import axios from "axios";

const { API_URL } = require("./constants");

const createLayout_api = async (
  accessToken,
  layoutname,
  modelUsed,
  node_data,
  edge_data
) => {
  for (let i = 0; i < node_data.length; i++) {
    node_data[i] = JSON.stringify(node_data[i]);
  }

  for (let i = 0; i < edge_data.length; i++) {
    edge_data[i] = JSON.stringify(edge_data[i]);
  }

  let data = JSON.stringify({
    layout_name: layoutname,
    model_used: modelUsed,
    node_data: node_data,
    edge_data: edge_data,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: API_URL + "api/v1/create_layout",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    data: data,
  };

  let result = "";

  await axios
    .request(config)
    .then((response) => {
      result = response.data;
    })
    .catch((error) => {
      return error;
    });

  return result;
};

export default createLayout_api;
