import { configureStore } from "@reduxjs/toolkit";
import accessTokenSlice from "features/accessTokenSlice";
import modelsListSlice from "features/modelsListSlice";
import layoutsListSlice from "features/layoutsListSlice";
import filteredLayoutsListSlice from "features/filteredLayoutsListSlice";
import notificationListSlice from "features/notificationListSlice";
import communityListSlice from "features/communityListSlice";
import myCommunityListSlice from "features/myCommunityListSlice";
import emailListSlice from "features/emailListSlice";

export default configureStore({
  reducer: {
    accessToken: accessTokenSlice,
    modelsList: modelsListSlice,
    layoutsList: layoutsListSlice,
    filteredLayoutsList: filteredLayoutsListSlice,
    notificationList: notificationListSlice,
    communityList: communityListSlice,
    myCommunityList: myCommunityListSlice,
    emailList: emailListSlice,
  },
});
