import React, { useEffect, useState } from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import routes from "routes.js";
import profile_routes from "profile_routes.js";
import { useSelector, useDispatch } from "react-redux";
import { setToken } from "features/accessTokenSlice";

const Admin = (props) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  // const count = useSelector((state) => state.counter.value)
  const dispatch = useDispatch();

  const domain = "dev-g63l0u2k3tmuhpdx.us.auth0.com";
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes, ProfileRoutes) => {
    var routes = [...routes, ...ProfileRoutes]; // this is for the hidden pages
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    // here
    var brand = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    return brand.replace(/([A-Z])/g, " $1").trim();
  };

  const getAccessToken = async () => {
    if (isAuthenticated) {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${domain}/api/v2/`,
          },
        });
        if (accessToken != "") {
          dispatch(setToken(accessToken));
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  };

  useEffect(() => {
    getAccessToken();
  }, []);

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/Inbox",
          imgSrc: require("../assets/img/brand/coeus-logo.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props?.location?.pathname)}
        />
        <Routes>
          {getRoutes(routes, profile_routes)}
          <Route path="*" element={<Navigate to="/admin/Inbox" replace />} />
        </Routes>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
