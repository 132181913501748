import axios from "axios";

const testAlertGroupMe_api = async (bot_id) => {
  let testing = "Testing from Coeus Studio";

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url:
      "https://api.groupme.com/v3/bots/post?bot_id=" +
      bot_id +
      "&text=" +
      testing,
  };

  let result = "";

  await axios
    .request(config)
    .then((response) => {
      result = response.status;
    })
    .catch((error) => {
      return error.response.status;
    });

  return result;
};

export default testAlertGroupMe_api;
