import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

const CommunityProfile = ({ setMessageApi, profileInfo }) => {
  // useEffect(() => {
  //   console.log("CommunityProfile");
  // }, []);
  return (
    <ModalBody>
      <Form>
        <FormGroup>
          <p className="mt-0 mb-7 text-sm">
            <span className="text-nowrap">
              <b>Created:</b> {profileInfo.created}
            </span>{" "}
            <br />
            <span className="text-nowrap">
              <b>Subscribers:</b> {profileInfo.subscriber}
            </span>{" "}
            <br />
            <span className="text-wrap" style={{ height: "100%" }}>
              <b>Description:</b> {profileInfo.description}{" "}
            </span>
          </p>
        </FormGroup>
      </Form>
    </ModalBody>
  );
};

export default CommunityProfile;
