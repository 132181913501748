import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tabs } from "antd";
import FormFilling from "./FormFilling";
import deleteEmail_api from "apis/deleteEmail";
import { deleteEmailData } from "features/emailListSlice";

const EmailModal = ({ setMessageApi, myEmailModal, setMyEmailModal }) => {
  const [loading, setLoading] = useState(false);
  const [emailData, setEmailData] = useState({});

  const accessToken = useSelector((state) => state.accessToken.value);
  const emailList = useSelector((state) => state.emailList.value);
  const dispatch = useDispatch();

  const newTabIndex = useRef(1); // new add start with 1 -> "Email 1"

  const [items, setItems] = useState([]); // calls formfilling component

  const [activeKey, setActiveKey] = useState("1"); // useeffect //activekey starts at 0

  useEffect(() => {
    setLoading(true);
    if (emailList.length == 0) {
      // preset template
      add();
    } else {
      // load in old email
      var temp = emailData;
      for (let i = 0; i < emailList.length; i++) {
        temp[emailList[i].id] = { email: emailList[i].email, verified: true };
      }
      setEmailData(temp);

      const newPanes = [...items];
      for (let i = 0; i < emailList.length; i++) {
        var keyValue = emailList[i].id.split(" ")[1];
        var keyValue_int = parseInt(keyValue);
        const newActiveKey = `${keyValue_int}`;
        setActiveKey(keyValue);
        newPanes.push({
          label: emailList[i].id,
          children: (
            <FormFilling
              emailData={emailData}
              setEmailData={setEmailData}
              activeKey={newActiveKey}
              setLoading={setLoading}
              setMessageApi={setMessageApi}
            />
          ),
          key: newActiveKey,
        });
        if (keyValue_int >= newTabIndex.current) {
          newTabIndex.current = keyValue_int + 1;
        }
      }
      setItems(newPanes);
    }
    setLoading(false);
  }, []);

  const toggle = () => {
    setMyEmailModal(false);
  };

  const add = () => {
    if (Object.keys(emailData).length >= 5) {
      setMessageApi("error", "Maximum of 5 emails allowed");
    } else {
      const newActiveKey = `${newTabIndex.current++}`;
      setActiveKey(newActiveKey);

      var temp = emailData;
      temp["Email " + newActiveKey] = {
        email: "",
        verified: false,
      };
      setEmailData(temp);

      const newPanes = [...items];
      newPanes.push({
        label: "Email " + newActiveKey,
        children: (
          <FormFilling
            emailData={emailData}
            setEmailData={setEmailData}
            activeKey={newActiveKey}
            setLoading={setLoading}
            setMessageApi={setMessageApi}
            loading={loading}
          />
        ),
        key: newActiveKey,
      });
      setItems(newPanes);
    }
  };

  const onDelete = async (targetKey) => {
    setLoading(true);
    if (accessToken != "") {
      var email = emailData["Email " + targetKey].email;
      var email_id = "Email " + targetKey;
      var result = await deleteEmail_api(accessToken, email, email_id);
      if (result != null) {
        if (result.error == false) {
          dispatch(deleteEmailData(email_id));
          setMessageApi("success", "Successfully Deleted Email");
        } else {
          setMessageApi("error", "Error in deleting emails");
        }
      } else {
        setMessageApi("error", "Error in deleting emails");
      }
    }
    setLoading(false);
  };

  const remove = (targetKey) => {
    onDelete(targetKey);
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    var temp = emailData;
    delete temp["Email " + targetKey];
    setEmailData(temp);
    setActiveKey(newActiveKey);
    setItems(newPanes);
  };

  const onEdit = (targetKey, action) => {
    setLoading(true);
    if (action === "add") {
      add();
    } else {
      remove(targetKey);
    }
    setLoading(false);
  };

  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };

  return (
    <Modal isOpen={myEmailModal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} style={{ fontSize: "15px" }}>
        Add Email Address
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Tabs
              type="editable-card"
              onChange={onChange}
              activeKey={activeKey}
              onEdit={onEdit}
              items={items}
            />{" "}
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default EmailModal;
