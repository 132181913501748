import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthenticationGuard } from "./components/authentication-guard";
import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

const LoadingContent = React.memo(() => (
  <Routes>
    <Route path="/" element={<Auth />} exact />
    <Route path="/auth/*" element={<Auth />} />
    <Route
      path="/admin/*"
      element={<AuthenticationGuard component={Admin} />}
    />
  </Routes>
));

export const App = () => {
  const { isLoading } = useAuth0();

  return (
    <>
      {isLoading ? (
        <div className="page-layout">
          <Auth />
        </div>
      ) : (
        <LoadingContent />
      )}
    </>
  );
};
