import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

const InputsModal = ({
  setInputsModal,
  inputsModal,
  parentCallback,
  currentEdit,
  parentInputsData,
}) => {
  const [moreInfo, setMoreInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalSize, setModalSize] = useState("lg");
  const [inputData, setInputData] = useState({
    ticker: "",
  });

  const accessToken = useSelector((state) => state.accessToken.value);
  const layoutList = useSelector((state) => state.layoutsList.value);
  const dispatch = useDispatch();

  const ticker = ["AAPL", "MSFT", "PLTR"];

  const toggle = () => {
    setInputsModal(false);
  };

  useEffect(() => {
    if (parentInputsData != undefined) {
      setInputData(parentInputsData);
    }
  }, []);

  const save = async () => {
    parentCallback(inputData);
  };

  const onChange = async (e) => {
    setInputData({ ...inputData, ticker: e.target.value });
    setMoreInfo("");
  };

  return (
    <Modal isOpen={inputsModal} toggle={toggle} size={modalSize}>
      <ModalHeader toggle={toggle}>Edit Inputs - {currentEdit}</ModalHeader>

      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="exampleSelect">Select</Label>
            <Input
              type="select"
              name="select"
              id="exampleSelect"
              value={inputData.ticker}
              onChange={onChange}
            >
              <option></option>
              {ticker.map((d) => (
                <option>{d}</option>
              ))}
            </Input>
          </FormGroup>
          <div>
            <span style={{ color: "red" }}>{moreInfo}</span>{" "}
          </div>
        </Form>
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <div>Saving...</div>
        ) : (
          <>
            <Button color="success" onClick={save} disabled={loading}>
              Save
            </Button>{" "}
            <Button color="secondary" onClick={toggle} disabled={loading}>
              Cancel
            </Button>{" "}
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default InputsModal;
