import axios from "axios";
const { API_URL } = require("./constants");

const getModelPage_api = async (accessToken) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: API_URL + "api/v1/get_model_page",
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };

  let result = "";

  await axios
    .request(config)
    .then((response) => {
      result = response.data;
    })
    .catch((error) => {
      return error;
    });

  return result;
};

export default getModelPage_api;
