import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import testAlertGroupMe_api from "apis/testAlertGroupMe";
import testAlertDiscord_api from "apis/testAlertDiscord";
import testAlertSlack_api from "apis/testAlertSlack";
import { requireField, checkInput } from "./Filtering";
import testCoeusSpace_api from "apis/testCoeusSpace";
import { Tabs } from "antd";
import FormFilling from "./FormFilling";

const SubsribeModal = ({
  setSubsribeModal,
  subsribeModal,
  handleSubscribeCallback,
  setMessageApi,
  setNotificationApi,
}) => {
  const [loading, setLoading] = useState(false);
  const [modalSize, setModalSize] = useState("lg");
  const [alertData, setAlertData] = useState({
    "Alert 1": {
      type: "",
      GroupMe_BotID: "",
      Discord_WebhookUrl: "",
      Slack_WebhookUrl: "",
    },
  });
  const newTabIndex = useRef(2);

  const initialItems = [
    {
      label: "Alert 1",
      children: (
        <FormFilling
          alertData={alertData}
          setAlertData={setAlertData}
          referID="1"
          setLoading={setLoading}
          setMessageApi={setMessageApi}
          setNotificationApi={setNotificationApi}
        />
      ),
      key: "1",
    },
  ];

  const [activeKey, setActiveKey] = useState(initialItems[0].key);
  const [items, setItems] = useState(initialItems);

  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };

  const add = () => {
    const newActiveKey = `${newTabIndex.current++}`;
    setActiveKey(newActiveKey);

    var temp = alertData;
    temp["Alert " + newActiveKey] = {
      type: "",
      GroupMe_BotID: "",
      Discord_WebhookUrl: "",
      Slack_WebhookUrl: "",
    };
    setAlertData(temp);

    const newPanes = [...items];
    newPanes.push({
      label: "Alert " + newActiveKey,
      children: (
        <FormFilling
          alertData={alertData}
          setAlertData={setAlertData}
          referID={newActiveKey}
          setLoading={setLoading}
          setMessageApi={setMessageApi}
          setNotificationApi={setNotificationApi}
        />
      ), // here
      key: newActiveKey,
    });
    setItems(newPanes);
  };

  const remove = (targetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key; // activate the last one
      } else {
        newActiveKey = newPanes[0].key; // activate the first one
      }
    }
    var temp = alertData;
    delete temp["Alert " + targetKey];
    setAlertData(temp);
    setActiveKey(newActiveKey);
    setItems(newPanes);
  };

  const onEdit = (targetKey, action) => {
    setLoading(true);
    if (action === "add") {
      add();
    } else {
      remove(targetKey);
    }
    setLoading(false);
  };

  const toggle = () => {
    setSubsribeModal(false);
  };

  const onSave = () => {
    var errorMsg = checkInput(alertData);
    if (errorMsg != "") {
      setMessageApi("error", errorMsg);
    } else {
      handleSubscribeCallback(alertData);
    }
  };

  return (
    <Modal isOpen={subsribeModal} toggle={toggle} size={modalSize}>
      <ModalHeader toggle={toggle}>
        Select your Subscription Integration
      </ModalHeader>

      <ModalBody>
        <Tabs
          type="editable-card"
          onChange={onChange}
          activeKey={activeKey}
          onEdit={onEdit}
          items={items}
        />
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <div>Saving...</div>
        ) : (
          <>
            <Button color="success" onClick={onSave} disabled={loading}>
              Save
            </Button>{" "}
            <Button color="secondary" onClick={toggle} disabled={loading}>
              Cancel
            </Button>{" "}
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default SubsribeModal;
