import { createSlice } from "@reduxjs/toolkit";

export const modelsListSlice = createSlice({
  name: "modelsList",
  initialState: {
    value: [],
  },
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes.
    // Also, no return statement is required from these functions.
    setModelData: (state, action) => {
      state.value = action.payload;
    },
    addModelData: (state, action) => {
      if (state.value.length == 0) {
        var newId = 0;
      } else {
        var newId = state.value.slice(-1)[0].id + 1;
      }
      state.value = [...state.value, { id: newId, name: action.payload }];
    },
    editModelData: (state, action) => {
      var oldModelName = action.payload[0];
      var newModelName = action.payload[1];
      for (let i = 0; i < state.value.length; i++) {
        if (state.value[i].name == oldModelName) {
          state.value[i].name = newModelName;
          break;
        }
      }
    },
    deleteModelData: (state, action) => {
      var oldModelName = action.payload;
      for (let i = 0; i < state.value.length; i++) {
        if (state.value[i].name == oldModelName) {
          state.value.splice([i], 1);
          break;
        }
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setModelData, addModelData, editModelData, deleteModelData } =
  modelsListSlice.actions;

export default modelsListSlice.reducer;
