import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import getLayout_api from "apis/getLayout";
import LayoutNameCheck from "./LayoutNameCheck";
import Editor from "@monaco-editor/react";
import deleteModal from "apis/deleteLayout";
import deleteModel_api from "apis/deleteModel";
import deleteLayout_api from "apis/deleteLayout";
import LayoutDesign from "./LayoutDesign";
import ReactFlow, { useNodesState, useEdgesState } from "reactflow";
import editLayout_api from "apis/editLayout";
import { editLayoutData, deleteLayoutData } from "features/layoutsListSlice";

const EditLayout = ({
  setEditLayout,
  editLayout,
  oldLayoutName,
  setMessageApi,
}) => {
  const [page, setPage] = useState(1);
  const [buttonText, setButtonText] = useState("Next");
  const [newLayoutName, setNewLayoutName] = useState("");
  const [oldLayoutValue, setOldLayoutValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalSize, setModalSize] = useState("md");
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [viewOnly, setViewOnly] = useState(false);

  const accessToken = useSelector((state) => state.accessToken.value);
  const layoutList = useSelector((state) => state.layoutsList.value);
  const modelList = useSelector((state) => state.modelsList.value);
  const dispatch = useDispatch();

  const toggle = () => {
    setEditLayout(false);
  };

  const nextPage = async () => {
    setLoading(true);
    var newLayoutNameTouch = true;
    if (newLayoutName == "" || newLayoutName == oldLayoutName) {
      newLayoutNameTouch = false;
    }
    if (page == 1) {
      var validName = LayoutNameCheck(newLayoutName);
      if (validName == "" || !newLayoutNameTouch) {
        setPage(2);
        setButtonText("Save");
        setModalSize("xl");
        setLoading(false);
      } else {
        setMessageApi("error", validName);
        setLoading(false);
      }
    } else if (page === 2) {
      // submit
      var newLayoutValue = ""; // filled
      var modelUsed = []; // list of model names
      Object.entries(nodes).forEach(([k, v]) => {
        // k -> model name, v -> [pressed, data]
        if (v.type == "default") {
          modelUsed.push(v.data.label);
        }
      });

      if (newLayoutNameTouch == false) {
        var result = await editLayout_api(
          accessToken,
          oldLayoutName,
          oldLayoutName,
          nodes,
          edges,
          modelUsed
        );
      } else {
        var result = await editLayout_api(
          accessToken,
          oldLayoutName,
          newLayoutName,
          nodes,
          edges,
          modelUsed
        );
      }

      try {
        // returns nothing
        if (result != undefined) {
          if (result.error == false) {
            if (newLayoutNameTouch) {
              // alternative is to search for oldlayoutname -> replace
              dispatch(editLayoutData([oldLayoutName, newLayoutName]));
            }
            setEditLayout(false);
            setLoading(false);
            setMessageApi("success", "Layout Saved");
          } else if (result.message == "Duplicate Layout Name") {
            setMessageApi(
              "error",
              "Layout Name already exist, Please Start Over"
            );
            setLoading(false);
          } else {
            setLoading(false);
            setMessageApi("error", "Please Try Again");
          }
        }
      } catch (err) {
        setLoading(false);
        setMessageApi("error", "Please Try Again");
      }
    }
  };

  const delete_Layout = async () => {
    setLoading(true);
    try {
      var result = await deleteLayout_api(accessToken, oldLayoutName);

      if (result != undefined) {
        if (result.error == false) {
          dispatch(deleteLayoutData(oldLayoutName));
          setEditLayout(false);
          setLoading(false);
          setMessageApi("success", "Layout Deleted");
        } else {
          setMessageApi("error", "Please Try Again");
          setLoading(false);
        }
      }
    } catch (err) {
      setMessageApi("error", "Please Try Again");
      setLoading(false);
    }
  };

  const getOldLayoutInfo = async () => {
    var result = await getLayout_api(accessToken, oldLayoutName);
    // 0 -> layout_id, 1 -> models used, 2 -> data, 3 -> layout_name
    if (result != undefined) {
      if (result.error == false) {
        var node_data = result.message[0][3];
        var edge_data = result.message[0][4];
        if (node_data != null) {
          setNodes(result.message[0][3]);
        }
        if (edge_data != null) {
          setEdges(result.message[0][4]);
        }
        setViewOnly(result.message[1]);
        setOldLayoutValue(result.message[0][2]);
      } else {
        setMessageApi("error", "cannot get layout info");
      }
    }
  };

  useEffect(() => {
    getOldLayoutInfo();
  }, []);

  const onChange = (e) => {
    if (e.target.id === "LayoutName") {
      setNewLayoutName(e.target.value);
    }
  };

  const handleKeyPress = (target) => {
    if (target.charCode == 13) {
      target.preventDefault();
      nextPage();
    }
  };

  return (
    <Modal isOpen={editLayout} toggle={toggle} size={modalSize}>
      {viewOnly ? (
        <ModalHeader toggle={toggle}>Edit Layout (View Only)</ModalHeader>
      ) : (
        <ModalHeader toggle={toggle}>Edit Layout</ModalHeader>
      )}

      <ModalBody>
        {page === 1 ? (
          <Form>
            <FormGroup>
              <Label for="LayoutName">Layout Name</Label>
              <Input
                id="LayoutName"
                placeholder={oldLayoutName}
                defaultValue={oldLayoutName}
                type="LayoutName"
                onChange={onChange}
                onKeyPress={handleKeyPress}
              />
            </FormGroup>
          </Form>
        ) : (
          <Form>
            <FormGroup>
              <LayoutDesign
                nodes={nodes}
                setNodes={setNodes}
                onNodesChange={onNodesChange}
                edges={edges}
                setEdges={setEdges}
                onEdgesChange={onEdgesChange}
                viewOnly={viewOnly}
              />
            </FormGroup>
          </Form>
        )}
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <div>Saving...</div>
        ) : (
          <>
            {viewOnly && page == 2 ? (
              <></>
            ) : (
              <Button color="success" onClick={nextPage} disabled={loading}>
                {buttonText}
              </Button>
            )}{" "}
            {!viewOnly ? (
              <Button color="danger" onClick={delete_Layout} disabled={loading}>
                Delete
              </Button>
            ) : (
              <></>
            )}{" "}
            <Button color="secondary" onClick={toggle} disabled={loading}>
              Cancel
            </Button>{" "}
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default EditLayout;
