const requireField = {
  Discord: ["Discord_WebhookUrl"],
  GroupMe: ["GroupMe_BotID"],
  Slack: ["Slack_WebhookUrl"],
  "Coeus Space": [],
};

const cleanSaveAlertData = (data) => {
  // node data breaks off here
  return data;
  // temp
};

export { requireField };
export { cleanSaveAlertData };
