const Tickers = [
  { id: 0, name: "AAPL" },
  { id: 1, name: "NVDA" },
  { id: 2, name: "AMZN" },
  { id: 3, name: "GOOGL" },
  { id: 4, name: "META" },
  { id: 5, name: "GOOG" },
  { id: 6, name: "BRK.B" },
  { id: 7, name: "AVGO" },
  { id: 8, name: "TSLA" },
  { id: 9, name: "LLY" },
  { id: 10, name: "JPM" },
  { id: 11, name: "UNH" },
  { id: 12, name: "V" },
  { id: 13, name: "XOM" },
  { id: 14, name: "JNJ" },
  { id: 15, name: "PG" },
  { id: 16, name: "MA" },
  { id: 17, name: "HD" },
  { id: 18, name: "MRK" },
  { id: 19, name: "COST" },
  { id: 20, name: "ABBV" },
  { id: 21, name: "AMD" },
  { id: 22, name: "ADBE" },
  { id: 23, name: "CRM" },
  { id: 24, name: "CVX" },
  { id: 25, name: "NFLX" },
  { id: 26, name: "ACN" },
  { id: 27, name: "WMT" },
  { id: 28, name: "KO" },
  { id: 29, name: "BAC" },
  { id: 30, name: "PEP" },
  { id: 31, name: "MCD" },
  { id: 32, name: "CSCO" },
  { id: 33, name: "INTC" },
  { id: 34, name: "TMO" },
  { id: 35, name: "LIN" },
  { id: 36, name: "ABT" },
  { id: 37, name: "ORCL" },
  { id: 38, name: "CMCSA" },
  { id: 39, name: "WFC" },
  { id: 40, name: "INTU" },
  { id: 41, name: "VZ" },
  { id: 42, name: "IBM" },
  { id: 43, name: "DIS" },
  { id: 44, name: "QCOM" },
  { id: 45, name: "AMGN" },
  { id: 46, name: "NOW" },
  { id: 47, name: "PFE" },
  { id: 48, name: "CAT" },
  { id: 49, name: "TXN" },
  { id: 50, name: "DHR" },
  { id: 51, name: "UNP" },
  { id: 52, name: "SPGI" },
  { id: 53, name: "AMAT" },
  { id: 54, name: "PM" },
  { id: 55, name: "GE" },
  { id: 56, name: "UBER" },
  { id: 57, name: "HON" },
  { id: 58, name: "COP" },
  { id: 59, name: "ISRG" },
  { id: 60, name: "RTX" },
  { id: 61, name: "GS" },
  { id: 62, name: "NKE" },
  { id: 63, name: "T" },
  { id: 64, name: "LOW" },
  { id: 65, name: "BKNG" },
  { id: 66, name: "NEE" },
  { id: 67, name: "PLD" },
  { id: 68, name: "UPS" },
  { id: 69, name: "BA" },
  { id: 70, name: "MDT" },
  { id: 71, name: "LRCX" },
  { id: 72, name: "ELV" },
  { id: 73, name: "MS" },
  { id: 74, name: "VRTX" },
  { id: 75, name: "BLK" },
  { id: 76, name: "TJX" },
  { id: 77, name: "AXP" },
  { id: 78, name: "SBUX" },
  { id: 79, name: "PANW" },
  { id: 80, name: "SYK" },
  { id: 81, name: "DE" },
  { id: 82, name: "PGR" },
  { id: 83, name: "C" },
  { id: 84, name: "REGN" },
  { id: 85, name: "MDLZ" },
  { id: 86, name: "BMY" },
  { id: 87, name: "GILD" },
  { id: 88, name: "CB" },
  { id: 89, name: "ADI" },
  { id: 90, name: "ETN" },
  { id: 91, name: "MU" },
  { id: 92, name: "ADP" },
  { id: 93, name: "LMT" },
  { id: 94, name: "SCHW" },
  { id: 95, name: "MMC" },
  { id: 96, name: "AMT" },
  { id: 97, name: "CVS" },
  { id: 98, name: "BSX" },
  { id: 99, name: "BX" },
  { id: 100, name: "CI" },
  { id: 101, name: "KLAC" },
  { id: 102, name: "ZTS" },
  { id: 103, name: "FI" },
  { id: 104, name: "TMUS" },
  { id: 105, name: "SNPS" },
  { id: 106, name: "CDNS" },
  { id: 107, name: "EQIX" },
  { id: 108, name: "SO" },
  { id: 109, name: "SLB" },
  { id: 110, name: "CME" },
  { id: 111, name: "DUK" },
  { id: 112, name: "ICE" },
  { id: 113, name: "ITW" },
  { id: 114, name: "SHW" },
  { id: 115, name: "MO" },
  { id: 116, name: "CSX" },
  { id: 117, name: "BDX" },
  { id: 118, name: "WM" },
  { id: 119, name: "EOG" },
  { id: 120, name: "CL" },
  { id: 121, name: "USB" },
  { id: 122, name: "ANET" },
  { id: 123, name: "TGT" },
  { id: 124, name: "PYPL" },
  { id: 125, name: "MCK" },
  { id: 126, name: "CMG" },
  { id: 127, name: "ABNB" },
  { id: 128, name: "MCO" },
  { id: 129, name: "NOC" },
  { id: 130, name: "PH" },
  { id: 131, name: "ORLY" },
  { id: 132, name: "PSX" },
  { id: 133, name: "PNC" },
  { id: 134, name: "APH" },
  { id: 135, name: "GD" },
  { id: 136, name: "AON" },
  { id: 137, name: "MAR" },
  { id: 138, name: "MPC" },
  { id: 139, name: "TDG" },
  { id: 140, name: "ROP" },
  { id: 141, name: "APD" },
  { id: 142, name: "FDX" },
  { id: 143, name: "TT" },
  { id: 144, name: "HCA" },
  { id: 145, name: "FCX" },
  { id: 146, name: "NXPI" },
  { id: 147, name: "EMR" },
  { id: 148, name: "LULU" },
  { id: 149, name: "MSI" },
  { id: 150, name: "ADSK" },
  { id: 151, name: "NSC" },
  { id: 152, name: "PCAR" },
  { id: 153, name: "PXD" },
  { id: 154, name: "MMM" },
  { id: 155, name: "CTAS" },
  { id: 156, name: "AJG" },
  { id: 157, name: "COF" },
  { id: 158, name: "ECL" },
  { id: 159, name: "TFC" },
  { id: 160, name: "HLT" },
  { id: 161, name: "DXCM" },
  { id: 162, name: "MCHP" },
  { id: 163, name: "AIG" },
  { id: 164, name: "AZO" },
  { id: 165, name: "WELL" },
  { id: 166, name: "TRV" },
  { id: 167, name: "GM" },
  { id: 168, name: "CCI" },
  { id: 169, name: "ROST" },
  { id: 170, name: "CARR" },
  { id: 171, name: "SPG" },
  { id: 172, name: "PSA" },
  { id: 173, name: "O" },
  { id: 174, name: "VLO" },
  { id: 175, name: "AFL" },
  { id: 176, name: "TEL" },
  { id: 177, name: "F" },
  { id: 178, name: "EW" },
  { id: 179, name: "SRE" },
  { id: 180, name: "URI" },
  { id: 181, name: "MSCI" },
  { id: 182, name: "MET" },
  { id: 183, name: "HUM" },
  { id: 184, name: "IDXX" },
  { id: 185, name: "DLR" },
  { id: 186, name: "NUE" },
  { id: 187, name: "DHI" },
  { id: 188, name: "CPRT" },
  { id: 189, name: "BK" },
  { id: 190, name: "WMB" },
  { id: 191, name: "FTNT" },
  { id: 192, name: "ALL" },
  { id: 193, name: "AEP" },
  { id: 194, name: "OKE" },
  { id: 195, name: "MNST" },
  { id: 196, name: "KMB" },
  { id: 197, name: "STZ" },
  { id: 198, name: "HES" },
  { id: 199, name: "NEM" },
  { id: 200, name: "AMP" },
  { id: 201, name: "FAST" },
  { id: 202, name: "IQV" },
  { id: 203, name: "CNC" },
  { id: 204, name: "CTSH" },
  { id: 205, name: "GWW" },
  { id: 206, name: "PAYX" },
  { id: 207, name: "LHX" },
  { id: 208, name: "DOW" },
  { id: 209, name: "CEG" },
  { id: 210, name: "OXY" },
  { id: 211, name: "PRU" },
  { id: 212, name: "A" },
  { id: 213, name: "JCI" },
  { id: 214, name: "SYY" },
  { id: 215, name: "AME" },
  { id: 216, name: "D" },
  { id: 217, name: "GIS" },
  { id: 218, name: "LEN" },
  { id: 219, name: "CHTR" },
  { id: 220, name: "FIS" },
  { id: 221, name: "YUM" },
  { id: 222, name: "COR" },
  { id: 223, name: "OTIS" },
  { id: 224, name: "IT" },
  { id: 225, name: "KVUE" },
  { id: 226, name: "BIIB" },
  { id: 227, name: "ODFL" },
  { id: 228, name: "VRSK" },
  { id: 229, name: "PCG" },
  { id: 230, name: "ROK" },
  { id: 231, name: "EXC" },
  { id: 232, name: "RSG" },
  { id: 233, name: "CSGP" },
  { id: 234, name: "GPN" },
  { id: 235, name: "MRNA" },
  { id: 236, name: "EA" },
  { id: 237, name: "CMI" },
  { id: 238, name: "PPG" },
  { id: 239, name: "KMI" },
  { id: 240, name: "HAL" },
  { id: 241, name: "XEL" },
  { id: 242, name: "IR" },
  { id: 243, name: "CTVA" },
  { id: 244, name: "KDP" },
  { id: 245, name: "FICO" },
  { id: 246, name: "VICI" },
  { id: 247, name: "EXR" },
  { id: 248, name: "ON" },
  { id: 249, name: "MLM" },
  { id: 250, name: "ED" },
  { id: 251, name: "CDW" },
  { id: 252, name: "EFX" },
  { id: 253, name: "BKR" },
  { id: 254, name: "KR" },
  { id: 255, name: "RCL" },
  { id: 256, name: "EL" },
  { id: 257, name: "VMC" },
  { id: 258, name: "KHC" },
  { id: 259, name: "ACGL" },
  { id: 260, name: "ANSS" },
  { id: 261, name: "MPWR" },
  { id: 262, name: "DG" },
  { id: 263, name: "PWR" },
  { id: 264, name: "PEG" },
  { id: 265, name: "GEHC" },
  { id: 266, name: "HSY" },
  { id: 267, name: "FANG" },
  { id: 268, name: "KEYS" },
  { id: 269, name: "DLTR" },
  { id: 270, name: "DD" },
  { id: 271, name: "ADM" },
  { id: 272, name: "RMD" },
  { id: 273, name: "DVN" },
  { id: 274, name: "XYL" },
  { id: 275, name: "TTWO" },
  { id: 276, name: "CBRE" },
  { id: 277, name: "WST" },
  { id: 278, name: "HIG" },
  { id: 279, name: "FTV" },
  { id: 280, name: "MTD" },
  { id: 281, name: "CAH" },
  { id: 282, name: "DFS" },
  { id: 283, name: "WTW" },
  { id: 284, name: "DAL" },
  { id: 285, name: "HPQ" },
  { id: 286, name: "EIX" },
  { id: 287, name: "ZBH" },
  { id: 288, name: "WEC" },
  { id: 289, name: "SBAC" },
  { id: 290, name: "TROW" },
  { id: 291, name: "AVB" },
  { id: 292, name: "BR" },
  { id: 293, name: "TSCO" },
  { id: 294, name: "WY" },
  { id: 295, name: "ULTA" },
  { id: 296, name: "LYB" },
  { id: 297, name: "AWK" },
  { id: 298, name: "CHD" },
  { id: 299, name: "APTV" },
  { id: 300, name: "FITB" },
  { id: 301, name: "MTB" },
  { id: 302, name: "GLW" },
  { id: 303, name: "WBD" },
  { id: 304, name: "WAB" },
  { id: 305, name: "STT" },
  { id: 306, name: "PHM" },
  { id: 307, name: "ILMN" },
  { id: 308, name: "NVR" },
  { id: 309, name: "EBAY" },
  { id: 310, name: "HWM" },
  { id: 311, name: "RJF" },
  { id: 312, name: "PTC" },
  { id: 313, name: "STE" },
  { id: 314, name: "DTE" },
  { id: 315, name: "CPAY" },
  { id: 316, name: "IFF" },
  { id: 317, name: "DOV" },
  { id: 318, name: "ETR" },
  { id: 319, name: "BLDR" },
  { id: 320, name: "HPE" },
  { id: 321, name: "MOH" },
  { id: 322, name: "EXPE" },
  { id: 323, name: "EQR" },
  { id: 324, name: "GPC" },
  { id: 325, name: "TDY" },
  { id: 326, name: "IRM" },
  { id: 327, name: "CBOE" },
  { id: 328, name: "NDAQ" },
  { id: 329, name: "BAX" },
  { id: 330, name: "DRI" },
  { id: 331, name: "WDC" },
  { id: 332, name: "ARE" },
  { id: 333, name: "LH" },
  { id: 334, name: "ES" },
  { id: 335, name: "TRGP" },
  { id: 336, name: "GRMN" },
  { id: 337, name: "INVH" },
  { id: 338, name: "VTR" },
  { id: 339, name: "CTRA" },
  { id: 340, name: "PPL" },
  { id: 341, name: "EXPD" },
  { id: 342, name: "FE" },
  { id: 343, name: "COO" },
  { id: 344, name: "ALGN" },
  { id: 345, name: "AKAM" },
  { id: 346, name: "NTAP" },
  { id: 347, name: "HBAN" },
  { id: 348, name: "LVS" },
  { id: 349, name: "WAT" },
  { id: 350, name: "BRO" },
  { id: 351, name: "HOLX" },
  { id: 352, name: "LUV" },
  { id: 353, name: "AEE" },
  { id: 354, name: "VRSN" },
  { id: 355, name: "OMC" },
  { id: 356, name: "TYL" },
  { id: 357, name: "FDS" },
  { id: 358, name: "HUBB" },
  { id: 359, name: "CLX" },
  { id: 360, name: "BALL" },
  { id: 361, name: "AXON" },
  { id: 362, name: "RF" },
  { id: 363, name: "CINF" },
  { id: 364, name: "STLD" },
  { id: 365, name: "STX" },
  { id: 366, name: "PFG" },
  { id: 367, name: "CNP" },
  { id: 368, name: "MKC" },
  { id: 369, name: "SWKS" },
  { id: 370, name: "J" },
  { id: 371, name: "WRB" },
  { id: 372, name: "NTRS" },
  { id: 373, name: "ATO" },
  { id: 374, name: "TXT" },
  { id: 375, name: "JBHT" },
  { id: 376, name: "VLTO" },
  { id: 377, name: "TER" },
  { id: 378, name: "WBA" },
  { id: 379, name: "CCL" },
  { id: 380, name: "CMS" },
  { id: 381, name: "EPAM" },
  { id: 382, name: "EG" },
  { id: 383, name: "AVY" },
  { id: 384, name: "FSLR" },
  { id: 385, name: "SYF" },
  { id: 386, name: "JBL" },
  { id: 387, name: "CFG" },
  { id: 388, name: "IEX" },
  { id: 389, name: "TSN" },
  { id: 390, name: "PKG" },
  { id: 391, name: "MAA" },
  { id: 392, name: "SNA" },
  { id: 393, name: "MAS" },
  { id: 394, name: "LDOS" },
  { id: 395, name: "LW" },
  { id: 396, name: "ESS" },
  { id: 397, name: "EQT" },
  { id: 398, name: "CF" },
  { id: 399, name: "POOL" },
  { id: 400, name: "BBY" },
  { id: 401, name: "SWK" },
  { id: 402, name: "DPZ" },
  { id: 403, name: "ENPH" },
  { id: 404, name: "DGX" },
  { id: 405, name: "CE" },
  { id: 406, name: "K" },
  { id: 407, name: "CAG" },
  { id: 408, name: "VTRS" },
  { id: 409, name: "UAL" },
  { id: 410, name: "ALB" },
  { id: 411, name: "PODD" },
  { id: 412, name: "HST" },
  { id: 413, name: "NDSN" },
  { id: 414, name: "AMCR" },
  { id: 415, name: "KEY" },
  { id: 416, name: "MRO" },
  { id: 417, name: "SJM" },
  { id: 418, name: "GEN" },
  { id: 419, name: "RVTY" },
  { id: 420, name: "L" },
  { id: 421, name: "ZBRA" },
  { id: 422, name: "IP" },
  { id: 423, name: "TRMB" },
  { id: 424, name: "BG" },
  { id: 425, name: "KIM" },
  { id: 426, name: "LYV" },
  { id: 427, name: "LKQ" },
  { id: 428, name: "IPG" },
  { id: 429, name: "LNT" },
  { id: 430, name: "JKHY" },
  { id: 431, name: "NRG" },
  { id: 432, name: "ROL" },
  { id: 433, name: "MGM" },
  { id: 434, name: "JNPR" },
  { id: 435, name: "PNR" },
  { id: 436, name: "TAP" },
  { id: 437, name: "EVRG" },
  { id: 438, name: "TFX" },
  { id: 439, name: "AES" },
  { id: 440, name: "INCY" },
  { id: 441, name: "TECH" },
  { id: 442, name: "UDR" },
  { id: 443, name: "ALLE" },
  { id: 444, name: "FFIV" },
  { id: 445, name: "KMX" },
  { id: 446, name: "CRL" },
  { id: 447, name: "WRK" },
  // { id: 448, name: "PEAK" },
  { id: 449, name: "GL" },
  { id: 450, name: "REG" },
  { id: 451, name: "CDAY" },
  { id: 452, name: "MOS" },
  { id: 453, name: "QRVO" },
  { id: 454, name: "NI" },
  { id: 455, name: "MTCH" },
  { id: 456, name: "MKTX" },
  { id: 457, name: "CPT" },
  { id: 458, name: "CHRW" },
  { id: 459, name: "HII" },
  { id: 460, name: "BF-B" },
  { id: 461, name: "BXP" },
  { id: 462, name: "EMN" },
  { id: 463, name: "AAL" },
  { id: 464, name: "AOS" },
  { id: 465, name: "APA" },
  { id: 466, name: "HSIC" },
  { id: 467, name: "UHS" },
  { id: 468, name: "CZR" },
  { id: 469, name: "BBWI" },
  { id: 470, name: "NWSA" },
  { id: 471, name: "PAYC" },
  { id: 472, name: "WYNN" },
  { id: 473, name: "CTLT" },
  { id: 474, name: "AIZ" },
  { id: 475, name: "HRL" },
  { id: 476, name: "TPR" },
  { id: 477, name: "CPB" },
  { id: 478, name: "RHI" },
  { id: 479, name: "ETSY" },
  { id: 480, name: "BWA" },
  { id: 481, name: "FOXA" },
  { id: 482, name: "PNW" },
  { id: 483, name: "BEN" },
  { id: 484, name: "FRT" },
  { id: 485, name: "XRAY" },
  { id: 486, name: "NCLH" },
  { id: 487, name: "IVZ" },
  { id: 488, name: "CMA" },
  { id: 489, name: "FMC" },
  { id: 490, name: "GNRC" },
  { id: 491, name: "PARA" },
  { id: 492, name: "BIO" },
  { id: 493, name: "ZION" },
  { id: 494, name: "HAS" },
  { id: 495, name: "WHR" },
  { id: 496, name: "DVA" },
  { id: 497, name: "VFC" },
  { id: 498, name: "RL" },
  { id: 499, name: "MHK" },
  { id: 500, name: "FOX" },
  { id: 501, name: "NWS" },
  { id: 502, name: "MSFT" },
];

export default Tickers;
