import axios from "axios";
const { API_URL } = require("./constants");

const createSimpleAlert_api = async (
  accessToken,
  alert_type,
  alert_code,
  tickerData,
  alert_data
) => {
  let data = JSON.stringify({
    alert_type: alert_type,
    alert_code: alert_code,
    ticker: tickerData,
    alert_data: alert_data,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: API_URL + "api/v1/create_simple_alert",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    data: data,
  };

  let result = "";

  await axios
    .request(config)
    .then((response) => {
      result = response.data;
    })
    .catch((error) => {
      return error;
    });

  return result;
};

export default createSimpleAlert_api;
