import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Jumbotron,
  Col,
} from "reactstrap";

import { useState, useRef, useCallback } from "react";
import createLayout from "apis/createLayout";
import { useSelector, useDispatch } from "react-redux";
import LayoutNameCheck from "./LayoutNameCheck";
import Editor from "@monaco-editor/react";
import createLayout_api from "apis/createLayout";
import LayoutDesign from "./LayoutDesign";
import ReactFlow, { useNodesState, useEdgesState } from "reactflow";
import { addLayoutData } from "features/layoutsListSlice";

const NewLayout = ({ setNewLayout, newLayout, setMessageApi }) => {
  const [page, setPage] = useState(1);
  const [buttonText, setButtonText] = useState("Next");
  const [layoutName, setLayoutName] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalSize, setModalSize] = useState("md");
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const accessToken = useSelector((state) => state.accessToken.value);
  const layoutList = useSelector((state) => state.layoutsList.value);
  const modelList = useSelector((state) => state.modelsList.value);
  const dispatch = useDispatch();

  const toggle = () => {
    setNewLayout(false);
  };

  const nextPage = async () => {
    setLoading(true);
    if (page === 2) {
      // submit
      var modelUsed = []; // list of model names
      Object.entries(nodes).forEach(([k, v]) => {
        // k -> model name, v -> [pressed, data]
        if (v.type == "default") {
          modelUsed.push(v.data.label);
        }
      });
      // var layoutValue = [nodes, edges]
      var result = await createLayout_api(
        accessToken,
        layoutName,
        modelUsed,
        nodes,
        edges
      );
      try {
        if (result != undefined) {
          if (result.error == false) {
            // successful
            dispatch(addLayoutData(layoutName));
            setNewLayout(false);
            setLoading(false);
            setMessageApi("success", "Layout Saved");
          } else {
            if (result.message == "Duplicate Layout Name") {
              setMessageApi(
                "error",
                "Layout Name already exist, Please Start Over"
              );
              setLoading(false);
            } else {
              setMessageApi("error", "Please Try Again");
              setLoading(false);
            }
          }
        }
      } catch (err) {
        setMessageApi("error", "Please Try Again");
        setLoading(false);
      }
    } else {
      // go to the next page
      var validName = LayoutNameCheck(layoutName);
      if (validName == "") {
        setPage(2);
        setButtonText("Save");
        setModalSize("xl");
        setLoading(false);
      } else {
        setMessageApi("error", validName);
        setLoading(false);
      }
    }
  };

  const onChange = (e) => {
    if (e.target.placeholder === "Layout Name") {
      setLayoutName(e.target.value);
    }
  };

  const handleKeyPress = (target) => {
    if (target.charCode == 13) {
      target.preventDefault();
      nextPage();
    }
  };

  return (
    // <Modal isOpen={newLayout} toggle={toggle} size='lg'>
    <Modal isOpen={newLayout} toggle={toggle} size={modalSize}>
      <ModalHeader toggle={toggle}>Create New Layout</ModalHeader>
      <ModalBody>
        {page === 1 ? (
          <Form>
            <FormGroup>
              <Label for="LayoutName">Layout Name</Label>
              <Input
                id="LayoutName"
                placeholder="Layout Name"
                type="LayoutName"
                onChange={onChange}
                onKeyPress={handleKeyPress}
              />
            </FormGroup>
          </Form>
        ) : (
          <Form>
            <FormGroup>
              <LayoutDesign
                nodes={nodes}
                setNodes={setNodes}
                onNodesChange={onNodesChange}
                edges={edges}
                setEdges={setEdges}
                onEdgesChange={onEdgesChange}
              />
            </FormGroup>
          </Form>
        )}
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <div>Saving...</div>
        ) : (
          <>
            <Button color="primary" onClick={nextPage} disabled={loading}>
              {buttonText}
            </Button>{" "}
            <Button color="secondary" onClick={toggle} disabled={loading}>
              Cancel
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default NewLayout;
