import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { requireField } from "./Filtering";
import { addNotifData } from "features/notificationListSlice";

import testAlertDiscord_api from "apis/testAlertDiscord";
import testAlertGroupMe_api from "apis/testAlertGroupMe";
import testCoeusSpace_api from "apis/testCoeusSpace";
import testAlertSlack_api from "apis/testAlertSlack";

const FormFilling = ({
  alertData,
  setAlertData,
  referID,
  setLoading,
  setMessageApi,
  setNotificationApi,
}) => {
  const [alertType, setAlertType] = useState(
    alertData["Alert " + referID].type
  );
  const [alertValue, setAlertValue] = useState("");

  const [myAlertValue, setMyAlertValue] = useState({
    type: "",
    GroupMe_BotID: "",
    Discord_WebhookUrl: "",
    Slack_WebhookUrl: "",
  });

  const accessToken = useSelector((state) => state.accessToken.value);
  const dispatch = useDispatch();

  const testalert = async () => {
    if (myAlertValue.type == "GroupMe") {
      var bot_id = myAlertValue.GroupMe_BotID;
      if (bot_id == "" || bot_id == undefined) {
        setMessageApi("error", "Entry cannot be empty");
      } else {
        var result = await testAlertGroupMe_api(bot_id);
        if (result == 404 || result == "") {
          // should return 302 if it works
          setMessageApi("error", "Error, Please try again later");
        } else {
          setMessageApi("success", "You should received an alert soon");
        }
      }
    } else if (myAlertValue.type == "Discord") {
      var webhookUrl = myAlertValue.Discord_WebhookUrl;
      if (webhookUrl == "" || webhookUrl == undefined) {
        setMessageApi("error", "Entry cannot be empty");
      } else {
        var result = await testAlertDiscord_api(webhookUrl);
        if (result == 404 || result == "") {
          // should return 302 if it works
          setMessageApi("error", "Error, Please try again later");
        } else {
          setMessageApi("success", "You should received an alert soon");
        }
      }
    } else if (myAlertValue.type == "Slack") {
      var webhookUrl = myAlertValue.Slack_WebhookUrl;
      if (webhookUrl == "" || webhookUrl == undefined) {
        setMessageApi("error", "Entry cannot be empty");
      } else {
        var result = await testAlertSlack_api(webhookUrl);
        if (result == 404 || result == "") {
          // should return 302 if it works
          setMessageApi("error", "Error, Please try again later");
        } else {
          setMessageApi("success", "You should received an alert soon");
        }
      }
    } else if (myAlertValue.type == "Coeus Space") {
      var result = await testCoeusSpace_api(
        accessToken,
        "Coeus Space",
        "Testing from Coeus - Message",
        "Testing from Coeus - Subject"
      );
      if (result != undefined) {
        if (result.error == false) {
          // add to notification
          var data = result.message;
          setMessageApi("success", "You should received an alert soon");

          var temp_notif = {
            key: data.myid,
            originate: "Coeus Space",
            created: data.timestamp,
            msg: "Testing from Coeus - Message",
            subject: "Testing from Coeus - Subject",
          };

          dispatch(addNotifData(temp_notif));
          setNotificationApi("Coeus", "Testing from Coeus Studio");
        } else {
          setMessageApi("error", "Error, Please try again later");
        }
      } else {
        setMessageApi("error", "Error, Please try again later");
      }
    } else if (myAlertValue.type == "") {
      // none is selected
      setMessageApi("error", "Please Select an Alert Type");
    }
  };

  const onSelectChange = async (e) => {
    if (e.key == "Enter") {
      e.preventDefault();
    } else {
      var mytype = e.target.value;
      if (!mytype.includes("Coming Soon")) {
        setLoading(true);
        var tempAlertData = alertData;
        tempAlertData["Alert " + referID].type = mytype;

        var tempMyAlertValue = myAlertValue;
        tempMyAlertValue.type = mytype;

        setMyAlertValue(tempMyAlertValue);
        setAlertData(tempAlertData);
        setAlertType(mytype); // used for rerendering
        setLoading(false);
      }
    }
  };

  const fillForm = async (e) => {
    setLoading(true);
    var tempAlertData = alertData;
    tempAlertData["Alert " + referID][e.target.id] = e.target.value;

    var tempMyAlertValue = myAlertValue;
    tempMyAlertValue[e.target.id] = e.target.value;

    setMyAlertValue(tempMyAlertValue);
    setAlertValue(e.target.value); // used for rerendering
    setAlertData(tempAlertData);
    setLoading(false);
  };

  const getForm = () => {
    if (myAlertValue.type == "GroupMe") {
      return (
        <>
          <hr
            style={{ background: "#000000", height: "3px", border: "none" }}
          />
          <FormGroup row>
            <Label for="botid" sm={2}>
              Bot ID <sup style={{ color: "red" }}>*</sup>
            </Label>
            <Col sm={8}>
              <Input
                id="GroupMe_BotID"
                value={myAlertValue.GroupMe_BotID}
                onChange={fillForm}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Col>
            <Col sm={1}>
              <Button
                color="secondary"
                href="https://dev.groupme.com/bots"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="ni ni-bulb-61 text-yellow" />
              </Button>
            </Col>
          </FormGroup>
        </>
      );
    } else if (myAlertValue.type == "Discord") {
      return (
        <>
          <hr
            style={{ background: "#000000", height: "3px", border: "none" }}
          />
          <FormGroup row>
            <Label for="webhook" sm={2}>
              Webhook Url <sup style={{ color: "red" }}>*</sup>
            </Label>

            <Col sm={8}>
              <Input
                id="Discord_WebhookUrl"
                value={myAlertValue.Discord_WebhookUrl}
                onChange={fillForm}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Col>
            <Col sm={1}>
              <Button
                color="secondary"
                href="https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="ni ni-bulb-61 text-yellow" />
              </Button>
            </Col>
          </FormGroup>
        </>
      );
    } else if (myAlertValue.type == "Slack") {
      return (
        <>
          <hr
            style={{ background: "#000000", height: "3px", border: "none" }}
          />
          <FormGroup row>
            <Label for="webhook" sm={2}>
              Webhook Url <sup style={{ color: "red" }}>*</sup>
            </Label>

            <Col sm={8}>
              <Input
                id="Slack_WebhookUrl"
                value={myAlertValue.Slack_WebhookUrl}
                onChange={fillForm}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Col>
            <Col sm={1}>
              <Button
                color="secondary"
                href="https://api.slack.com/messaging/webhooks"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="ni ni-bulb-61 text-yellow" />
              </Button>
            </Col>
          </FormGroup>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <ModalBody>
      <Form>
        <FormGroup>
          <Label for="exampleSelect">Select</Label>
          <Input
            type="select"
            name="select"
            id={referID}
            value={myAlertValue.type}
            key={referID}
            onChange={onSelectChange} // added and then set
          >
            <option></option>
            <option>Coeus Space</option>
            <option>Discord</option>
            <option>GroupMe</option>
            <option>Slack</option>
            <option>Email (Coming Soon)</option>
            <option>SMS (Coming Soon)</option>
          </Input>
        </FormGroup>
        {getForm()}
        <Button color="info" onClick={testalert}>
          Test
        </Button>{" "}
      </Form>
    </ModalBody>
  );
};

export default FormFilling;
