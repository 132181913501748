import * as React from "react";
import { Row, Col } from "reactstrap";

export default function Header({ setTicker }) {
  return (
    <>
      <div
        className="header bg-gradient-info pb-5 pt-5 pt-md-6"
        style={{ marginBottom: "10%" }}
      >
        <Row></Row>
      </div>
    </>
  );
}
