import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Popover, ConfigProvider, notification } from "antd";
import { Avatar, Badge, Space, message } from "antd";
import DropDownNotif from "views/pages/Notification/DropDownNotif";
import { useSelector, useDispatch } from "react-redux";
import getEmail_api from "apis/getEmail";
import { setEmailData } from "features/emailListSlice";

const AdminNavbar = (props) => {
  // Initial Loading
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [notifCount, setNotifCount] = useState(0);
  const { user, logout } = useAuth0();
  const [notifApi, notifContextHolder] = notification.useNotification();
  const [messageApi, msgContextHolder] = message.useMessage();
  const accessToken = useSelector((state) => state.accessToken.value);
  const dispatch = useDispatch();

  const key = "updatable";
  // user.name
  // user.email
  // user.picture
  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const setMessageApi = async (mType, validName) => {
    messageApi.open({
      key,
      type: mType,
      content: validName,
      duration: 3,
    });
  };

  const openNotification = () => {
    notifApi.open({
      message: "Notification Title",
      description:
        "I will close automatically. This is a purposely very very long description that has many many characters and words.",
      duration: 2,
      type: "info",
    });
  };

  const loadInitialData = async () => {
    setConfirmLoading(true);
    if (accessToken != "") {
      var result = await getEmail_api(accessToken); // do sensative changes
      if (result != null) {
        if (result.error == false) {
          var temp = [];
          var myresult = result.message;
          for (let i = 0; i < myresult.length; i++) {
            temp.push({ id: myresult[i][0], email: myresult[i][1] });
          }
          dispatch(setEmailData(temp));
        } else {
          setMessageApi("error", "Error in getting emails");
        }
      } else {
        setMessageApi("error", "Error in getting emails");
      }
    }
    setConfirmLoading(false);
  };

  useEffect(() => {
    loadInitialData();
  }, [accessToken]);

  return (
    <>
      {notifContextHolder}
      {msgContextHolder}
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <span
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </span>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Badge count={notifCount}>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={require("../../assets/img/brand/bell.jpeg")}
                      />
                    </span>
                  </Media>
                </Badge>
              </DropdownToggle>
              <DropDownNotif
                setMessageApi={setMessageApi}
                setNotifCount={setNotifCount}
                notifCount={notifCount}
              />
            </UncontrolledDropdown>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={require("../../assets/img/brand/person.jpg")}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {user.name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={handleLogout}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
