import { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import HeatMap from "../Widgets/HeatMap.js";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Notification from "../Notification/Notification.js";

const Inbox = (props) => {
  return (
    <>
      {/* <Header /> */}
      <Notification />
    </>
  );
};

export default Inbox;
