import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardTitle,
  Button,
} from "reactstrap";
// core components
import getCommunityPage_api from "apis/getCommunityPage.js";
import {
  setCommunityData,
  deleteCommunityData,
} from "features/communityListSlice.js";
import createSubscription_api from "apis/createSubscription.js";
import SubsribeModal from "./SubscribeModal/SubsribeModal.js";
import {
  setMyCommunityData,
  addMyCommunityData,
  increSubMyCommunityData,
} from "features/myCommunityListSlice.js";
import getMyCommunityPage_api from "apis/getMyCommunityPage.js";
import ExploreModal from "./ExploreModal/ExploreModal.js";

const SearchCommunity = ({ setMessageApi, setNotificationApi }) => {
  const [loading, setLoading] = useState(false);
  const [subsribeModal, setSubsribeModal] = useState(false);
  const [exploreModal, setExploreModal] = useState(false);
  const [referID, setReferID] = useState(-1);

  const accessToken = useSelector((state) => state.accessToken.value);
  const dispatch = useDispatch();
  const communityList = useSelector((state) => state.communityList.value);
  const myCommunityList = useSelector((state) => state.myCommunityList.value);

  const loadPage = async () => {
    setLoading(true);
    var tempCommunities = {};
    if (accessToken != "") {
      var result = await getCommunityPage_api(accessToken);
      try {
        if (result != undefined) {
          if (result.error == false) {
            // [37, 'Coeus Studio1234', 'Mon, 08 Jan 2024 17:20:10 GMT', 'feeeebrb', 1]
            var communities = result.message;
            Object.entries(communities).forEach(([v, k]) => {
              tempCommunities[k[0]] = {
                id: k[0],
                name: k[1],
                description: k[3],
                created: k[2].split(" ").slice(1, 4).join(" "),
                subscriber: k[4],
              };
            });
          } else {
            setMessageApi("error", "Page Failed to load, Please Try Again");
            setLoading(false);
          }
        } else {
          setMessageApi("error", "Page Failed to load, Please Try Again");
          setLoading(false);
        }
      } catch (err) {
        setMessageApi("error", "Page Failed to load, Please Try Again");
        setLoading(false);
      }
    }
    if (accessToken != "") {
      var result = await getMyCommunityPage_api(accessToken);
      try {
        if (result != undefined) {
          if (result.error == false) {
            // [37, 'Coeus Studio1234', 'Mon, 08 Jan 2024 17:20:10 GMT', 'feeeebrb', 1]
            var communities = result.message;
            var temp = {};
            var removeIds = [];
            Object.entries(communities).forEach(([v, k]) => {
              temp[k[0]] = {
                id: k[0],
                name: k[1],
                description: k[3],
                created: k[2].split(" ").slice(1, 4).join(" "),
                subscriber: k[4],
              };

              removeIds.push(k[0]);
            });
            dispatch(deleteCommunityData(removeIds));

            for (let i = 0; i < removeIds.length; i++) {
              delete tempCommunities[removeIds[i]];
            }
            dispatch(setCommunityData(tempCommunities));
            dispatch(setMyCommunityData(temp));
          } else {
            setMessageApi("error", "Page Failed to load, Please Try Again");
            setLoading(false);
          }
        } else {
          setMessageApi("error", "Page Failed to load, Please Try Again");
          setLoading(false);
        }
      } catch (err) {
        setMessageApi("error", "Page Failed to load, Please Try Again");
        setLoading(false);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    loadPage();
  }, [accessToken]);

  const handleSubscribeCallback = async (data) => {
    var communityName = communityList[referID].name;
    var result = await createSubscription_api(accessToken, communityName, data);
    try {
      if (result != undefined) {
        if (result.error == false) {
          dispatch(addMyCommunityData([referID, communityList[referID]]));
          dispatch(increSubMyCommunityData(referID));
          dispatch(deleteCommunityData([referID]));
          setSubsribeModal(false);
          setMessageApi("success", "Successfully Added to: " + communityName);
        } else {
          if (result.message == "already subscribed") {
            setMessageApi("error", "Already Subscribed");
          } else {
            setMessageApi("error", "Page Failed to load, Please Try Again");
          }
        }
      } else {
        setMessageApi("error", "Page Failed to load, Please Try Again");
      }
    } catch (err) {
      setMessageApi("error", "Page Failed to load, Please Try Again");
    }
  };

  const onSubscribe = async (e) => {
    setLoading(true);
    var myid = e.target.id;
    setReferID(myid);
    if (accessToken != "") {
      if (myCommunityList.hasOwnProperty(myid)) {
        setMessageApi("error", "Already Subscribed");
      } else {
        setSubsribeModal(true);
      }
    }
    setLoading(false);
  };

  const onExplore = async (e) => {
    setLoading(true);
    setReferID(e.target.id);
    setExploreModal(true);
    setLoading(false);
  };

  return (
    <>
      {subsribeModal ? (
        <SubsribeModal
          setSubsribeModal={setSubsribeModal}
          subsribeModal={subsribeModal}
          handleSubscribeCallback={handleSubscribeCallback}
          setMessageApi={setMessageApi}
          setNotificationApi={setNotificationApi}
        />
      ) : (
        <></>
      )}
      {exploreModal ? (
        <ExploreModal
          setExploreModal={setExploreModal}
          exploreModal={exploreModal}
          setMessageApi={setMessageApi}
          setNotificationApi={setNotificationApi}
          referID={referID}
          myCommunity={false}
        />
      ) : (
        <></>
      )}
      <Row style={{ marginBottom: "2%" }} />
      <Row>
        {loading ? (
          <div>Loading</div>
        ) : (
          Object.keys(communityList).map((key, index) => (
            <Col lg="6" xl="4" key={index}>
              <Card
                className="card-stats mb-4 mb-xl-5"
                style={{
                  border: "1px solid black",
                  height: "90%",
                  maxHeight: "90%",
                }}
              >
                <CardBody
                  style={{
                    height: "225px",
                    maxHeight: "90%",
                  }}
                >
                  <Row>
                    <div className="col">
                      <span className="h2 font-weight-bold mb-0">
                        {communityList[key].name}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                        <i className="fas fa-chart-bar" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-0 mb-7 text-sm">
                    <span className="text-nowrap">
                      <b>Created:</b> {communityList[key].created}
                    </span>{" "}
                    <br />
                    <span className="text-nowrap">
                      <b>Subscribers:</b> {communityList[key].subscriber}
                    </span>{" "}
                    <br />
                    <span className="text-wrap" style={{ height: "100%" }}>
                      {communityList[key].description.length > 20 ? (
                        <>
                          <b>Description:</b>{" "}
                          {communityList[key].description.substring(0, 225) +
                            "..."}
                        </>
                      ) : (
                        <>
                          <b>Description:</b> {communityList[key].description}{" "}
                        </>
                      )}
                    </span>
                  </p>
                </CardBody>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "5%",
                  }}
                >
                  <Button
                    color="info"
                    id={key}
                    onClick={onExplore}
                    style={{
                      width: "35%",
                    }}
                  >
                    Explore
                  </Button>
                  <Button
                    color="info"
                    id={key}
                    onClick={onSubscribe}
                    style={{
                      width: "35%",
                    }}
                  >
                    Add
                  </Button>
                </div>
              </Card>
              <Row style={{ marginBottom: "10%" }}></Row>
            </Col>
          ))
        )}
      </Row>
    </>
  );
};

export default SearchCommunity;
