import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardTitle,
  Button,
} from "reactstrap";
// core components

import {
  setMyCommunityData,
  deleteMyCommunityData,
} from "features/myCommunityListSlice.js";
import getMyCommunityPage_api from "apis/getMyCommunityPage.js";
import deleteSubscription_api from "apis/deleteSubscription";
import ExploreModal from "./ExploreModal/ExploreModal";

import {
  decreSubCommunityData,
  addCommunityData,
} from "features/communityListSlice";

const MyCommunity = ({ setMessageApi, setNotificationApi }) => {
  const [loading, setLoading] = useState(false);
  const [exploreModal, setExploreModal] = useState(false);
  const accessToken = useSelector((state) => state.accessToken.value);
  const dispatch = useDispatch();
  const myCommunityList = useSelector((state) => state.myCommunityList.value);
  const [referID, setReferID] = useState(-1);

  const onUnsubscribe = async (e) => {
    setLoading(true);
    var referID = e.target.id;
    var name = myCommunityList[referID].name;
    var result = await deleteSubscription_api(accessToken, name);
    if (result != undefined) {
      if (result.error == false) {
        dispatch(addCommunityData([referID, myCommunityList[referID]]));
        dispatch(decreSubCommunityData(referID));
        dispatch(deleteMyCommunityData([referID]));
        setMessageApi("success", "Successfully Removed from: " + name);
      } else {
        if (result.message == "Admin") {
          setMessageApi("error", "Cannot remove from your own community");
        } else {
          setMessageApi("error", "Error, Please try again later");
        }
      }
    } else {
      setMessageApi("error", "Error, Please try again later");
    }
    setLoading(false);
  };

  const onExplore = async (e) => {
    setLoading(true);
    setReferID(e.target.id);
    setExploreModal(true);
    setLoading(false);
  };

  return (
    <>
      {exploreModal ? (
        <ExploreModal
          setExploreModal={setExploreModal}
          exploreModal={exploreModal}
          setMessageApi={setMessageApi}
          setNotificationApi={setNotificationApi}
          referID={referID}
          myCommunity={true}
        />
      ) : (
        <></>
      )}
      <Row style={{ marginBottom: "2%" }} />
      <Row>
        {Object.keys(myCommunityList).map((key, index) => (
          <Col lg="6" xl="4" key={index}>
            <Card
              className="card-stats mb-4 mb-xl-5"
              style={{
                border: "1px solid black",
                height: "90%",
                maxHeight: "90%",
              }}
            >
              <CardBody
                style={{
                  height: "225px",
                  maxHeight: "90%",
                }}
              >
                <Row>
                  <div className="col">
                    <span className="h2 font-weight-bold mb-0">
                      {myCommunityList[key].name}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                      <i className="fas fa-chart-bar" />
                    </div>
                  </Col>
                </Row>
                <p className="mt-0 mb-7 text-sm">
                  <span className="text-nowrap">
                    <b>Created:</b> {myCommunityList[key].created}
                  </span>{" "}
                  <br />
                  <span className="text-nowrap">
                    <b>Subscribers:</b> {myCommunityList[key].subscriber}
                  </span>{" "}
                  <br />
                  <span className="text-wrap" style={{ height: "100%" }}>
                    {myCommunityList[key].description.length > 20 ? (
                      <>
                        <b>Description:</b>{" "}
                        {myCommunityList[key].description.substring(0, 225) +
                          "..."}
                      </>
                    ) : (
                      <>
                        <b>Description:</b> {myCommunityList[key].description}{" "}
                      </>
                    )}
                  </span>
                </p>
              </CardBody>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "5%",
                }}
              >
                <Button
                  color="info"
                  id={key}
                  onClick={onExplore}
                  style={{
                    width: "35%",
                  }}
                >
                  Explore
                </Button>
                <Button
                  color="info"
                  id={key}
                  onClick={onUnsubscribe}
                  style={{
                    width: "35%",
                  }}
                >
                  Remove
                </Button>
              </div>
            </Card>
            <Row style={{ marginBottom: "10%" }}></Row>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default MyCommunity;
