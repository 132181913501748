import { createSlice } from "@reduxjs/toolkit";

export const emailListSlice = createSlice({
  name: "emailList",
  initialState: {
    value: [],
  },
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes.
    // Also, no return statement is required from these functions.
    setEmailData: (state, action) => {
      state.value = action.payload;
    },
    addEmailData: (state, action) => {
      var id = action.payload[0];
      var email = action.payload[1];
      state.value = [...state.value, { id: id, email: email }];
    },
    deleteEmailData: (state, action) => {
      var oldId = action.payload;
      for (let i = 0; i < state.value.length; i++) {
        if (state.value[i].id == oldId) {
          state.value.splice([i], 1);
          break;
        }
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setEmailData, addEmailData, deleteEmailData } =
  emailListSlice.actions;

export default emailListSlice.reducer;
