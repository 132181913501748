import Profile from "views/pages/Profile/Profile";
import AlertBuilder from "./views/pages/CustomizeAlert/Complex/AlertBuilder";
import Notification from "views/pages/Notification/Notification";
var profile_routes = [
  {
    path: "/profile",
    name: "Profile",
    icon: "ni ni-settings text-yellow",
    component: <Profile />,
    layout: "/admin",
  },
  {
    path: "/notification",
    name: "Notification",
    icon: "ni ni-settings text-yellow",
    component: <Notification />,
    layout: "/admin",
  },
  {
    path: "/ComplexAlertBuilder",
    name: "Complex Alert Builder",
    icon: "ni ni-settings text-yellow",
    component: <AlertBuilder />,
    layout: "/admin",
  },
];
export default profile_routes;
