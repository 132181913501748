import axios from "axios";
const { API_URL } = require("./constants");

const saveAlert_api = async (
  accessToken,
  input_data,
  output_data,
  alert_data,
  layout_name
) => {
  let data = JSON.stringify({
    alert_data: alert_data,
    input_data: input_data,
    output_data: output_data,
    layout_name: layout_name,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: API_URL + "api/v1/save_alert",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    data: data,
  };

  let result = "";

  await axios
    .request(config)
    .then((response) => {
      result = response.data;
    })
    .catch((error) => {
      return error;
    });

  return result;
};

export default saveAlert_api;
