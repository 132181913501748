import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import getLayout_api from "apis/getLayout";
import AlertsModal from "./AlertsModal";
import InputsModal from "./InputsModal";
import saveAlert_api from "apis/saveAlert";

const EditFlow = ({
  setEditFlowModal,
  editFlowModal,
  layoutName,
  setMessageApi,
  setNotificationApi,
}) => {
  const [loading, setLoading] = useState(false);
  const [alertsModal, setAlertsModal] = useState(false);
  const [inputsModal, setInputsModal] = useState(false);
  const [modalSize, setModalSize] = useState("lg");
  const [inputList, setInputList] = useState({});
  const [alertList, setAlertList] = useState({}); // {"alert1": {"type": "groupme", "key": "123"}}
  const [outputList, setOutputList] = useState({});
  const [currentEdit, setCurrentEdit] = useState("");

  const accessToken = useSelector((state) => state.accessToken.value);
  const layoutList = useSelector((state) => state.layoutsList.value);
  const dispatch = useDispatch();

  const handleCallbackInput = (data) => {
    // node data breaks off here
    inputList[currentEdit] = data;
    setInputList({ ...inputList });
    setCurrentEdit("");
    setInputsModal(false);
    setMessageApi("success", "Inputs Saved");
  };

  const handleCallbackOutput = (data) => {
    // node data breaks off here
    outputList[currentEdit] = data;
    setOutputList({ ...outputList });
    setCurrentEdit("");
    setInputsModal(false);
  };

  const handleCallbackAlert = (data) => {
    // node data breaks off here
    // json form data for credentials
    // example: {"type": "groupme", "key": "123"}
    alertList[currentEdit] = data;
    setAlertList({ ...alertList });
    setCurrentEdit("");
    setAlertsModal(false);
    setMessageApi("success", "Alerts Saved");
  };

  const toggle = () => {
    setEditFlowModal(false);
  };

  const save = async () => {
    setLoading(true);
    var result = await saveAlert_api(
      accessToken,
      inputList,
      outputList,
      alertList,
      layoutName
    );
    try {
      if (result != undefined) {
        if (result.error == false) {
          setLoading(false);
          setEditFlowModal(false);
          setMessageApi("success", "Inputs and Alerts Saved");
        } else {
          setMessageApi("error", "Please Try Again");
          setLoading(false);
        }
      } else {
        setMessageApi("error", "Please Try Again");
        setLoading(false);
      }
    } catch (err) {
      setMessageApi("error", "Please Try Again");
      setLoading(false);
    }
    setLoading(false);
  };

  const inputButton = async (e) => {
    setCurrentEdit(e.target.id);
    setInputsModal(true);
  };

  const outputButton = async (e) => {
    setCurrentEdit(e.target.id);
  };

  const alertButton = async (e) => {
    setCurrentEdit(e.target.id);
    setAlertsModal(true);
  };

  const getLayoutData = async () => {
    setLoading(true);
    var result = await getLayout_api(accessToken, layoutName);
    var tempInput = {};
    var tempOutput = {};
    var tempAlert = {};
    var nodes = result.message[0][3];
    // if any of these is null -> alerts have not beel edited
    var myalerts = result.message[0][5];
    var myinputs = result.message[0][6];
    var myoutputs = result.message[0][7];
    if (nodes != null) {
      // if there exist some node and there is presaved alerts -> could be {} (empty from saving)
      Object.entries(nodes).forEach(([k, v]) => {
        // k -> model name, v -> [pressed, data]
        if (v.data.type == "input") {
          tempInput[v.id] = myinputs == null ? {} : myinputs[v.id];
        } else if (v.data.type == "output") {
          // either an output or alert
          tempOutput[v.id] = myoutputs == null ? {} : myoutputs[v.id];
        } else if (v.data.type == "alert") {
          tempAlert[v.id] = myalerts == null ? {} : myalerts[v.id];
        }
      });
      setAlertList(tempAlert);
      setInputList(tempInput);
      setOutputList(tempOutput);
    }

    setLoading(false);
  };

  useEffect(() => {
    getLayoutData();
  }, []);

  return (
    <>
      {alertsModal ? (
        <AlertsModal
          setAlertsModal={setAlertsModal}
          alertsModal={alertsModal}
          parentCallback={handleCallbackAlert}
          currentEdit={currentEdit}
          parentAlertData={alertList[currentEdit]}
          setMessageApi={setMessageApi}
          setNotificationApi={setNotificationApi}
        />
      ) : (
        <></>
      )}
      {inputsModal ? (
        <InputsModal
          setInputsModal={setInputsModal}
          inputsModal={inputsModal}
          parentCallback={handleCallbackInput}
          currentEdit={currentEdit}
          parentInputsData={inputList[currentEdit]}
        />
      ) : (
        <></>
      )}
      <Modal isOpen={editFlowModal} toggle={toggle} size={modalSize}>
        <ModalHeader toggle={toggle}>Edit Alerts</ModalHeader>

        <ModalBody>
          <Form>
            <FormGroup>
              {/* <Label for="LayoutName">
                                Alerts
                            </Label> */}
              <CardBody>
                <Row>
                  <Col xs="3">
                    {Object.keys(inputList).map((myinputs) => (
                      <>
                        <Button
                          onClick={inputButton}
                          key={myinputs}
                          id={myinputs}
                          style={{
                            border: "2px solid #14A44D",
                            width: "100%",
                            marginBottom: "5%",
                          }}
                        >
                          <div id={myinputs}>
                            <i
                              id={myinputs}
                              className="ni ni-curved-next text-green"
                            />
                            <span id={myinputs}>{myinputs}</span>
                          </div>
                        </Button>
                        <div></div>
                      </>
                    ))}
                  </Col>

                  <Col xs="5">
                    <Card
                      style={{
                        border: "2px solid #54B4D3",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                      className="card-stats mb-4 mb-xl-0"
                    >
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Layout Name
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {layoutName}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col xs="3">
                    {Object.keys(outputList).map((myoutput) => (
                      <>
                        <Button
                          onClick={outputButton}
                          key={myoutput}
                          disabled={true}
                          id={myoutput}
                          style={{
                            border: "2px solid #8965E0",
                            width: "100%",
                            marginBottom: "5%",
                          }}
                        >
                          <div id={myoutput}>
                            <i
                              id={myoutput}
                              className="ni ni-send text-primary"
                            />
                            <span id={myoutput}>{myoutput}</span>
                          </div>
                        </Button>
                        <div></div>
                      </>
                    ))}

                    {Object.keys(alertList).map((myalerts) => (
                      <>
                        <Button
                          onClick={alertButton}
                          id={myalerts}
                          key={myalerts}
                          style={{
                            border: "2px solid #DC4C64",
                            width: "100%",
                            marginBottom: "5%",
                          }}
                        >
                          <div id={myalerts}>
                            <i
                              id={myalerts}
                              className="ni ni-bell-55 text-red"
                            />
                            <span id={myalerts}>{myalerts}</span>
                          </div>
                        </Button>
                        <div></div>
                      </>
                    ))}
                  </Col>
                </Row>
              </CardBody>
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter>
          {loading ? (
            <div>Saving...</div>
          ) : (
            <>
              <Button color="success" onClick={save} disabled={loading}>
                Save
              </Button>{" "}
              <Button color="secondary" onClick={toggle} disabled={loading}>
                Cancel
              </Button>{" "}
            </>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditFlow;
