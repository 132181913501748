// reactstrap components
import { useState, useEffect } from "react";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
// core components
import { useSelector, useDispatch } from "react-redux";
import TestModal from "./TestModal.js";
import deployLayout_api from "apis/deployLayout.js";
import stopLayout_api from "apis/stopLayout.js";
import getFilteredLayoutPage_api from "apis/getFilteredLayoutPage.js";
import deleteLayout_api from "apis/deleteLayout.js";
import {
  deleteFilteredLayoutData,
  editFilteredLayoutStatusData,
  setFilteredLayoutData,
} from "features/filteredLayoutsListSlice";
import { message } from "antd";
import getSimpleAlert_api from "apis/getSimpleAlert.js";
import deleteSimpleAlert_api from "apis/deleteSimpleAlert.js";
import deploySimpleAlert_api from "apis/deploySimpleAlert.js";
import stopSimpleAlert_api from "apis/stopSimpleAlert.js";

const Deploy = ({ title }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [layoutTarget, setLayoutTarget] = useState("");
  const [testModal, setTestModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const accessToken = useSelector((state) => state.accessToken.value);
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";
  const filteredlayoutList = useSelector(
    (state) => state.filteredLayoutsList.value
  );
  const setMessageApi = async (mType, validName) => {
    messageApi.open({
      key,
      type: mType,
      content: validName,
      duration: 3,
    });
  };
  const dispatch = useDispatch();
  // not tested -> not active -> active
  const template = {
    layout1: { budget: 2500, status: "not active", completion: 60 },
    layout2: { budget: 2500, status: "not tested", completion: 60 },
    layout3: { budget: 2500, status: "active", completion: 70 },
    layout4: { budget: 2500, status: "stopped", completion: 60 },
    layout5: { budget: 2500, status: "stopped", completion: 60 },
  };
  const statusColor = {
    "not active": "bg-info",
    "not tested": "bg-warning",
    active: "bg-success",
    stopped: "bg-pink",
  };
  // Use Effect to load page
  const loadPage = async () => {
    setConfirmLoading(true);
    if (accessToken != "") {
      try {
        var temp = {};
        var result = await getFilteredLayoutPage_api(accessToken);
        if (result != undefined) {
          if (result.error == false) {
            var layoutresult = result.message;
            Object.entries(layoutresult).forEach(([v, k]) => {
              temp[k[0].toString() + "C"] = {
                // key will be layout/alert_id+"C"
                name: v,
                status: k[2],
                time: k[3],
                type: "complex",
              };
            });
          } else {
            setMessageApi("error", "Cannot get Complex Alerts");
          }
        }

        var result2 = await getSimpleAlert_api(accessToken);
        if (result2 != undefined) {
          if (result2.error == false) {
            var simpleAlert = result2.message;
            Object.entries(simpleAlert).forEach(([v, k]) => {
              temp[v.toString() + "S"] = {
                name: k[5],
                status: k[4],
                time: k[3],
                type: "simple",
              };
            });
          } else {
            setMessageApi("error", "Cannot get Simple Alerts");
          }
        }
        dispatch(setFilteredLayoutData(temp));
      } catch (e) {
        setMessageApi("error", "Cannot load page");
      }
    }
    setConfirmLoading(false);
  };
  useEffect(() => {
    loadPage();
  }, [accessToken]);

  const test = async (e) => {
    setLayoutTarget(e.target.id);
    setTestModal(true);
  };

  const deploy = async (e) => {
    setLayoutTarget(e.target.id);
    deployLayout(e.target.id);
  };
  const deployLayout = async (target_id) => {
    setConfirmLoading(true); // dont start if its not tested
    var layoutData = filteredlayoutList[target_id];
    if (layoutData.status == "not tested") {
      setMessageApi("error", "Run Test First");
    } else if (layoutData.status == "active") {
      setMessageApi("error", "Already Started");
    } else if (accessToken != "") {
      if (layoutData.type == "complex") {
        try {
          var result = await deployLayout_api(accessToken, layoutData.name);
          if (result != undefined) {
            setMessageApi("success", "Alert Started");
            dispatch(editFilteredLayoutStatusData([target_id, "active"]));
          }
        } catch (e) {
          console.log(e.message);
        }
      } else {
        try {
          var result = await deploySimpleAlert_api(accessToken, target_id);
          if (result != undefined) {
            if (result.error == false) {
              setMessageApi("success", "Alert Started");
              dispatch(editFilteredLayoutStatusData([target_id, "active"]));
            } else {
              setMessageApi("error", "Error in Starting Alert");
            }
          }
        } catch (e) {
          console.log(e.message);
        }
      }
    } else {
      setMessageApi("error", "Please Try Again Later!");
    }
    setConfirmLoading(false);
  };

  const stop = async (e) => {
    setLayoutTarget(e.target.id);
    stopLayout(e.target.id);
  };
  const stopLayout = async (target_id) => {
    setConfirmLoading(true);
    var layoutData = filteredlayoutList[target_id];
    if (layoutData.status != "active") {
      setMessageApi("error", "Alert is not active");
    } else if (accessToken != "") {
      try {
        if (layoutData.type == "complex") {
          var result = await stopLayout_api(accessToken, layoutData.name);
          if (result != undefined) {
            if (result.error == false) {
              setMessageApi("success", "Alert Stopped");
              dispatch(editFilteredLayoutStatusData([target_id, "not active"]));
            } else {
              setMessageApi("error", "Error in Stopping Alert");
            }
          }
        } else {
          var result = await stopSimpleAlert_api(accessToken, target_id);
          if (result != undefined) {
            if (result.error == false) {
              setMessageApi("success", "Alert Stopped");
              dispatch(editFilteredLayoutStatusData([target_id, "not active"]));
            } else {
              setMessageApi("error", "Error in Stopping Alert");
            }
          }
        }
      } catch (e) {
        console.log(e.message);
      }
    }
    setConfirmLoading(false);
  };

  const deleteAlert = async (e) => {
    setLayoutTarget(e.target.id);
    setDeleteModal(true);
  };
  const delete_Layout = async () => {
    toggle();
    setConfirmLoading(true);
    var target_id = layoutTarget;
    var layoutData = filteredlayoutList[target_id];
    try {
      if (layoutData.type == "complex") {
        var result = await deleteLayout_api(accessToken, layoutData.name);
        if (result != undefined) {
          if (result.error == false) {
            dispatch(deleteFilteredLayoutData(target_id));
            setMessageApi("success", "Alert Deleted");
            setConfirmLoading(false);
          } else {
            setMessageApi("error", "Please Try Again");
            setConfirmLoading(false);
          }
        } else {
          setMessageApi("error", "Please Try Again");
          setConfirmLoading(false);
        }
      } else {
        var result = await deleteSimpleAlert_api(accessToken, target_id);
        if (result != undefined) {
          if (result.error == false) {
            dispatch(deleteFilteredLayoutData(target_id));
            setMessageApi("success", "Alert Deleted");
            setConfirmLoading(false);
          } else {
            setMessageApi("error", "Please Try Again");
            setConfirmLoading(false);
          }
        } else {
          setMessageApi("error", "Please Try Again");
          setConfirmLoading(false);
        }
      }
    } catch (err) {
      setMessageApi("error", "Please Try Again");
      setConfirmLoading(false);
    }
  };

  const toggle = () => {
    setDeleteModal(false);
  };
  // steps for this ---
  // - cannot update models/layout/alerts/input while it is deployed
  // 9/14/2023 - lets focus on testing
  return (
    <>
      {contextHolder}
      {/* Page content */}
      {testModal ? (
        <TestModal
          setTestModal={setTestModal}
          testModal={testModal}
          layoutTarget={layoutTarget}
        />
      ) : (
        <></>
      )}
      {deleteModal ? (
        <Modal isOpen={deleteModal} size="sm" toggle={toggle}>
          <ModalBody>Are you sure you want to delete?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={delete_Layout}>
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      ) : (
        <></>
      )}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">{title}</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Alerts</th>
                    <th scope="col">Status</th>
                    <th scope="col">Created</th>
                    <th scope="col">Type</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {confirmLoading ? (
                    <Media>
                      <span className="mb-0 text-sm">loading</span>
                    </Media>
                  ) : (
                    Object.keys(filteredlayoutList).map((key, index) => (
                      <tr key={key}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {filteredlayoutList[key].name}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i
                              className={
                                statusColor[filteredlayoutList[key].status]
                              }
                            />

                            {filteredlayoutList[key].status}
                          </Badge>
                        </td>
                        <td>
                          <div>{filteredlayoutList[key].time}</div>
                        </td>
                        <td>
                          <div>{filteredlayoutList[key].type}</div>
                        </td>
                        {filteredlayoutList[key].type == "complex" ? (
                          <td>
                            <Button id={key} onClick={test}>
                              Test
                            </Button>
                          </td>
                        ) : (
                          <td></td>
                        )}

                        <td>
                          <Button id={key} onClick={deploy}>
                            Start
                          </Button>
                        </td>
                        <td>
                          <Button id={key} onClick={stop}>
                            Stop
                          </Button>
                        </td>
                        <td>
                          <Button id={key} onClick={deleteAlert}>
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="..."></nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default Deploy;
