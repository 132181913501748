import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import { App } from "./app";
import store from "./app/store";
import { Provider } from "react-redux";
import { SocketProvider } from "app/SocketContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Auth0ProviderWithNavigate>
      <Provider store={store}>
        <SocketProvider>
          <App />
        </SocketProvider>
      </Provider>
    </Auth0ProviderWithNavigate>
  </BrowserRouter>
);
