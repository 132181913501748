import { useCallback } from "react";
import ReactFlow, { MiniMap, Controls, Background, addEdge } from "reactflow";

import { Button } from "reactstrap";

import { useState, useEffect, useRef } from "react";
import "reactflow/dist/style.css";
import ModelSelect from "./ModelSelect";

const initBgColor = "#FFFFFF";

// const initialEdges = [];
// const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }];
const initialEdges = [];
const LayoutDesign = ({
  nodes,
  setNodes,
  onNodesChange,
  edges,
  setEdges,
  onEdgesChange,
  viewOnly,
}) => {
  const [modelSelect, setModelSelect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addedNodes, setAddedNodes] = useState([]);
  const yPos = useRef(0);
  const xPos = useRef(0);

  const inputCount = useRef(1);
  const outputCount = useRef(1);
  const alertCount = useRef(1);
  const increPos = () => {
    yPos.current += 25;
    xPos.current += 25;
  };

  const handleCallback = (nodesNames, nodesData) => {
    // node data breaks off here
    setLoading(true);
    setAddedNodes([...addedNodes, ...nodesNames]);
    var temp = [];
    Object.entries(nodesData).forEach(([k, v]) => {
      // k -> model name, v -> [pressed, data]
      increPos();
      temp.push({
        id: v.name, // if we set this to v.name then we dont need nodesData
        type: "default",
        style: { border: "2px solid #54B4D3" },
        position: { x: xPos.current, y: yPos.current },
        data: { label: v.name, type: "model", currdata: v },
      });
    });
    setNodes([...nodes, ...temp]); // add to reactflow graph
    setLoading(false);
  };

  const addModel = useCallback(() => {
    setModelSelect(true);
  }, []);

  const addInput = useCallback(() => {
    setLoading(true);
    increPos();
    setNodes((nodes) => {
      return [
        ...nodes,
        {
          id: "input" + inputCount.current,
          type: "input",
          style: { border: "2px solid #14A44D" },
          position: { x: xPos.current, y: yPos.current },
          data: {
            label: "input" + inputCount.current,
            type: "input",
            id: inputCount.current,
          },
        },
      ];
    });
    inputCount.current += 1;
    setLoading(false);
  }, []);

  const addOutput = useCallback(() => {
    setLoading(true);
    increPos();
    setNodes((nodes) => {
      return [
        ...nodes,
        {
          id: "output" + outputCount.current,
          type: "output",
          style: { border: "2px solid #8965E0" },
          position: { x: xPos.current, y: yPos.current },
          data: {
            label: "output" + outputCount.current,
            type: "output",
            id: outputCount.current,
          },
        },
      ];
    });
    outputCount.current += 1;
    setLoading(false);
  }, []);

  const addALert = useCallback(() => {
    setLoading(true);
    increPos();
    setNodes((nodes) => {
      return [
        ...nodes,
        {
          id: "alert" + alertCount.current, // alertCount should be recalculated from previous saved version
          type: "output",
          style: { border: "2px solid #DC4C64" },
          position: { x: xPos.current, y: yPos.current },
          data: {
            label: "alert" + alertCount.current,
            type: "alert",
            id: alertCount.current,
          },
        },
      ];
    });
    alertCount.current += 1;
    setLoading(false);
  }, []);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const onNodesDelete = useCallback(
    (deleted) => {
      var temp = addedNodes;
      Object.entries(deleted).forEach(([k, v]) => {
        // k -> model name, v -> [pressed, data]
        if (v.type == "default") {
          const index = temp.indexOf(v.data.label);
          temp.splice(index, 1);
        }
      });
      setAddedNodes(temp);
    },
    [nodes, edges]
  );

  const settingAddedNodes = async () => {
    // have this bc so we already have added nodes, therefore updateding models to add
    var temp = [];
    Object.entries(nodes).forEach(([k, v]) => {
      // k -> model name, v -> [pressed, data]
      temp.push(v.data.label);
      var nodetype = v.data.type;
      var nodecount = v.data.id;
      if (nodetype == "output") {
        if (nodecount >= outputCount.current) {
          outputCount.current = nodecount + 1;
        }
      } else if (nodetype == "input") {
        if (nodecount >= inputCount.current) {
          inputCount.current = nodecount + 1;
        }
      } else if (nodetype == "alert") {
        if (nodecount >= alertCount.current) {
          alertCount.current = nodecount + 1;
        }
      }
    });
    setAddedNodes(temp);
  };

  useEffect(() => {
    settingAddedNodes();
  }, []);

  return (
    <>
      {modelSelect ? (
        <ModelSelect
          setModelSelect={setModelSelect}
          modelSelect={modelSelect}
          addedNodes={addedNodes}
          setAddedNodes={setAddedNodes}
          parentCallback={handleCallback}
        />
      ) : (
        <></>
      )}
      {!viewOnly ? (
        <>
          <Button color="info" onClick={addModel}>
            Add Model
          </Button>{" "}
          <Button color="success" onClick={addInput}>
            Add Input
          </Button>{" "}
          <Button color="warning" onClick={addOutput}>
            Add Output
          </Button>{" "}
          <Button color="danger" onClick={addALert}>
            Add Alert
          </Button>{" "}
        </>
      ) : (
        <></>
      )}
      <h3 />
      <div style={{ height: "70vh", width: "100%", border: "2px solid black" }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onNodesDelete={onNodesDelete}
          onConnect={onConnect}
          style={{ background: initBgColor }}
        >
          <MiniMap />
          <Controls />
          <Background />
        </ReactFlow>
      </div>
    </>
  );
};

export default LayoutDesign;
