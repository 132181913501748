import * as React from "react";
import { Row, Col } from "reactstrap";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Tickers from "./tickerList";

export default function Header() {
  return (
    <>
      <div
        className="header bg-gradient-info pb-5 pt-5 pt-md-6"
        style={{ marginBottom: "10%" }}
      ></div>
    </>
  );
}
