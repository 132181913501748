import { useState, useEffect } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
// import Header from "components/Headers/Header.js";
import Header from "./Header";
import Models from "./Models/Models";
import Layout from "./Layout/Layout";
import Alerts from "./Alerts/Alerts";
import Deploy from "./Deploy/Deploy";
const AlertBuilder = () => {
  const [activeStep, setActiveStep] = useState(0);
  const title = "Test and Deployment";
  const getStep = () => {
    if (activeStep == 0) {
      return <Models />;
    } else if (activeStep == 1) {
      return <Layout />;
    } else if (activeStep == 2) {
      return <Alerts />;
    } else if (activeStep == 3) {
      return <Deploy title={title} />;
    } else {
      <></>;
    }
  };

  return (
    <>
      <Header activeStep={activeStep} setActiveStep={setActiveStep} />
      {getStep()}
    </>
  );
};

export default AlertBuilder;
