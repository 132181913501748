import { useState, useEffect } from "react";
import { withRouter, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Deploy from "./Complex/Deploy/Deploy.js";
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardTitle,
} from "reactstrap";

import Header from "./Header.js";
import { useSelector, useDispatch } from "react-redux";
import SimpleAlert from "./Simple/SimpleAlert.js";
import { message, Watermark, notification } from "antd";

const CustomizeAlert = (props) => {
  const [simpleAlert, setSimpleAlert] = useState(false);
  const accessToken = useSelector((state) => state.accessToken.value);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [notifApi, notifContextHolder] = notification.useNotification();
  const key = "updatable";
  const nextPath = (path) => {
    navigate(path);
  };
  const title = "Alert Summary";

  const setMessageApi = async (mType, validName) => {
    messageApi.open({
      key,
      type: mType,
      content: validName,
      duration: 3,
    });
  };

  const setNotificationApi = (originate, msg) => {
    notifApi.open({
      message: originate,
      description: msg,
      duration: 3,
      type: "info",
    });
  };

  return (
    <>
      {contextHolder}
      {notifContextHolder}
      <Header />
      {/* Page content */}
      {simpleAlert ? (
        <SimpleAlert
          simpleAlert={simpleAlert}
          setSimpleAlert={setSimpleAlert}
          setMessageApi={setMessageApi}
          setNotificationApi={setNotificationApi}
        />
      ) : (
        <></>
      )}
      <Container className="mt--9" fluid>
        <div className="header-body" style={{ marginBottom: "5%" }}>
          <Row>
            <Col lg="12" xl="1"></Col>
            <Col lg="12" xl="4">
              <Card
                className="card-stats mb-4 mb-xl-0"
                style={{ border: "3px solid black" }}
              >
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        <span
                          className="h2 font-weight-bold mb-0"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          BUILD A&nbsp;<u>SIMPLE</u>&nbsp;ALERT
                        </span>
                      </CardTitle>
                    </div>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="text-nowrap">Price alerts</span>
                    <br />
                    <span className="text-nowrap">Volume</span>
                    <br />
                    <span className="text-nowrap">Earnings Date</span>
                    <br />
                    <span className="text-nowrap">etc...</span>
                    <br />
                    <br />
                    <br />
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      color="info"
                      onClick={() => {
                        setSimpleAlert(true);
                      }}
                    >
                      Get Started
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="12" xl="1"></Col>
            <Col lg="12" xl="4">
              <Watermark
                content={["Coming Soon"]}
                gap={[50, 50]}
                style={{ position: "relative", zIndex: 0 }}
              >
                <Card
                  className="card-stats mb-4 mb-xl-0"
                  style={{ border: "3px solid black" }}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <span
                            className="h2 font-weight-bold mb-0"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            BUILD A&nbsp;<u>COMPLEX</u>&nbsp;ALERT
                          </span>
                        </CardTitle>
                      </div>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-nowrap">X-day Moving Averages</span>
                      <br />
                      <span className="text-nowrap">Analyst Rating</span>
                      <br />
                      <span className="text-nowrap">
                        IPO and Price Offerings
                      </span>
                      <br />
                      <span className="text-nowrap">
                        Relative Strength Index
                      </span>
                      <br />
                      <span className="text-nowrap">News</span>
                      <br />
                      <span className="text-nowrap">etc...</span>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          color="info"
                          onClick={() =>
                            setMessageApi("success", "Coming Soon!")
                          }
                          // onClick={() => nextPath("/admin/ComplexAlertBuilder")}
                        >
                          Get Started
                        </Button>
                      </div>
                    </p>
                  </CardBody>
                </Card>
              </Watermark>
            </Col>
          </Row>
          <br />
          <br />
          <br />
        </div>
        <Deploy title={title} />
      </Container>
    </>
  );
};

export default CustomizeAlert;
