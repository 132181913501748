const requireField = {
  Discord: ["Discord_WebhookUrl"],
  GroupMe: ["GroupMe_BotID"],
  Slack: ["Slack_WebhookUrl"],
  "Coeus Space": [],
  Email: [],
};

const checkInput = (data) => {
  var keys = Object.keys(data);
  for (let i = 0; i < keys.length; i++) {
    var currentkey = keys[i];
    var currentdata = data[currentkey];
    if (currentdata.type != "") {
      var fields = requireField[currentdata.type];
      for (let i = 0; i < fields.length; i++) {
        if (
          currentdata[fields[i]] == "" ||
          currentdata[fields[i]] == undefined
        ) {
          return "Please fill out all required fields";
        }
      }
    }
    // else {
    //   return "";
    //   // allow empty alert types for now
    //   return "Please fill out all alert types";
    // }
  }
  return "";
};

export { requireField };
export { checkInput };
