import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button, Container, Row, Col } from "reactstrap";

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/admin/Inbox",
      },
      authorizationParams: {
        prompt: "login",
        audience: "https://dev-g63l0u2k3tmuhpdx.us.auth0.com/api/v2/",
      },
    });
  };

  return (
    <Button
      className="button__login"
      onClick={handleLogin}
      style={{
        width: "17%",
        height: "15%",
        fontSize: "100%",
        borderWidth: "2px",
        borderColor: "blue",
      }}
    >
      Start Here
    </Button>
  );
};
