import { createSlice } from "@reduxjs/toolkit";

export const layoutsListSlice = createSlice({
  name: "layoutsList",
  initialState: {
    value: [],
  },
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes.
    // Also, no return statement is required from these functions.
    setLayoutData: (state, action) => {
      state.value = action.payload;
    },
    addLayoutData: (state, action) => {
      if (state.value.length == 0) {
        var newId = 0;
      } else {
        var newId = state.value.slice(-1)[0].id + 1;
      }
      state.value = [...state.value, { id: newId, name: action.payload }];
    },
    editLayoutData: (state, action) => {
      var oldLayoutName = action.payload[0];
      var newLayoutName = action.payload[1];
      for (let i = 0; i < state.value.length; i++) {
        if (state.value[i].name == oldLayoutName) {
          state.value[i].name = newLayoutName;
          break;
        }
      }
    },
    deleteLayoutData: (state, action) => {
      var oldLayoutName = action.payload;
      for (let i = 0; i < state.value.length; i++) {
        if (state.value[i].name == oldLayoutName) {
          state.value.splice([i], 1);
          break;
        }
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLayoutData,
  addLayoutData,
  editLayoutData,
  deleteLayoutData,
} = layoutsListSlice.actions;

export default layoutsListSlice.reducer;
