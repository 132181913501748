import { createSlice } from "@reduxjs/toolkit";

export const filteredLayoutsListSlice = createSlice({
  name: "filteredLayoutsList",
  initialState: {
    value: {},
  },
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes.
    // Also, no return statement is required from these functions.
    setFilteredLayoutData: (state, action) => {
      state.value = action.payload;
    },
    addFilteredLayoutData: (state, action) => {
      var referID = action.payload[0];
      var data = action.payload[1];
      state.value[referID] = data;
    },
    editFilteredLayoutStatusData: (state, action) => {
      var name = action.payload[0];
      var status = action.payload[1];
      state.value[name].status = status;
    },
    deleteFilteredLayoutData: (state, action) => {
      var name = action.payload;
      delete state.value[name];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFilteredLayoutData,
  editFilteredLayoutStatusData,
  deleteFilteredLayoutData,
  addFilteredLayoutData,
} = filteredLayoutsListSlice.actions;

export default filteredLayoutsListSlice.reducer;
