import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import isCommunityAdmin_api from "apis/isCommunityAdmin";
import { deleteMyCommunityData } from "features/myCommunityListSlice";
import deleteCommunity_api from "apis/deleteCommunity";
const CommunityAdmin = ({
  setMessageApi,
  isAdminCheck,
  profileInfo,
  exploreModalToggle,
}) => {
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [content, setContent] = useState("");

  const accessToken = useSelector((state) => state.accessToken.value);

  const dispatch = useDispatch();

  const loadPage = async () => {
    setLoading(true);
    if (accessToken != "") {
      var result = await isCommunityAdmin_api(accessToken, profileInfo.name);
      try {
        if (result != undefined) {
          if (result.error == false) {
            setIsAdmin(true);
            setMessageApi("success", "Gained Access");
          } else {
            setContent("You don't access to this page");
            setMessageApi("error", "You don't access to this page");
          }
        } else {
          setContent("You don't access to this page");
          setMessageApi("error", "You don't access to this page");
        }
      } catch (err) {
        setContent("You don't access to this page");
        setMessageApi("error", "You don't access to this page");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!isAdminCheck) {
      setContent("You don't access to this page");
      setMessageApi("error", "You don't access to this page");
    } else {
      loadPage();
    }
  }, []);

  const deleteCommunityModal = async (e) => {
    setDeleteModal(true);
  };

  const deleteCommunity = async (e) => {
    setLoading(true);
    if (accessToken != "") {
      var result = await deleteCommunity_api(accessToken, profileInfo.name);
      try {
        if (result != undefined) {
          if (result.error == false) {
            setDeleteModal(false);
            exploreModalToggle();
            setMessageApi(
              "success",
              "Successfully deleted your Community: " + profileInfo.name
            );
            dispatch(deleteMyCommunityData([profileInfo.id]));
          } else {
            setMessageApi("error", "Please try again later");
          }
        } else {
          setMessageApi("error", "Please try again later");
        }
      } catch (err) {
        setMessageApi("Please try again later");
      }
    }
    setLoading(false);
  };

  const toggle = () => {
    setDeleteModal(false);
  };

  return (
    <ModalBody>
      {deleteModal ? (
        <Modal isOpen={deleteModal} size="sm" toggle={toggle}>
          <ModalBody>Are you sure you want to delete?</ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={deleteCommunity}>
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      ) : (
        <></>
      )}
      <Form>
        <FormGroup>{content}</FormGroup>
        {isAdmin ? (
          <Button color="danger" onClick={deleteCommunityModal}>
            Delete Community
          </Button>
        ) : (
          <></>
        )}
      </Form>
    </ModalBody>
  );
};

export default CommunityAdmin;
