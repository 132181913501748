import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import io from "socket.io-client";
import { useSelector, useDispatch } from "react-redux";

const SocketContext = React.createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = React.useState();
  const [socketAuth, setsocketAuth] = useState(false);
  const accessToken = useSelector((state) => state.accessToken.value);
  const newSocket = useRef(null);

  useEffect(() => {
    if (accessToken != "") {
      const params = {
        token: "Bearer " + accessToken,
      };
      const options = {
        query: params,
        transports: ["websocket"],
        upgrade: false,
      };

      if (newSocket.current === null) {
        // TODO Deploy
        var url = "https://recoeus.net";
        // var url = "http://127.0.0.1:8000";
        newSocket.current = io(url, options);
        setSocket(newSocket.current);
      }

      newSocket.current.emit("message_from_client", "Hello from React");

      // // Example: Receive a message from the server
      newSocket.current.on("connect", () => {
        console.log("socket connected");
        setsocketAuth(true);
      });

      newSocket.current.on("disconnect", () => {
        console.log("socket disconnected");
        setsocketAuth(false);
      });

      return () => {
        // forced closing
        console.log("socket closed");
        // Clean up the socket connection when the component is unmounted
        if (newSocket.current !== null) {
          newSocket.current.disconnect();
          newSocket.current = null;
        }
        // newSocket.current.disconnect();
      };
    }
  }, [accessToken]);

  return (
    <SocketContext.Provider value={{ socket, socketAuth }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  const { socket, socketAuth } = useContext(SocketContext);
  return { socket, socketAuth };
};
