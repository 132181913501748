import Inbox from "views/pages/Dashboard/Inbox";
import CustomizeAlert from "views/pages/CustomizeAlert/CustomizeAlert.js";
import Info from "views/pages/Info/Info";
import Marketplace from "views/pages/AlertMarketplace/Marketplace";
import CreatorSettings from "views/pages/CreatorSettings/CreatorSettings";

var routes = [
  {
    path: "/Inbox",
    name: "My Inbox",
    icon: "ni ni-tv-2 text-black",
    component: <Inbox />,
    layout: "/admin",
  },
  {
    path: "/Info",
    name: "Info",
    icon: "ni ni-chart-bar-32 text-info",
    component: <Info />,
    layout: "/admin",
  },
  {
    path: "/CustomizeAlert",
    name: "Customize Alert",
    icon: "ni ni-bell-55 text-danger",
    component: <CustomizeAlert />,
    layout: "/admin",
  },
  {
    path: "/AlertMarketplace",
    name: "Alert Marketplace",
    icon: "ni ni-bag-17 text-green",
    component: <Marketplace />,
    layout: "/admin",
  },
  {
    path: "/CreatorSettings",
    name: "Creator Settings",
    icon: "ni ni-settings-gear-65 text-purple",
    component: <CreatorSettings />,
    layout: "/admin",
  },
];
export default routes;
