import { Form, FormGroup } from "reactstrap";
import { useState, useEffect, useRef, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert, Flex, message, Spin } from "antd";

import { List, Avatar, Input, Button } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { SendOutlined } from "@ant-design/icons";
import isCommunityAdmin_api from "apis/isCommunityAdmin";
import "antd/dist/reset.css";
import createCommunityMsg_api from "apis/createCommunityMsg";
import getCommunityMsg_api from "apis/getCommunityMsg";
import { useSocket } from "app/SocketContext";

const { TextArea } = Input;

const CommunityAlerts = ({ setMessageApi, profileInfo }) => {
  const [{ messages }, setMessages] = useReducer(
    (state, action) => {
      if (action.type === "NEW_MESSAGE") {
        state.messages.push(action.message);
        return state;
      } else if (action.type === "LOAD_MESSAGE") {
        state.messages = action.message;
        return state;
      }
      return state;
    },
    { messages: [] }
  );
  const [newMessage, setNewMessage] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isAdmin, setIsAdmin] = useState(false);
  const [oldMsgLoaded, setOldMsgLoaded] = useState(false);
  const accessToken = useSelector((state) => state.accessToken.value);

  const [loading, setLoading] = useState(false);
  const messagesContainerRef = useRef(null);
  const { socket, socketAuth } = useSocket();

  const loadPage = async () => {
    setLoading(true);
    if (accessToken != "") {
      var result = await getCommunityMsg_api(accessToken, profileInfo.name);
      try {
        if (result != undefined) {
          if (result.error == false) {
            setIsAdmin(result.isAdmin);
            var temp = [];
            var msgResult = result.message;
            for (let i = 0; i < msgResult.length; i++) {
              temp.push({
                text: msgResult[i][1],
                user: "Admin",
                time: msgResult[i][0],
              });
            }
            setMessages({ type: "LOAD_MESSAGE", message: temp });
            setOldMsgLoaded(true);
          } else {
            setMessageApi("error", "Page Failed to load, Please Try Again");
          }
        } else {
          setMessageApi("error", "Page Failed to load, Please Try Again");
        }
      } catch (err) {
        setMessageApi("error", "Page Failed to load, Please Try Again");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    loadPage();
    fetchMoreData();
  }, []);

  useEffect(() => {
    // Scroll to the bottom when messages are updated
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo(
        0,
        messagesContainerRef.current.scrollHeight
      );
      setHasMore(false);
    }
  }, [messages, loading, hasMore]);

  useEffect(() => {
    // Use socket and check connected status
    if (socketAuth && oldMsgLoaded) {
      socket.emit("community_connect", { communityName: profileInfo.name });
      socket.on(profileInfo.name, (message) => {
        setHasMore(true);
        message["user"] = "Admin";
        setMessages({ type: "NEW_MESSAGE", message });
      });
    } else {
      socket.emit("community_disconnect", { communityName: profileInfo.name });
    }
  }, [socket, socketAuth, oldMsgLoaded]);

  const fetchMoreData = () => {
    setHasMore(false);
  };

  const handleSendMessage = async () => {
    var community_name = profileInfo.name;
    setLoading(true);
    if (accessToken != "" && newMessage.trim() !== "") {
      var result = await createCommunityMsg_api(
        accessToken,
        community_name,
        newMessage
      );
      try {
        if (result != undefined) {
          if (result.error == false) {
            setNewMessage("");
          } else {
            setMessageApi("error", "Page Failed to load, Please Try Again");
          }
        } else {
          setMessageApi("error", "Page Failed to load, Please Try Again");
        }
      } catch (err) {
        setMessageApi("error", "Page Failed to load, Please Try Again");
      }
    }
    setLoading(false);
  };

  const handleEnterPress = (e) => {
    if (!e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const onChange = (e) => {
    setNewMessage(e.target.value);
  };

  // Function to handle pasting an image
  // const handlePaste = (event) => {
  //   const items = (event.clipboardData || event.originalEvent.clipboardData)
  //     .items;
  //   for (let index in items) {
  //     const item = items[index];
  //     if (item.kind === "file") {
  //       const blob = item.getAsFile();
  //       const reader = new FileReader();
  //       reader.onload = function (event) {
  //         const dataUrl = event.target.result;
  //         setNewMessage(dataUrl);
  //         console.log(dataUrl);
  //       };
  //       reader.readAsDataURL(blob);
  //     }
  //   }
  // };

  return (
    <Form>
      {loading ? (
        <FormGroup>
          <Spin tip="Loading...">
            <div
              style={{
                height: 600,
                display: "flex",
                flexDirection: "column",
                padding: "16px",
                border: "2px solid #1890ff", // Color the border
                borderRadius: "8px",
              }}
            ></div>
          </Spin>
        </FormGroup>
      ) : (
        <div
          style={{
            height: 600,
            display: "flex",
            flexDirection: "column",
            padding: "16px",
            border: "2px solid #1890ff", // Color the border
            borderRadius: "8px",
          }}
        >
          <div
            ref={messagesContainerRef}
            style={{ flexGrow: 1, overflowY: "auto" }}
          >
            <InfiniteScroll
              dataLength={messages.length}
              next={fetchMoreData}
              hasMore={hasMore}
              inverse={true}
              loader={
                <h4 style={{ textAlign: "center", marginTop: "16px" }}>
                  Loading...
                </h4>
              }
              style={{ display: "flex", flexDirection: "column-reverse" }}
              endMessage={<p>No more messages</p>}
            >
              <List
                itemLayout="horizontal"
                dataSource={messages}
                renderItem={(message, index) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar>A</Avatar>}
                      title={message.user}
                      description={
                        <div style={{ whiteSpace: "pre-wrap", color: "black" }}>
                          <p>{message.text}</p>
                          <small>{message.time}</small>
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </div>
          {isAdmin ? (
            <div
              style={{
                marginTop: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextArea
                style={{ flex: 1, marginRight: "8px", minHeight: "40px" }}
                placeholder="Type a message"
                value={newMessage}
                onChange={onChange}
                onKeyDown={handleEnterPress}
                // onPaste={handlePaste}
                autoSize={{ minRows: 1, maxRows: 6 }} // Adjust as needed
              />
              <Button
                type="primary"
                shape="circle"
                icon={<SendOutlined />}
                onClick={handleSendMessage}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </Form>
  );
};

export default CommunityAlerts;
