import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addNotifData } from "features/notificationListSlice";

import testAlertDiscord_api from "apis/testAlertDiscord";
import testAlertGroupMe_api from "apis/testAlertGroupMe";
import testCoeusSpace_api from "apis/testCoeusSpace";
import testAlertSlack_api from "apis/testAlertSlack";

const FormFilling = ({
  alertData,
  setAlertData,
  referID,
  setLoading,
  setMessageApi,
  setNotificationApi,
}) => {
  const [currAlertData, setCurrAlertData] = useState(
    alertData["Alert " + referID]
  );

  const accessToken = useSelector((state) => state.accessToken.value);
  const emailList = useSelector((state) => state.emailList.value);
  const dispatch = useDispatch();

  const testalert = async () => {
    if (currAlertData.type == "GroupMe") {
      var bot_id = currAlertData.GroupMe_BotID;
      if (bot_id == "" || bot_id == undefined) {
        setMessageApi("error", "Entry cannot be empty");
      } else {
        var result = await testAlertGroupMe_api(bot_id);
        if (result == 404 || result == "") {
          // should return 302 if it works
          setMessageApi("error", "Error, Please try again later");
        } else {
          setMessageApi("success", "You should received an alert soon");
        }
      }
    } else if (currAlertData.type == "Discord") {
      var webhookUrl = currAlertData.Discord_WebhookUrl;
      if (webhookUrl == "" || webhookUrl == undefined) {
        setMessageApi("error", "Entry cannot be empty");
      } else {
        var result = await testAlertDiscord_api(webhookUrl);
        if (result == 404 || result == "") {
          // should return 302 if it works
          setMessageApi("error", "Error, Please try again later");
        } else {
          setMessageApi("success", "You should received an alert soon");
        }
      }
    } else if (currAlertData.type == "Slack") {
      var webhookUrl = currAlertData.Slack_WebhookUrl;
      if (webhookUrl == "" || webhookUrl == undefined) {
        setMessageApi("error", "Entry cannot be empty");
      } else {
        var result = await testAlertSlack_api(webhookUrl);
        if (result == 404 || result == "") {
          // should return 302 if it works
          setMessageApi("error", "Error, Please try again later");
        } else {
          setMessageApi("success", "You should received an alert soon");
        }
      }
    } else if (currAlertData.type == "Coeus Space") {
      var result = await testCoeusSpace_api(
        accessToken,
        "Coeus Space",
        "Testing from Coeus - Message",
        "Testing from Coeus - Subject"
      );
      if (result != undefined) {
        if (result.error == false) {
          // add to notification
          var data = result.message;
          setMessageApi("success", "You should received an alert soon");

          var temp_notif = {
            key: data.myid,
            originate: "Coeus Space",
            created: data.timestamp,
            msg: "Testing from Coeus - Message",
            subject: "Testing from Coeus - Subject",
          };

          dispatch(addNotifData(temp_notif));
          setNotificationApi("Coeus", "Testing from Coeus Studio");
        } else {
          setMessageApi("error", "Error, Please try again later");
        }
      } else {
        setMessageApi("error", "Error, Please try again later");
      }
    } else if (currAlertData.type == "") {
      // none is selected
      setMessageApi("error", "Please Select an Alert Type");
    }
  };

  useEffect(() => {
    setLoading(true);
    var newEmailData = {};
    var oldEmailData = currAlertData.Email;
    for (let i = 0; i < emailList.length; i++) {
      var myemail = emailList[i].email;
      if (oldEmailData == undefined) {
        newEmailData[myemail] = "false";
      } else {
        if (myemail in oldEmailData) {
          newEmailData[myemail] = oldEmailData[myemail];
        } else {
          newEmailData[myemail] = "false";
        }
      }
    }
    var tempAlertData = alertData;
    tempAlertData["Alert " + referID].Email = newEmailData;
    setCurrAlertData({
      ...currAlertData,
      ...tempAlertData["Alert " + referID],
    });
    setAlertData(tempAlertData);
    setLoading(false);
  }, []);

  const fillForm = async (e) => {
    setLoading(true);
    var tempAlertData = {};
    tempAlertData[e.target.id] = e.target.value;
    var newCurrAlertData = { ...currAlertData, ...tempAlertData };
    setCurrAlertData(newCurrAlertData);

    var overallAlertData = alertData;
    overallAlertData["Alert " + referID] = newCurrAlertData;
    setAlertData(overallAlertData);
    setLoading(false);
  };

  const fillFormEmail = async (e) => {
    setLoading(true);
    if (currAlertData.type == "Email") {
      setLoading(true);
      var myvalue = currAlertData.Email[e.target.id];
      if (myvalue == "true") {
        myvalue = "false";
      } else {
        myvalue = "true";
      }
      var tempEmailData = {};
      tempEmailData[e.target.id] = myvalue;
      var tempAlertData = currAlertData;
      tempAlertData["Email"] = { ...currAlertData.Email, ...tempEmailData };
      setCurrAlertData({ ...currAlertData, ...tempAlertData });

      var overallAlertData = alertData;
      overallAlertData["Alert " + referID] = currAlertData;
      setAlertData(overallAlertData);
      setLoading(false);
    }
  };

  const getForm = () => {
    if (currAlertData.type == "GroupMe") {
      return (
        <>
          <hr
            style={{ background: "#000000", height: "3px", border: "none" }}
          />
          <FormGroup row>
            <Label for="botid" sm={2}>
              Bot ID <sup style={{ color: "red" }}>*</sup>
            </Label>
            <Col sm={8}>
              <Input
                id="GroupMe_BotID"
                value={currAlertData.GroupMe_BotID}
                onChange={fillForm}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Col>
            <Col sm={1}>
              <Button
                color="secondary"
                href="https://dev.groupme.com/bots"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="ni ni-bulb-61 text-yellow" />
              </Button>
            </Col>
          </FormGroup>
          <Button color="info" onClick={testalert}>
            Test
          </Button>{" "}
        </>
      );
    } else if (currAlertData.type == "Discord") {
      return (
        <>
          <hr
            style={{ background: "#000000", height: "3px", border: "none" }}
          />
          <FormGroup row>
            <Label for="webhook" sm={2}>
              Webhook Url <sup style={{ color: "red" }}>*</sup>
            </Label>

            <Col sm={8}>
              <Input
                id="Discord_WebhookUrl"
                value={currAlertData.Discord_WebhookUrl}
                onChange={fillForm}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Col>
            <Col sm={1}>
              <Button
                color="secondary"
                href="https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="ni ni-bulb-61 text-yellow" />
              </Button>
            </Col>
          </FormGroup>
          <Button color="info" onClick={testalert}>
            Test
          </Button>{" "}
        </>
      );
    } else if (currAlertData.type == "Slack") {
      return (
        <>
          <hr
            style={{ background: "#000000", height: "3px", border: "none" }}
          />
          <FormGroup row>
            <Label for="webhook" sm={2}>
              Webhook Url <sup style={{ color: "red" }}>*</sup>
            </Label>

            <Col sm={8}>
              <Input
                id="Slack_WebhookUrl"
                value={currAlertData.Slack_WebhookUrl}
                onChange={fillForm}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Col>
            <Col sm={1}>
              <Button
                color="secondary"
                href="https://api.slack.com/messaging/webhooks"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="ni ni-bulb-61 text-yellow" />
              </Button>
            </Col>
          </FormGroup>
          <Button color="info" onClick={testalert}>
            Test
          </Button>{" "}
        </>
      );
    } else if (currAlertData.type == "Email") {
      return (
        <>
          <hr
            style={{ background: "#000000", height: "3px", border: "none" }}
          />
          <FormGroup row>
            {Object.keys(currAlertData.Email).map((key, index) => (
              <Col key={key} lg={{ size: 10 }}>
                <FormGroup key={key} check>
                  <Label key={key} check>
                    <Input
                      type="checkbox"
                      key={key}
                      id={key}
                      name={key}
                      onChange={fillFormEmail}
                      checked={
                        currAlertData.Email[key] == "true" ? true : false
                      }
                    />{" "}
                    {key}
                  </Label>
                </FormGroup>
              </Col>
            ))}
          </FormGroup>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <ModalBody>
      <Form>
        <FormGroup>
          <Label for="exampleSelect">Select</Label>
          <Input
            type="select"
            name="select"
            id={"type"}
            value={currAlertData.type}
            key={referID}
            onChange={fillForm} // added and then set
          >
            <option></option>
            <option>Coeus Space</option>
            <option>Discord</option>
            <option>Email</option>
            <option>GroupMe</option>
            <option>Slack</option>
          </Input>
        </FormGroup>
        {getForm()}
      </Form>
    </ModalBody>
  );
};

export default FormFilling;
