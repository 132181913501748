import axios from "axios";
const { API_URL } = require("./constants");

const getNotification_api = async (accessToken) => {
  //not used
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: API_URL + "api/v1/get_notification",
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  let result = "";

  await axios
    .request(config)
    .then((response) => {
      result = response.data;
    })
    .catch((error) => {
      return error;
    });

  return result;
};

export default getNotification_api;
