import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import io from "socket.io-client";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Modal,
  ModalBody,
  ModalFooter,
  CardTitle,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Popover, ConfigProvider, notification } from "antd";
import { Avatar, Badge, Space } from "antd";
import getNotificationPage_api from "apis/getNotificationPage";
import getNotification_api from "apis/getNotification";
import { useSelector, useDispatch } from "react-redux";
import {
  setNotifData,
  clearSelectNotifData,
  clearAllNotifData,
  addNotifData,
} from "features/notificationListSlice";
import deleteNotification_api from "apis/deleteNotification";
import { useSocket } from "app/SocketContext";

const DropDownNotif = ({ setMessageApi, setNotifCount, notifCount }) => {
  const [layoutTarget, setLayoutTarget] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [shortenNotif, setShortenNotif] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const accessToken = useSelector((state) => state.accessToken.value);
  const dispatch = useDispatch();
  const notificationList = useSelector((state) => state.notificationList.value);
  const { socket, socketAuth } = useSocket();

  const loadPage = async () => {
    setConfirmLoading(true);
    if (accessToken != "") {
      var result = await getNotificationPage_api(accessToken); // do sensative changes
      if (result != null) {
        if (result.error == false) {
          dispatch(setNotifData(result.message)); // maybe set state for shortened UI?
        } else {
          setMessageApi("error", "Error in getting notifications");
        }
      } else {
        setMessageApi("error", "Error in getting notifications");
      }
    }
    setConfirmLoading(false);
  };

  const toggle = () => {
    setDeleteModal(!deleteModal);
  };

  useEffect(() => {
    loadPage();
  }, [accessToken]);

  useEffect(() => {
    setNotifCount(Object.keys(notificationList).length);
    var temp = {};
    var mykeys = Object.keys(notificationList).reverse(); // more than 5?
    var maxNotif = 5;
    if (mykeys.length < 5) {
      maxNotif = mykeys.length;
    }
    for (let i = 0; i < maxNotif; i++) {
      temp[mykeys[i]] = notificationList[mykeys[i]];
    }
    setShortenNotif(temp);
  }, [notificationList]);

  const onClearAll = async () => {
    // here for clear all
    setConfirmLoading(true);
    if (accessToken != "") {
      var result = await deleteNotification_api(
        accessToken,
        Object.keys(notificationList)
      );
      if (result != null) {
        if (result.error == false) {
          dispatch(clearAllNotifData());
          setMessageApi("success", "Deleted all notifications");
          toggle();
        } else {
          setMessageApi("error", "Error in deleting notifications");
        }
      } else {
        setMessageApi("error", "Error in deleting notifications");
      }
    }
    setConfirmLoading(false);
  };

  useEffect(() => {
    // Use socket and check connected status
    if (socketAuth) {
      socket.on("messages", (message) => {
        dispatch(addNotifData(message));
      });
    }
  }, [socket, socketAuth]);

  return (
    <>
      {" "}
      {deleteModal ? (
        <Modal isOpen={deleteModal} size="sm" toggle={toggle}>
          <ModalBody>
            Are you sure you want to delete {notifCount} Notifications?
          </ModalBody>
          <ModalFooter>
            <Button type="primary" onClick={onClearAll}>
              Yes
            </Button>{" "}
            <Button onClick={toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      ) : (
        <></>
      )}
      <DropdownMenu
        className="dropdown-menu-arrow"
        right
        style={{ position: "relative", zIndex: 1 }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "green",
            width: "350px",
          }}
        >
          You have {notifCount} Notifications
        </div>
        <DropdownItem divider />
        {Object.keys(shortenNotif) // more than 5?
          .reverse()
          .map((key, index) => (
            <span key={key}>
              <DropdownItem key={key} text>
                <div
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "350px",
                    alignItems: "center",
                  }}
                  key={key}
                >
                  {shortenNotif[key].originate}: {shortenNotif[key].subject}
                </div>
                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                  {shortenNotif[key].created}
                </CardTitle>
              </DropdownItem>
              <DropdownItem divider />
            </span>
          ))}
        {}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button type="link">
            <Link to="/admin/Inbox"> View All </Link>
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="link" onClick={toggle}>
            Clear All
          </Button>
        </div>
      </DropdownMenu>
    </>
  );
};

export default DropDownNotif;
