import axios from "axios";
const { API_URL } = require("./constants");

const editLayout_api = async (
  accessToken,
  old_layout_name,
  new_layout_name,
  node_data,
  edge_data,
  modelUsed
) => {
  for (let i = 0; i < node_data.length; i++) {
    node_data[i] = JSON.stringify(node_data[i]);
  }

  for (let i = 0; i < edge_data.length; i++) {
    edge_data[i] = JSON.stringify(edge_data[i]);
  }

  let data = JSON.stringify({
    old_layout_name: old_layout_name,
    new_layout_name: new_layout_name,
    model_used: modelUsed,
    node_data: node_data,
    edge_data: edge_data,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: API_URL + "api/v1/edit_layout",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    data: data,
  };

  let result = "";

  await axios
    .request(config)
    .then((response) => {
      result = response.data;
    })
    .catch((error) => {
      return error;
    });

  return result;
};

export default editLayout_api;
