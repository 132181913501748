function GroupNameCheck(name) {
  if (name == "") {
    return "Cannot be empty string";
  } else if (name.length > 25) {
    return "Max length exceeded >25 characters";
  } else if (name.startsWith(" ") || name.endsWith(" ")) {
    return "Cannot start or end with space";
  } else if (/^[a-zA-Z0-9 ]*$/.test(name)) {
    return "";
  }
  return "Cannot contain special characters";

  //contains only # and Letters with len(name) <= 25
  //cannot start or end with "_"
  //return valid(boolean), error Msg(str)
}

export default GroupNameCheck;
