import { createSlice } from "@reduxjs/toolkit";

export const communityListSlice = createSlice({
  name: "communityListSlice",
  initialState: {
    value: {},
  },
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes.
    // Also, no return statement is required from these functions.
    setCommunityData: (state, action) => {
      state.value = action.payload;
    },
    addCommunityData: (state, action) => {
      var referID = action.payload[0];
      var data = action.payload[1];
      state.value[referID] = data;
    },
    editCommunityData: (state, action) => {
      var referID = action.payload[0];
      var status = action.payload[1];
      state.value[referID].status = status;
    },
    decreSubCommunityData: (state, action) => {
      var referID = action.payload;
      state.value[referID].subscriber -= 1;
    },
    deleteCommunityData: (state, action) => {
      var referIDs = action.payload;
      for (let i = 0; i < referIDs.length; i++) {
        delete state.value[referIDs[i]];
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCommunityData,
  addCommunityData,
  editCommunityData,
  deleteCommunityData,
  increSubCommunityData,
  decreSubCommunityData,
} = communityListSlice.actions;

export default communityListSlice.reducer;
