import axios from "axios";
const { API_URL } = require("./constants");

const createGroup_api = async (accessToken, form) => {
  let data = JSON.stringify(form);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: API_URL + "api/v1/create_community",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    data: data,
  };

  let result = "";

  await axios
    .request(config)
    .then((response) => {
      result = response.data;
    })
    .catch((error) => {
      return error;
    });

  return result;
};

export default createGroup_api;
