import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { requireField } from "./Filtering";
import { addNotifData } from "features/notificationListSlice";

import testAlertDiscord_api from "apis/testAlertDiscord";
import testAlertGroupMe_api from "apis/testAlertGroupMe";
import testCoeusSpace_api from "apis/testCoeusSpace";
import testAlertSlack_api from "apis/testAlertSlack";
import getMyAdminCommunityPage_api from "apis/getMyAdminCommunityPage";
import createCommunityMsg_api from "apis/createCommunityMsg";

const FormFilling = ({
  alertData,
  setAlertData,
  referID,
  setLoading,
  setMessageApi,
  setNotificationApi,
}) => {
  const [commData, setCommData] = useState([]);

  const [currAlertData, setCurrAlertData] = useState({
    type: "",
    GroupMe_BotID: "",
    Discord_WebhookUrl: "",
    Slack_WebhookUrl: "",
    Community_List: [],
    Email: [],
  });

  const accessToken = useSelector((state) => state.accessToken.value);
  const emailList = useSelector((state) => state.emailList.value);
  const dispatch = useDispatch();

  const loadPage = async () => {
    setLoading(true);
    // set community list
    if (accessToken != "") {
      var result = await getMyAdminCommunityPage_api(accessToken);
      try {
        if (result != undefined) {
          if (result.error == false) {
            setCommData(result.message);
          } else {
            setMessageApi("error", "Page Failed to load, Please Try Again");
          }
        } else {
          setMessageApi("error", "Page Failed to load, Please Try Again");
        }
      } catch (err) {
        setMessageApi("error", "Page Failed to load, Please Try Again");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    loadPage();
  }, []);

  const testalert = async () => {
    if (currAlertData.type == "GroupMe") {
      var bot_id = currAlertData.GroupMe_BotID;
      if (bot_id == "" || bot_id == undefined) {
        setMessageApi("error", "Entry cannot be empty");
      } else {
        var result = await testAlertGroupMe_api(bot_id);
        if (result == 404 || result == "") {
          // should return 302 if it works
          setMessageApi("error", "Error, Please try again later");
        } else {
          setMessageApi("success", "You should received an alert soon");
        }
      }
    } else if (currAlertData.type == "Discord") {
      var webhookUrl = currAlertData.Discord_WebhookUrl;
      if (webhookUrl == "" || webhookUrl == undefined) {
        setMessageApi("error", "Entry cannot be empty");
      } else {
        var result = await testAlertDiscord_api(webhookUrl);
        if (result == 404 || result == "") {
          // should return 302 if it works
          setMessageApi("error", "Error, Please try again later");
        } else {
          setMessageApi("success", "You should received an alert soon");
        }
      }
    } else if (currAlertData.type == "Slack") {
      var webhookUrl = currAlertData.Slack_WebhookUrl;
      if (webhookUrl == "" || webhookUrl == undefined) {
        setMessageApi("error", "Entry cannot be empty");
      } else {
        var result = await testAlertSlack_api(webhookUrl);
        if (result == 404 || result == "") {
          // should return 302 if it works
          setMessageApi("error", "Error, Please try again later");
        } else {
          setMessageApi("success", "You should received an alert soon");
        }
      }
    } else if (currAlertData.type == "Coeus Space") {
      var result = await testCoeusSpace_api(
        accessToken,
        "Coeus Space",
        "Testing from Coeus - Message",
        "Testing from Coeus - Subject"
      );
      if (result != undefined) {
        if (result.error == false) {
          // add to notification
          var data = result.message;
          setMessageApi("success", "You should received an alert soon");

          var temp_notif = {
            key: data.myid,
            originate: "Coeus Space",
            created: data.timestamp,
            msg: "Testing from Coeus - Message",
            subject: "Testing from Coeus - Subject",
          };

          dispatch(addNotifData(temp_notif));
          setNotificationApi("Coeus", "Testing from Coeus Studio");
        } else {
          setMessageApi("error", "Error, Please try again later");
        }
      } else {
        setMessageApi("error", "Error, Please try again later");
      }
    } else if (currAlertData.type == "My Community") {
      for (let i = 0; i < currAlertData.Community_List.length; i++) {
        if (accessToken != "") {
          var result = await createCommunityMsg_api(
            accessToken,
            currAlertData.Community_List[i],
            "Test from Simple Alert"
          );
          try {
            if (result != undefined) {
              if (result.error != false) {
                setMessageApi("error", "Page Failed to load, Please Try Again");
              }
            } else {
              setMessageApi("error", "Page Failed to load, Please Try Again");
            }
          } catch (err) {
            setMessageApi("error", "Page Failed to load, Please Try Again");
          }
        }
      }
    } else if (currAlertData.type == "") {
      // none is selected
      setMessageApi("error", "Please Select an Alert Type");
    }
  };

  const onSelectChange = async (e) => {
    if (e.key == "Enter") {
      e.preventDefault();
    } else {
      var mytype = e.target.value;
      if (!mytype.includes("Coming Soon")) {
        setLoading(true);
        var tempAlertData = {};
        tempAlertData["type"] = mytype;
        var newCurrAlertData = { ...currAlertData, ...tempAlertData };
        setCurrAlertData(newCurrAlertData);

        var overallAlertData = alertData;
        overallAlertData["Alert " + referID] = newCurrAlertData;
        setAlertData(overallAlertData);
        setLoading(false);
      }
    }
  };

  const fillForm = async (e) => {
    setLoading(true);
    var tempAlertData = {};
    tempAlertData[e.target.id] = e.target.value;
    var newCurrAlertData = { ...currAlertData, ...tempAlertData };
    setCurrAlertData(newCurrAlertData);

    var overallAlertData = alertData;
    overallAlertData["Alert " + referID] = newCurrAlertData;
    setAlertData(overallAlertData);
    setLoading(false);
  };

  const onChange = (e) => {
    setLoading(true);
    // set alert Value
    var myname = e.target.getAttribute("name");
    var tempAlertData = currAlertData;
    const index = tempAlertData[e.target.id].indexOf(myname);
    if (index > -1) {
      tempAlertData[e.target.id].splice(index, 1);
    } else {
      tempAlertData[e.target.id].push(myname);
    }
    var newCurrAlertData = { ...currAlertData, ...tempAlertData };
    setCurrAlertData(newCurrAlertData);

    var overallAlertData = alertData;
    overallAlertData["Alert " + referID] = newCurrAlertData;
    setAlertData(overallAlertData);

    setLoading(false);
  };

  const getForm = () => {
    if (currAlertData.type == "GroupMe") {
      return (
        <>
          <hr
            style={{ background: "#000000", height: "3px", border: "none" }}
          />
          <FormGroup row>
            <Label for="botid" sm={2}>
              Bot ID <sup style={{ color: "red" }}>*</sup>
            </Label>
            <Col sm={8}>
              <Input
                id="GroupMe_BotID"
                value={currAlertData.GroupMe_BotID}
                onChange={fillForm}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Col>
            <Col sm={1}>
              <Button
                color="secondary"
                href="https://dev.groupme.com/bots"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="ni ni-bulb-61 text-yellow" />
              </Button>
            </Col>
          </FormGroup>
          <Button color="info" onClick={testalert}>
            Test
          </Button>{" "}
        </>
      );
    } else if (currAlertData.type == "Discord") {
      return (
        <>
          <hr
            style={{ background: "#000000", height: "3px", border: "none" }}
          />
          <FormGroup row>
            <Label for="webhook" sm={2}>
              Webhook Url <sup style={{ color: "red" }}>*</sup>
            </Label>

            <Col sm={8}>
              <Input
                id="Discord_WebhookUrl"
                value={currAlertData.Discord_WebhookUrl}
                onChange={fillForm}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Col>
            <Col sm={1}>
              <Button
                color="secondary"
                href="https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="ni ni-bulb-61 text-yellow" />
              </Button>
            </Col>
          </FormGroup>
          <Button color="info" onClick={testalert}>
            Test
          </Button>{" "}
        </>
      );
    } else if (currAlertData.type == "Slack") {
      return (
        <>
          <hr
            style={{ background: "#000000", height: "3px", border: "none" }}
          />
          <FormGroup row>
            <Label for="webhook" sm={2}>
              Webhook Url <sup style={{ color: "red" }}>*</sup>
            </Label>

            <Col sm={8}>
              <Input
                id="Slack_WebhookUrl"
                value={currAlertData.Slack_WebhookUrl}
                onChange={fillForm}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Col>
            <Col sm={1}>
              <Button
                color="secondary"
                href="https://api.slack.com/messaging/webhooks"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="ni ni-bulb-61 text-yellow" />
              </Button>
            </Col>
          </FormGroup>
          <Button color="info" onClick={testalert}>
            Test
          </Button>{" "}
        </>
      );
    } else if (currAlertData.type == "My Community") {
      return (
        <>
          <hr
            style={{ background: "#000000", height: "3px", border: "none" }}
          />
          <FormGroup row>
            <Label for="webhook" sm={12}>
              Select your Community <sup style={{ color: "red" }}>*</sup>
            </Label>
            {commData != [] ? (
              commData.map((community) => (
                <Col key={community[0]} lg={{ size: 10 }}>
                  <FormGroup key={community[0]} check>
                    <Label key={community[0]} check>
                      <Input
                        type="checkbox"
                        key={community[0]}
                        id="Community_List"
                        name={community[1]}
                        onChange={onChange}
                      />{" "}
                      {community[1]}
                    </Label>
                  </FormGroup>
                </Col>
              ))
            ) : (
              <></>
            )}
          </FormGroup>
          <Button color="info" onClick={testalert}>
            Test
          </Button>{" "}
        </>
      );
    } else if (currAlertData.type == "Email") {
      return (
        <>
          <hr
            style={{ background: "#000000", height: "3px", border: "none" }}
          />
          <FormGroup row>
            <Label for="webhook" sm={12}>
              Select your Email <sup style={{ color: "red" }}>*</sup>
            </Label>
            {emailList != [] ? (
              emailList.map((myemail) => (
                <Col key={myemail["email"]} lg={{ size: 10 }}>
                  <FormGroup key={myemail["email"]} check>
                    <Label key={myemail["email"]} check>
                      <Input
                        type="checkbox"
                        key={myemail["email"]}
                        id="Email"
                        name={myemail["email"]}
                        onChange={onChange}
                      />{" "}
                      {myemail["email"]}
                    </Label>
                  </FormGroup>
                </Col>
              ))
            ) : (
              <></>
            )}
          </FormGroup>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <ModalBody>
      <Form>
        <FormGroup>
          <Label for="exampleSelect">Select</Label>
          <Input
            type="select"
            name="select"
            id={referID}
            value={currAlertData.type}
            key={referID}
            onChange={onSelectChange} // added and then set
          >
            <option></option>
            <option>Coeus Space</option>
            <option>Discord</option>
            <option>GroupMe</option>
            <option>My Community</option>
            <option>Slack</option>
            <option>Email</option>
            <option>SMS (Coming Soon)</option>
          </Input>
        </FormGroup>
        {getForm()}
      </Form>
    </ModalBody>
  );
};

export default FormFilling;
