import { useState, useEffect } from "react";
// react component that copies the given text inside your clipboard
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
// import Header from "components/Headers/Header.js";
import { useSelector, useDispatch } from "react-redux";
import getLayoutPage_api from "apis/getLayoutPage";
import getModelPage_api from "apis/getModelPage";
import EditLayout from "./EditLayout";
import NewLayout from "./NewLayout";
import { setLayoutData } from "features/layoutsListSlice";
import { setModelData } from "features/modelsListSlice";
import { message } from "antd";

const Layout = () => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [newLayout, setNewLayout] = useState(false);
  const [editLayout, setEditLayout] = useState(false);
  const [oldLayoutName, setOldLayoutName] = useState("");

  const accessToken = useSelector((state) => state.accessToken.value);
  const layoutList = useSelector((state) => state.layoutsList.value);
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const key = "updatable";

  const onCreateLayout = async (e) => {
    setNewLayout(true);
  };

  const onEditLayout = async (e) => {
    setOldLayoutName(e.target.id);
    setEditLayout(true);
  };

  const setMessageApi = async (mType, validName) => {
    messageApi.open({
      key,
      type: mType,
      content: validName,
      duration: 3,
    });
  };

  // Use Effect to load page

  const loadPage = async () => {
    if (accessToken != "") {
      try {
        setConfirmLoading(true);

        var result = await getLayoutPage_api(accessToken);
        if (result != undefined) {
          if (result.error == false) {
            var layoutresult = result.message;
            var temp = [];
            Object.entries(layoutresult).forEach(([v, k]) => {
              temp.push({ id: v, name: k });
            });
            dispatch(setLayoutData(temp));
          } else {
            setMessageApi("error", "Cannot get layout info");
          }
        }

        var result = await getModelPage_api(accessToken);
        if (result != undefined) {
          if (result.error == false) {
            var modelresult = result.message; // array of names
            var temp = [];
            Object.entries(modelresult).forEach(([v, k]) => {
              temp.push({ id: v, name: k });
            });
            dispatch(setModelData(temp));
          } else {
            setMessageApi("error", "Cannot get model info");
          }
        }

        setConfirmLoading(false);
      } catch (e) {
        console.log(e.message);
      }
    }
  };

  useEffect(() => {
    loadPage();
  }, []);

  return (
    <>
      {contextHolder}
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}

        {newLayout ? (
          <NewLayout
            setNewLayout={setNewLayout}
            newLayout={newLayout}
            setMessageApi={setMessageApi}
          />
        ) : (
          <></>
        )}

        {editLayout ? (
          <EditLayout
            setEditLayout={setEditLayout}
            editLayout={editLayout}
            oldLayoutName={oldLayoutName}
            setMessageApi={setMessageApi}
          />
        ) : (
          <></>
        )}

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Layouts</h3>
              </CardHeader>
              <CardBody>
                {confirmLoading ? (
                  "loading"
                ) : (
                  <Row className="model-examples">
                    <Col lg="3" md="6">
                      <button
                        className="btn-icon-clipboard"
                        id="Create-Layout"
                        type="button"
                        onClick={onCreateLayout}
                      >
                        <div>
                          <i className="ni ni-fat-add text-green" />
                          <span>Create Layout</span>
                        </div>
                      </button>
                      <UncontrolledTooltip
                        delay={0}
                        trigger="hover focus"
                        target="Create-Layout"
                      >
                        {"Click to Add"}
                      </UncontrolledTooltip>
                    </Col>
                    {layoutList.map((layout) => (
                      <Col lg="3" md="6" key={layout.name}>
                        <button
                          className="btn-icon-clipboard"
                          id={layout.name}
                          type="button"
                          onClick={onEditLayout}
                        >
                          <div id={layout.name}>
                            <i
                              id={layout.name}
                              className="ni ni-support-16 text-info"
                            />
                            <span id={layout.name}>{layout.name}</span>
                          </div>
                        </button>
                      </Col>
                    ))}
                  </Row>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Layout;
