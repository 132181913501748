import { useState, useEffect, useRef } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import Header from "./Header.js";
import TradingViewWidget from "../Widgets/ChartRT";
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import HeatMap from "../Widgets/HeatMap.js";
import { useSelector, useDispatch } from "react-redux";
import { message, Tabs, notification } from "antd";
import NewsTable from "../Widgets/NewsTable.js";

const Info = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  const setMessageApi = async (mType, validName) => {
    messageApi.open({
      key,
      type: mType,
      content: validName,
      duration: 3,
    });
  };

  return (
    <>
      {contextHolder}
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <div style={{ height: "650px", width: "100%" }}>
          <TradingViewWidget />
        </div>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="7">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="text-white mb-0">Heat Map</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <HeatMap />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="5">
            <Card className="shadow">
              <NewsTable setMessageApi={setMessageApi} />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Info;
