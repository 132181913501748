const requireField = {
  Discord: ["Discord_WebhookUrl"],
  GroupMe: ["GroupMe_BotID"],
  Slack: ["Slack_WebhookUrl"],
  "My Community": ["Community_List"],
  "Coeus Space": [],
  Email: ["Email"],
};

const checkInput = (data) => {
  var keys = Object.keys(data);
  var atLeastOneType = false;
  for (let i = 0; i < keys.length; i++) {
    var currentkey = keys[i];
    var currentdata = data[currentkey];
    if (currentdata.type != "") {
      atLeastOneType = true;
      var fields = requireField[currentdata.type];
      for (let i = 0; i < fields.length; i++) {
        if (
          currentdata[fields[i]] == "" ||
          currentdata[fields[i]] == undefined ||
          currentdata[fields[i]] == []
        ) {
          return "Please fill out all required fields";
        }
      }
    }
  }
  if (!atLeastOneType) {
    return "You must set at least one alert type";
  }
  return "";
};

export { requireField };
export { checkInput };
