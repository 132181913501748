// reactstrap components
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { message, Tabs, notification } from "antd";
import EmailModal from "./emailModal";

const UserHeader = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [myEmailModal, setMyEmailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, logout } = useAuth0();

  const key = "updatable";

  const setMessageApi = async (mType, validName) => {
    messageApi.open({
      key,
      type: mType,
      content: validName,
      duration: 3,
    });
  };

  const addEmail = async (e) => {
    setLoading(true);
    setMyEmailModal(true);
    setLoading(false);
  };

  return (
    <>
      {contextHolder}
      {myEmailModal ? (
        <EmailModal
          setMyEmailModal={setMyEmailModal}
          myEmailModal={myEmailModal}
          setMessageApi={setMessageApi}
        />
      ) : (
        <></>
      )}

      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "600px",
          backgroundImage:
            "url(" + require("../../assets/img/brand/coeus-logo.png") + ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="5">
              <h1 className="display-2 text-white">Hello {user.name}</h1>
              <p className="text-white mt-0 mb-5">
                This is your profile page. You can see all your profile
                information here.
                <br />
                <br /> Coeus Space offers:
                <br /> -> Alert Notification and Integration to other apps
                <br /> -> Customizing your own Alerts
                <br /> -> US Equities (SP500) data
                <br /> -> Creating and Joining Alert Communities
                <br />
                <br />
                If there is any features you would like, please contact us.
              </p>
              <Button>
                <NavLink
                  as="a"
                  href="https://www.coeus.studio/contact-us"
                  target="_blank"
                >
                  <i className="ni ni-email-83" />
                  &ensp;Contact Us
                </NavLink>
              </Button>
            </Col>
            <Col lg="1" md="2"></Col>
            <Col lg="4" md="5">
              <p className="text-white mt-0 mb-5">
                <Button onClick={addEmail}>+ Add Email Address</Button>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
