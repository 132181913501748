import axios from "axios";

const testAlertSlack_api = async (webhookUrl) => {
  let data = JSON.stringify({
    text: "Testing from Coeus Studio",
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: webhookUrl,
    data: data,
  };

  let result = "";

  await axios
    .request(config)
    .then((response) => {
      result = response.status;
    })
    .catch((error) => {
      return error.response.status;
    });

  return result;
};

export default testAlertSlack_api;
