// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  NavLink,
} from "reactstrap";
// core components
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import { Table, message } from "antd";
import {
  setNotifData,
  clearSelectNotifData,
  clearAllNotifData,
} from "features/notificationListSlice";

import deleteNotification_api from "apis/deleteNotification";
const columns = [
  Table.SELECTION_COLUMN,
  {
    title: "From",
    dataIndex: "originate",
    key: "originate",
    width: "25%",
  },
  {
    title: "Subject",
    dataIndex: "subject",
    key: "subject",
    width: "45%",
  },
  Table.EXPAND_COLUMN,
  {
    title: "Date",
    dataIndex: "created",
    key: "created",
    width: "30%",
  },
];

const Notification = () => {
  const { user, logout } = useAuth0();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ellipsis, setEllipsis] = useState(false);
  const [data, setData] = useState([]);
  const [messageApi, msgContextHolder] = message.useMessage();
  const key = "updatable";
  const dispatch = useDispatch();

  const accessToken = useSelector((state) => state.accessToken.value);
  const notificationList = useSelector((state) => state.notificationList.value);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const setMessageApi = async (mType, validName) => {
    messageApi.open({
      key,
      type: mType,
      content: validName,
      duration: 3,
    });
  };

  useEffect(() => {
    setData(Object.values(notificationList).reverse());
  }, [notificationList]);

  const deleteNotifs = async () => {
    setConfirmLoading(true);
    if (selectedRowKeys.length == 0) {
      setMessageApi("error", "No Notifications Selected");
    } else if (accessToken != "") {
      var result = await deleteNotification_api(accessToken, selectedRowKeys);
      if (result != null) {
        if (result.error == false) {
          dispatch(clearSelectNotifData([selectedRowKeys]));
          setMessageApi("success", "Deleted Notifications");
        } else {
          setMessageApi("error", "Error in Deleting Notifications");
        }
      } else {
        setMessageApi("error", "Error in Deleting Notifications");
      }
    }
    setConfirmLoading(false);
  };

  const deleteAll = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "right",
        }}
      >
        &nbsp;&nbsp;&nbsp;
        <Button color="danger" onClick={deleteNotifs}>
          Clear
        </Button>
      </div>
    );
  };

  // get data and format into dataSource = array[] Object.values()

  return (
    <>
      {msgContextHolder}
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container
          fluid
          style={{
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Table
            loading={loading}
            // pagination={{
            //   position: ["none"],
            // }}
            ellipsis={ellipsis}
            columns={columns}
            rowSelection={rowSelection}
            expandable={{
              expandedRowRender: (record) => (
                <p
                  style={{
                    margin: 0,
                  }}
                >
                  {record.msg}
                </p>
              ),
            }}
            dataSource={data}
            footer={() => deleteAll()}
          />
        </Container>
      </div>
    </>
  );
};

export default Notification;
