import { useState, useEffect } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardTitle,
  Button,
} from "reactstrap";
// core components
import Header from "./Header.js";
import { message, Tabs, notification } from "antd";
import SearchCommunity from "./SearchCommunity.js";
import MyCommunity from "./MyCommunity.js";

const Marketplace = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [notifApi, notifContextHolder] = notification.useNotification();
  const key = "updatable";

  const setMessageApi = async (mType, validName) => {
    messageApi.open({
      key,
      type: mType,
      content: validName,
      duration: 3,
    });
  };

  const setNotificationApi = (originate, msg) => {
    notifApi.open({
      message: originate,
      description: msg,
      duration: 3,
      type: "info",
    });
  };

  const MyTabs = [
    {
      id: 0,
      name: "Search Community",
      content: (
        <SearchCommunity
          setMessageApi={setMessageApi}
          setNotificationApi={setNotificationApi}
        />
      ),
    },
    {
      id: 1,
      name: "My Community",
      content: (
        <MyCommunity
          setMessageApi={setMessageApi}
          setNotificationApi={setNotificationApi}
        />
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      {notifContextHolder}
      <Header />
      {/* Page content */}
      <Container className="mt--8" fluid>
        {/* Table */}

        <Tabs
          defaultActiveKey="1"
          type="card"
          size="large"
          style={{
            marginBottom: 32,
          }}
          items={MyTabs.map((subItems, index) => {
            return {
              label: subItems.name,
              key: index,
              children: subItems.content,
            };
          })}
        />
      </Container>
    </>
  );
};

export default Marketplace;
