import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

const steps = [
  "Create Model",
  "Create Layout",
  "Set Input/Alert",
  "Test and Deploy",
];

export default function Header({ activeStep, setActiveStep }) {
  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  // <div className="header bg-gradient-info pb-5 pt-5 pt-md-8"></div>;
  return (
    <>
      <div
        className="header bg-gradient-info pb-5 pt-5 pt-md-8"
        style={{ marginBottom: "10%" }}
      >
        <Row>
          <Col lg="12" xl="1"></Col>
          <Col lg="12" xl="10">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Stepper nonLinear activeStep={activeStep}>
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepButton color="inherit" onClick={handleStep(index)}>
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </CardBody>
            </Card>
          </Col>
          <Col lg="12" xl="1"></Col>
        </Row>
      </div>
    </>
  );
}
