import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import { useState, useRef } from "react";
import createModel_api from "apis/createModel";
import ModelNameCheck from "./ModelNameCheck";
import Editor from "@monaco-editor/react";
import { addModelData } from "features/modelsListSlice";
import { useSelector, useDispatch } from "react-redux";

const NewModel = ({ setNewModel, newModel, setMessageApi }) => {
  const [page, setPage] = useState(1);
  const [buttonText, setButtonText] = useState("Next");
  const [modelName, setModelName] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalSize, setModalSize] = useState("md");

  const accessToken = useSelector((state) => state.accessToken.value);
  const modelList = useSelector((state) => state.modelsList.value);
  const dispatch = useDispatch();

  // if api successfully change parent modal list
  function getplaceholder() {
    var functionName = modelName.replaceAll(" ", "_");
    var moncod = `#We only support python at this time \n\nclass _${functionName}:\n    def __init__(self) -> None:\n        self.x = 0\n\n    def main(self, *argv): #this will be called\n        return [self.x]\n`;
    return moncod;
  }

  const toggle = () => {
    setNewModel(false);
  };

  const nextPage = async () => {
    setLoading(true);
    if (page === 2) {
      // submit
      var modelValue = editorRef.current.getValue();
      var result = await createModel_api(accessToken, modelName, modelValue);
      try {
        if (result != undefined) {
          if (result.error == false) {
            dispatch(addModelData(modelName));
            setNewModel(false);
            setLoading(false);
            setMessageApi("success", "Model Saved");
          } else {
            if (result.message === "Duplicate Model Name") {
              setMessageApi(
                "error",
                "Model Name already exist, Please Start Over"
              );
              setLoading(false);
            } else {
              setMessageApi("error", "Please Try Again");
              setLoading(false);
            }
          }
        }
      } catch (err) {
        setMessageApi("error", "Please Try Again");
        setLoading(false);
      }
    } else {
      // go to the next page
      var validName = ModelNameCheck(modelName);
      if (validName == "") {
        setPage(2);
        setButtonText("Save");
        setModalSize("lg");
        setLoading(false);
      } else {
        setMessageApi("error", validName);
        setLoading(false);
      }
    }
  };

  const onChange = (e) => {
    if (e.target.placeholder === "Model Name") {
      setModelName(e.target.value);
    }
  };

  const editorRef = useRef(null);

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
  }

  const handleKeyPress = (target) => {
    if (target.charCode == 13) {
      target.preventDefault();
      nextPage();
    }
  };

  return (
    <Modal isOpen={newModel} toggle={toggle} size={modalSize}>
      <ModalHeader toggle={toggle}>Create New Model</ModalHeader>
      <ModalBody>
        {page === 1 ? (
          <Form>
            <FormGroup>
              <Label for="ModelName">Model Name</Label>
              <Input
                id="ModelName"
                placeholder="Model Name"
                type="ModelName"
                onChange={onChange}
                onKeyPress={handleKeyPress}
              />
            </FormGroup>
          </Form>
        ) : (
          <Form>
            <FormGroup>
              <Label>Model Code</Label>
              <Editor
                height="60vh"
                defaultLanguage="python"
                theme="vs-dark"
                defaultValue={getplaceholder()}
                onMount={handleEditorDidMount}
              />
            </FormGroup>
          </Form>
        )}
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <div>Saving...</div>
        ) : (
          <>
            <Button color="primary" onClick={nextPage} disabled={loading}>
              {buttonText}
            </Button>{" "}
            <Button color="secondary" onClick={toggle} disabled={loading}>
              Cancel
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default NewModel;
