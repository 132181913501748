import axios from "axios";
const { API_URL } = require("./constants");

const testCoeusSpace_api = async (accessToken, originate, msg, subject) => {
  let data = JSON.stringify({
    originate: originate,
    subject: subject,
    msg: msg,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: API_URL + "api/v1/create_notification",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    data: data,
  };

  let result = "";

  await axios
    .request(config)
    .then((response) => {
      result = response.data;
    })
    .catch((error) => {
      return error;
    });

  return result;
};

export default testCoeusSpace_api;

// if (accessToken != "") {
//   try {
//     setConfirmLoading(true);
//     var result = await getFilteredLayoutPage_api(accessToken);
//     if (result != undefined) {
//       var layoutresult = result.message;
//       var temp = [];
//       var mykeys = Object.keys(layoutresult);
//       Object.entries(mykeys).forEach(([v, k]) => {
//         temp.push({ id: v, name: k });
//       });
//       dispatch(setLayoutData(temp));
//     }

//     setConfirmLoading(false);
//   } catch (e) {
//     setConfirmLoading(false);
//   }
// }
