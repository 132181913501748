import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import testAlertGroupMe_api from "apis/testAlertGroupMe";
import testAlertDiscord_api from "apis/testAlertDiscord";
import testAlertSlack_api from "apis/testAlertSlack";
import { requireField } from "./Filtering";
import testCoeusSpace_api from "apis/testCoeusSpace";
import { addNotifData } from "features/notificationListSlice";

const AlertsModal = ({
  setAlertsModal,
  alertsModal,
  parentCallback,
  currentEdit,
  parentAlertData,
  setMessageApi,
  setNotificationApi,
}) => {
  const [loading, setLoading] = useState(false);
  const [modalSize, setModalSize] = useState("lg");
  const [alertData, setAlertData] = useState({
    type: "",
    GroupMe_BotID: "",
    Discord_WebhookUrl: "",
    Slack_WebhookUrl: "",
  });

  const accessToken = useSelector((state) => state.accessToken.value);
  const layoutList = useSelector((state) => state.layoutsList.value);
  const dispatch = useDispatch();

  const toggle = () => {
    setAlertsModal(false);
  };

  useEffect(() => {
    if (parentAlertData != undefined) {
      setAlertData(parentAlertData);
    }
  }, []);

  const save = async () => {
    // make sure all required fields are fill out
    if (alertData.type != "") {
      var fields = requireField[alertData.type];
      for (let i = 0; i < fields.length; i++) {
        if (alertData[fields[i]] == "" || alertData[fields[i]] == undefined) {
          setMessageApi("error", "Entry cannot be empty");
          return;
        }
      }
    }
    setAlertsModal(false);
    parentCallback(alertData);
  };

  const testalert = async () => {
    if (alertData.type == "GroupMe") {
      var bot_id = alertData.GroupMe_BotID;
      if (bot_id == "" || bot_id == undefined) {
        setMessageApi("error", "Entry cannot be empty");
      } else {
        var result = await testAlertGroupMe_api(bot_id);
        if (result == 404 || result == "") {
          // should return 302 if it works
          setMessageApi("error", "Error, Please try again later");
        } else {
          setMessageApi("success", "You should received an alert soon");
        }
      }
    } else if (alertData.type == "Discord") {
      var webhookUrl = alertData.Discord_WebhookUrl;
      if (webhookUrl == "" || webhookUrl == undefined) {
        setMessageApi("error", "Entry cannot be empty");
      } else {
        var result = await testAlertDiscord_api(webhookUrl);
        if (result == 404 || result == "") {
          // should return 302 if it works
          setMessageApi("error", "Error, Please try again later");
        } else {
          setMessageApi("success", "You should received an alert soon");
        }
      }
    } else if (alertData.type == "Slack") {
      var webhookUrl = alertData.Slack_WebhookUrl;
      if (webhookUrl == "" || webhookUrl == undefined) {
        setMessageApi("error", "Entry cannot be empty");
      } else {
        var result = await testAlertSlack_api(webhookUrl);
        if (result == 404 || result == "") {
          // should return 302 if it works
          setMessageApi("error", "Error, Please try again later");
        } else {
          setMessageApi("success", "You should received an alert soon");
        }
      }
    } else if (alertData.type == "Coeus Space") {
      var result = await testCoeusSpace_api(
        accessToken,
        "Coeus Space",
        "Testing from Coeus - Message",
        "Testing from Coeus - Subject"
      );
      if (result != undefined) {
        if (result.error == false) {
          // add to notification
          var data = result.message;
          setMessageApi("success", "You should received an alert soon");

          var temp_notif = {
            key: data.myid,
            originate: "Coeus Space",
            created: data.timestamp,
            msg: "Testing from Coeus - Message",
            subject: "Testing from Coeus - Subject",
          };

          dispatch(addNotifData(temp_notif));
          setNotificationApi("Coeus", "Testing from Coeus Studio");
        } else {
          setMessageApi("error", "Error, Please try again later");
        }
      } else {
        setMessageApi("error", "Error, Please try again later");
      }
    } else if (alertData.type == "") {
      // none is selected
      setMessageApi("error", "Please Select an Alert Type");
    }
  };

  const onChange = async (e) => {
    setAlertData({ ...alertData, type: e.target.value });
  };

  const fillForm = async (e) => {
    var temp = {};
    temp[e.target.id] = e.target.value;
    setAlertData({ ...alertData, ...temp });
  };

  const getForm = () => {
    if (alertData.type == "GroupMe") {
      return (
        <>
          <hr
            style={{ background: "#000000", height: "3px", border: "none" }}
          />
          <FormGroup row>
            <Label for="botid" sm={2}>
              Bot ID <sup style={{ color: "red" }}>*</sup>
            </Label>
            <Col sm={8}>
              <Input
                id="GroupMe_BotID"
                value={alertData.GroupMe_BotID}
                onChange={fillForm}
              />
            </Col>
            <Col sm={1}>
              <Button
                color="secondary"
                href="https://dev.groupme.com/bots"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="ni ni-bulb-61 text-yellow" />
              </Button>
            </Col>
          </FormGroup>
        </>
      );
    } else if (alertData.type == "Discord") {
      return (
        <>
          <hr
            style={{ background: "#000000", height: "3px", border: "none" }}
          />
          <FormGroup row>
            <Label for="webhook" sm={2}>
              Webhook Url <sup style={{ color: "red" }}>*</sup>
            </Label>

            <Col sm={8}>
              <Input
                id="Discord_WebhookUrl"
                value={alertData.Discord_WebhookUrl}
                onChange={fillForm}
              />
            </Col>
            <Col sm={1}>
              <Button
                color="secondary"
                href="https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="ni ni-bulb-61 text-yellow" />
              </Button>
            </Col>
          </FormGroup>
        </>
      );
    } else if (alertData.type == "Slack") {
      return (
        <>
          <hr
            style={{ background: "#000000", height: "3px", border: "none" }}
          />
          <FormGroup row>
            <Label for="webhook" sm={2}>
              Webhook Url <sup style={{ color: "red" }}>*</sup>
            </Label>

            <Col sm={8}>
              <Input
                id="Slack_WebhookUrl"
                value={alertData.Slack_WebhookUrl}
                onChange={fillForm}
              />
            </Col>
            <Col sm={1}>
              <Button
                color="secondary"
                href="https://api.slack.com/messaging/webhooks"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="ni ni-bulb-61 text-yellow" />
              </Button>
            </Col>
          </FormGroup>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Modal isOpen={alertsModal} toggle={toggle} size={modalSize}>
      <ModalHeader toggle={toggle}>
        Edit Alerts Credentials - {currentEdit}
      </ModalHeader>

      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="exampleSelect">Select</Label>
            <Input
              type="select"
              name="select"
              id="exampleSelect"
              value={alertData.type}
              onChange={onChange}
            >
              <option></option>
              <option>Coeus Space</option>
              <option>Discord</option>
              <option>GroupMe</option>
              <option>Slack</option>
            </Input>
          </FormGroup>
          {getForm()}
        </Form>
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <div>Saving...</div>
        ) : (
          <>
            <Button color="success" onClick={testalert} disabled={loading}>
              Test
            </Button>{" "}
            <Button color="success" onClick={save} disabled={loading}>
              Save
            </Button>{" "}
            <Button color="secondary" onClick={toggle} disabled={loading}>
              Cancel
            </Button>{" "}
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default AlertsModal;
