import axios from "axios";
const { API_URL } = require("./constants");

const emailVerification_api = async (accessToken, email) => {
  let data = JSON.stringify({
    email: email,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: API_URL + "api/v1/email_verification",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    data: data,
  };

  let result = "";

  await axios
    .request(config)
    .then((response) => {
      result = response.data;
    })
    .catch((error) => {
      return error;
    });

  return result;
};

export default emailVerification_api;
