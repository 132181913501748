import React, { useState, useEffect } from "react";
import { Table, Pagination } from "antd";
import { Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import getRecentNews_api from "apis/getRecentNews";

const columns = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    render: (text) => (
      <td style={{ maxWidth: "300px", overflow: "auto" }}>{text}</td>
    ),
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Link",
    dataIndex: "link",
    key: "link",
    render: (text) => (
      <Button
        color="primary"
        href={text}
        rel="noopener noreferrer"
        target="_blank"
        size="sm"
      >
        See all
      </Button>
    ),
  },
];

const NewsTable = ({ setMessageApi }) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [newsData, setNewsData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const accessToken = useSelector((state) => state.accessToken.value);
  const dispatch = useDispatch();
  const pageSize = 5;

  const loadPage = async () => {
    setLoading(true);
    if (accessToken != "") {
      var result = await getRecentNews_api(accessToken);
      try {
        if (result != undefined) {
          if (result.error == false) {
            var myresult = result.message;
            var temp = [];
            for (let i = 0; i < myresult.length; i++) {
              temp.push({
                key: i,
                title: myresult[i][0],
                date: myresult[i][2],
                link: myresult[i][1],
              });
            }
            setNewsData(temp);
          } else {
            setMessageApi("error", "Page Failed to load, Please Try Again");
          }
        } else {
          setMessageApi("error", "Page Failed to load, Please Try Again");
        }
      } catch (err) {
        setMessageApi("error", "Page Failed to load, Please Try Again");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    loadPage();
  }, [accessToken]);

  // Get the data for the current page
  useEffect(() => {
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = startIndex + pageSize;
    setCurrentData(newsData.slice(startIndex, endIndex));
  }, [currentPage, newsData]);

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={currentData}
        pagination={false}
      />
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={newsData.length}
        onChange={handlePageChange}
        showSizeChanger={false}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};

export default NewsTable;
