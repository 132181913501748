// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
// div className="header bg-gradient-info pb-8 pt-5 pt-md-8" contains header
const Header = () => {
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
    </>
  );
};

export default Header;
