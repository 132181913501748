import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import editModel_api from "apis/editModel";
import getModel_api from "apis/getModel";
import ModelNameCheck from "./ModelNameCheck";
import Editor from "@monaco-editor/react";
import deleteModel_api from "apis/deleteModel";
import { editModelData, deleteModelData } from "features/modelsListSlice";
import { useSelector, useDispatch } from "react-redux";

const EditModel = ({
  setEditModel,
  editModel,
  oldModelName,
  setMessageApi,
}) => {
  const [page, setPage] = useState(1);
  const [buttonText, setButtonText] = useState("Next");
  const [newModelName, setNewModelName] = useState("");
  const [oldModelValue, setOldModelValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalSize, setModalSize] = useState("md");
  const [viewOnly, setViewOnly] = useState(false);

  const accessToken = useSelector((state) => state.accessToken.value);

  const dispatch = useDispatch();

  const toggle = () => {
    setEditModel(false);
  };

  const nextPage = async () => {
    setLoading(true);
    var newModelNameTouch = true;
    if (newModelName == "" || newModelName == oldModelName) {
      newModelNameTouch = false;
    }
    if (page === 2) {
      // submit
      var newModelValue = editorRef.current.getValue();
      var newModelValueTouch = true;
      if (newModelValue == "" || newModelValue == oldModelValue) {
        newModelValueTouch = false;
      }
      if (newModelNameTouch == false && newModelValueTouch == false) {
        // nothing changed
        setEditModel(false);
        setLoading(false);
        return;
      } else if (newModelNameTouch == true && newModelValueTouch == false) {
        var result = await editModel_api(
          accessToken,
          oldModelName,
          newModelName,
          oldModelValue
        );
      } else if (newModelNameTouch == false && newModelValueTouch == true) {
        var result = await editModel_api(
          accessToken,
          oldModelName,
          oldModelName,
          newModelValue
        );
      } else {
        var result = await editModel_api(
          accessToken,
          oldModelName,
          newModelName,
          newModelValue
        );
      }
      try {
        if (result != null) {
          if (result.error == false) {
            if (newModelNameTouch) {
              dispatch(editModelData([oldModelName, newModelName]));
            }
            setEditModel(false);
            setLoading(false);
            setMessageApi("success", "Model Saved");
          } else {
            if (result.message === "Duplicate Model Name") {
              setMessageApi(
                "error",
                "Model Name already exist, Please Start Over"
              );
              setLoading(false);
            } else {
              setMessageApi("error", "Please try again laster");
              setLoading(false);
            }
          }
        }
      } catch (err) {
        setLoading(false);
        setMessageApi("error", "Please Try Again");
      }
    } else {
      // go to the next page
      var validName = ModelNameCheck(newModelName);
      if (validName == "" || !newModelNameTouch) {
        setPage(2);
        setButtonText("Save");
        setModalSize("lg");
        setLoading(false);
      } else {
        setMessageApi("error", validName);
        setLoading(false);
      }
    }
  };

  const delete_Model = async () => {
    setLoading(true);
    try {
      var result = await deleteModel_api(accessToken, oldModelName);
      if (result != undefined) {
        if (result.error == false) {
          dispatch(deleteModelData(oldModelName));
          setEditModel(false);
          setLoading(false);
          setMessageApi("success", "Model Deleted");
        } else {
          if (result.message == "model in used for layout") {
            setMessageApi("error", "Model is used for Layout!");
            setLoading(false);
          } else {
            setMessageApi("error", "Please Try Again");
            setLoading(false);
          }
        }
      }
    } catch (err) {
      setMessageApi("error", "Please Try Again");
      setLoading(false);
    }
  };

  const getOldModelInfo = async () => {
    var result = await getModel_api(accessToken, oldModelName);
    if (result != undefined) {
      if (result.error == false) {
        setViewOnly(result.message[0][3]);
        setOldModelValue(result.message[0][2]);
      } else {
        setMessageApi("error", "Cannot get model info");
        setEditModel(false);
      }
    }
  };

  useEffect(() => {
    getOldModelInfo();
  }, []);

  const onChange = (e) => {
    if (e.target.id === "ModelName") {
      setNewModelName(e.target.value);
    }
  };

  const editorRef = useRef(null);

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
  }

  const handleKeyPress = (target) => {
    if (target.charCode == 13) {
      target.preventDefault();
      nextPage();
    }
  };

  return (
    <Modal isOpen={editModel} toggle={toggle} size={modalSize}>
      {viewOnly ? (
        <ModalHeader toggle={toggle}>Edit Model (View Only)</ModalHeader>
      ) : (
        <ModalHeader toggle={toggle}>Edit Model</ModalHeader>
      )}

      <ModalBody>
        {page === 1 ? (
          <Form>
            <FormGroup>
              <Label for="ModelName">Model Name</Label>
              <Input
                id="ModelName"
                placeholder={oldModelName}
                defaultValue={oldModelName}
                type="ModelName"
                onChange={onChange}
                onKeyPress={handleKeyPress}
              />
            </FormGroup>
          </Form>
        ) : (
          <Form>
            <FormGroup>
              <Label for="ModelValue">Model Code</Label>
              <Editor
                height="50vh"
                defaultLanguage="python"
                theme="vs-dark"
                defaultValue={oldModelValue}
                onMount={handleEditorDidMount}
              />
            </FormGroup>
          </Form>
        )}
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <div>Saving...</div>
        ) : (
          <>
            {viewOnly && page == 2 ? (
              <></>
            ) : (
              <Button color="success" onClick={nextPage} disabled={loading}>
                {buttonText}
              </Button>
            )}{" "}
            {!viewOnly ? (
              <Button color="danger" onClick={delete_Model} disabled={loading}>
                Delete
              </Button>
            ) : (
              <></>
            )}{" "}
            <Button color="secondary" onClick={toggle} disabled={loading}>
              Cancel
            </Button>{" "}
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default EditModel;
