import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import testAlertGroupMe_api from "apis/testAlertGroupMe";
import testAlertDiscord_api from "apis/testAlertDiscord";
import testAlertSlack_api from "apis/testAlertSlack";
import testCoeusSpace_api from "apis/testCoeusSpace";
import { Tabs } from "antd";
import CommunityProfile from "./CommunityProfile/CommunityProfile";
import CommunityAdmin from "./CommunityAdmin/CommunityAdmin";
import CommunitySettings from "./CommunitySettings/CommunitySettings";
import CommunityAlerts from "./CommunityAlerts/CommunityAlerts";
import { useSocket } from "app/SocketContext";

const ExploreModal = ({
  setExploreModal,
  exploreModal,
  setMessageApi,
  setNotificationApi,
  referID,
  myCommunity,
}) => {
  const [loading, setLoading] = useState(false);
  const [modalSize, setModalSize] = useState("xl");
  const newTabIndex = useRef(2);
  const communityList = useSelector((state) => {
    if (myCommunity) {
      return state.myCommunityList.value;
    } else {
      return state.communityList.value;
    }
  });
  const { socket, socketAuth } = useSocket();

  const toggle = () => {
    if (socketAuth) {
      socket.emit("community_disconnect", {
        communityName: communityList[referID].name,
      });
    }
    setExploreModal(false);
  };

  const initialItems = [
    {
      label: "Profile",
      children: (
        <CommunityProfile
          setMessageApi={setMessageApi}
          profileInfo={communityList[referID]}
        />
      ),
      key: "1",
      icon: <i className="ni ni-single-02 text-info" />,
    },
    {
      label: "Alerts",
      children: (
        <CommunityAlerts
          setMessageApi={setMessageApi}
          profileInfo={communityList[referID]}
        />
      ),
      key: "2",
      icon: <i className="ni ni-bell-55 text-danger" />,
    },
    {
      label: "Settings",
      children: myCommunity ? (
        <CommunitySettings
          setMessageApi={setMessageApi}
          profileInfo={communityList[referID]}
          isAdminCheck={true}
          setNotificationApi={setNotificationApi}
        />
      ) : (
        <CommunitySettings
          setMessageApi={setMessageApi}
          profileInfo={communityList[referID]}
          isAdminCheck={false}
          setNotificationApi={setNotificationApi}
        />
      ),
      key: "3",
      icon: <i className="ni ni-settings text-indigo" />,
    },
    {
      label: "Admin",
      children: myCommunity ? (
        <CommunityAdmin
          setMessageApi={setMessageApi}
          isAdminCheck={true}
          profileInfo={communityList[referID]}
          exploreModalToggle={toggle}
        />
      ) : (
        <CommunityAdmin
          setMessageApi={setMessageApi}
          isAdminCheck={false}
          profileInfo={communityList[referID]}
          exploreModalToggle={toggle}
        />
      ),
      key: "4",
      icon: <i className="ni ni-world text-darker" />,
    },
  ];
  const [activeKey, setActiveKey] = useState(initialItems[1].key);
  const [items, setItems] = useState(initialItems);

  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };

  return (
    <Modal isOpen={exploreModal} toggle={toggle} size={modalSize}>
      {communityList[referID] ? (
        <ModalHeader toggle={toggle} style={{ fontSize: "15px" }}>
          Explore - {communityList[referID].name}
        </ModalHeader>
      ) : (
        <></>
      )}

      <ModalBody>
        <Tabs
          onChange={onChange}
          activeKey={activeKey}
          items={items}
          tabPosition="left"
          style={{
            height: 600,
          }}
          size="large"
        />
      </ModalBody>
    </Modal>
  );
};

export default ExploreModal;
