import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Jumbotron,
  Col,
} from "reactstrap";

import { useState, useRef, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const ModelSelect = ({
  setModelSelect,
  modelSelect,
  addedNodes,
  parentCallback,
}) => {
  const [loading, setLoading] = useState(false);
  const [refinedModelList, setRefinedModelList] = useState([]); // this is used for first loaded page
  const [modelsChecked, setModelsChecked] = useState({}); // {modelName: checked#} -> this is for saving
  const layoutList = useSelector((state) => state.layoutsList.value);
  const modelList = useSelector((state) => state.modelsList.value);
  const dispatch = useDispatch();

  const toggle = () => {
    setModelSelect(false);
  };

  const onChange = (e) => {
    // key -> id, id -> name
    var myname = e.target.getAttribute("name");
    var myid = e.target.getAttribute("myid");
    var temp = modelsChecked;
    if (myname in temp) {
      temp[myname][0] += 1;
    } else {
      temp[myname] = [1, { id: myid, name: myname }];
    }
    setModelsChecked(temp);
  };

  const onSave = async () => {
    setLoading(true);
    var nodesNames = [];
    var nodesData = [];
    Object.entries(modelsChecked).forEach(([k, v]) => {
      // k -> model name, v -> [pressed, data]
      if (v[0] % 2 == 1) {
        nodesNames.push(k);
        nodesData.push(v[1]);
      }
    });
    parentCallback(nodesNames, nodesData);
    setModelSelect(false);
    setLoading(false);
  };

  // Use Effect to load page

  const loadPage = async () => {
    const temp = modelList.filter(({ name }) => !addedNodes.includes(name));
    setRefinedModelList(temp);
  };

  useEffect(() => {
    loadPage();
  }, []);

  return (
    // <Modal isOpen={newLayout} toggle={toggle} size='lg'>
    <Modal isOpen={modelSelect} toggle={toggle} size="md">
      <ModalHeader toggle={toggle}>Select Models</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            {refinedModelList.map((model) => (
              <Col key={model.id} lg={{ size: 10 }}>
                <FormGroup key={model.id} check>
                  <Label key={model.id} check>
                    <Input
                      type="checkbox"
                      key={model.id}
                      myid={model.id}
                      name={model.name}
                      onChange={onChange}
                    />{" "}
                    {model.name}
                  </Label>
                </FormGroup>
              </Col>
            ))}
          </FormGroup>
        </Form>
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <div>Saving...</div>
        ) : (
          <>
            <Button color="primary" onClick={onSave} disabled={loading}>
              Add
            </Button>{" "}
            <Button color="secondary" onClick={toggle} disabled={loading}>
              Cancel
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ModelSelect;
