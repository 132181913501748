import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import createSimpleAlert_api from "apis/createSimpleAlert";
import {
  deleteFilteredLayoutData,
  editFilteredLayoutStatusData,
  addFilteredLayoutData,
  setFilteredLayoutData,
} from "features/filteredLayoutsListSlice";
import { Tabs } from "antd";
import FormFilling from "./FormFilling";
import Tickers from "./tickerList";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { requireField, checkInput } from "./Filtering";

const SimpleAlert = ({
  simpleAlert,
  setSimpleAlert,
  setMessageApi,
  setNotificationApi,
}) => {
  const [loading, setLoading] = useState(false);
  const [modalSize, setModalSize] = useState("md");
  const [alertType, setAlertType] = useState("PRICE");
  const [alertTypeList, setAlertTypeList] = useState({
    PRICE: { aboveBelow: "Above", alertPrice: 0 },
    VOLUME: { aboveBelow: "Above", volumeLevel: 0 },
    EARNINGS: { dayBefore: "5 Days Before" },
  });

  const [tickerData, setTickerData] = useState("");
  const [alertData, setAlertData] = useState({
    "Alert 1": {
      type: "",
      GroupMe_BotID: "",
      Discord_WebhookUrl: "",
      Slack_WebhookUrl: "",
      Community_List: [],
    },
  });
  const newTabIndex = useRef(2);

  const initialItems = [
    {
      label: "Alert 1",
      children: (
        <FormFilling
          alertData={alertData}
          setAlertData={setAlertData}
          referID="1"
          setLoading={setLoading}
          setMessageApi={setMessageApi}
          setNotificationApi={setNotificationApi}
        />
      ),
      key: "1",
    },
  ];
  const [activeKey, setActiveKey] = useState(initialItems[0].key);
  const [items, setItems] = useState(initialItems);

  const accessToken = useSelector((state) => state.accessToken.value);
  const layoutList = useSelector((state) => state.layoutsList.value);
  const modelList = useSelector((state) => state.modelsList.value);
  const dispatch = useDispatch();

  const add = () => {
    const newActiveKey = `${newTabIndex.current++}`;
    setActiveKey(newActiveKey);

    var temp = alertData;
    temp["Alert " + newActiveKey] = {
      type: "",
      GroupMe_BotID: "",
      Discord_WebhookUrl: "",
      Slack_WebhookUrl: "",
      Community_List: [],
    };
    setAlertData(temp);

    const newPanes = [...items];
    newPanes.push({
      label: "Alert " + newActiveKey,
      children: (
        <FormFilling
          alertData={alertData}
          setAlertData={setAlertData}
          referID={newActiveKey}
          setLoading={setLoading}
          setMessageApi={setMessageApi}
          setNotificationApi={setNotificationApi}
        />
      ), // here
      key: newActiveKey,
    });
    setItems(newPanes);
  };

  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };

  const remove = (targetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key; // activate the last one
      } else {
        newActiveKey = newPanes[0].key; // activate the first one
      }
    }
    var temp = alertData;
    delete temp["Alert " + targetKey];
    setAlertData(temp);
    setActiveKey(newActiveKey);
    setItems(newPanes);
  };

  const onEdit = (targetKey, action) => {
    setLoading(true);
    if (action === "add") {
      add();
    } else {
      remove(targetKey);
    }
    setLoading(false);
  };

  const toggle = () => {
    setSimpleAlert(false);
  };

  const save = async () => {
    if (tickerData == "") {
      setMessageApi("error", "Please Select a Ticker");
      return;
    }
    var errorMsg = checkInput(alertData);
    if (errorMsg != "") {
      setMessageApi("error", errorMsg);
      return;
    }
    setLoading(true);
    var result = await createSimpleAlert_api(
      accessToken,
      alertType,
      alertTypeList[alertType],
      tickerData,
      alertData
    );
    try {
      if (result.error == false) {
        var temp = [];
        Object.entries(result.message).forEach(([v, k]) => {
          temp.push(v.toString() + "S");
          temp.push(k);
        });
        setLoading(false);
        dispatch(addFilteredLayoutData(temp));
        setMessageApi("success", "Simple Alert Saved");
        setSimpleAlert(false);
      } else {
        setMessageApi("error", "Cannot Create Simple Alert");
        setLoading(false);
      }
    } catch (err) {
      setMessageApi("error", "Please Try Again");
      setLoading(false);
    }
    setLoading(false);
  };

  const handleOnSelect = (item) => {
    setTickerData(item.name);
  };

  const formatResult = (item) => {
    return (
      <div
        style={{
          display: "block",
          textAlign: "left",
        }}
      >
        {item.name}
      </div>
    );
  };

  const onChangeAlertType = async (e) => {
    setAlertType(e.target.id);
  };

  const onChangeAlertDetail = async (e) => {
    var temp = alertTypeList[alertType];
    temp[e.target.id] = e.target.value;

    var temp2 = {};
    temp2[alertType] = temp;
    setAlertTypeList({ ...alertTypeList, ...temp2 });
  };

  const handleKeyPress = (target) => {
    if (target.charCode == 13) {
      target.preventDefault();
    }
  };

  const alertTypeModal = () => {
    if (alertType == "PRICE") {
      return (
        <FormGroup row>
          <Col sm={6}>
            <Input
              type="select"
              name="select"
              id="aboveBelow"
              value={alertTypeList.PRICE.aboveBelow}
              onChange={onChangeAlertDetail}
            >
              <option>Above</option>
              <option>Below</option>
            </Input>
          </Col>
          <Col sm={6}>
            <Input
              type="number"
              name="input"
              id="alertPrice"
              value={alertTypeList.PRICE.alertPrice}
              onChange={onChangeAlertDetail}
              onKeyPress={handleKeyPress}
            />
          </Col>
        </FormGroup>
      );
    } else if (alertType == "VOLUME") {
      return (
        <FormGroup row>
          <Col sm={6}>
            <Input
              type="select"
              name="select"
              id="aboveBelow"
              value={alertTypeList.VOLUME.aboveBelow}
              onChange={onChangeAlertDetail}
            >
              <option>Above</option>
              <option>Below</option>
            </Input>
          </Col>
          <Col sm={6}>
            <Input
              type="number"
              name="input"
              id="volumeLevel"
              value={alertTypeList.VOLUME.volumeLevel}
              onChange={onChangeAlertDetail}
              onKeyPress={handleKeyPress}
            />
          </Col>
        </FormGroup>
      );
    } else if (alertType == "EARNINGS") {
      return (
        <FormGroup row>
          <Col sm={6}>
            <Input
              type="select"
              name="select"
              id="dayBefore"
              value={alertTypeList.EARNINGS.dayBefore}
              onChange={onChangeAlertDetail}
            >
              <option>5 Days Before</option>
              <option>3 Days Before</option>
              <option>1 Days Before</option>
            </Input>
          </Col>
        </FormGroup>
      );
    }
  };

  return (
    <Modal isOpen={simpleAlert} toggle={toggle} size={modalSize}>
      <ModalHeader toggle={toggle}>Create Simple Alert</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <FormGroup row>
              <Col sm={12}>
                {Object.entries(alertTypeList).map(([k, v]) => (
                  <>
                    <Button
                      color={alertType == k ? "info" : "secondary"}
                      onClick={onChangeAlertType}
                      disabled={loading}
                      id={k}
                    >
                      {k}
                    </Button>{" "}
                  </>
                ))}
              </Col>
            </FormGroup>
            <FormGroup row style={{ position: "relative", zIndex: 1 }}>
              <Col sm={2}>
                <Label>Ticker</Label>
              </Col>
              <Col sm={10}>
                <ReactSearchAutocomplete
                  items={Tickers}
                  onSelect={handleOnSelect}
                  autoFocus
                  formatResult={formatResult}
                  placeholder="TICKER e.g AAPL, MSFT, .."
                />
              </Col>
            </FormGroup>
            {alertTypeModal()}
          </FormGroup>
          <hr
            style={{ background: "#000000", height: "3px", border: "none" }}
          />
          <Tabs
            type="editable-card"
            onChange={onChange}
            activeKey={activeKey}
            onEdit={onEdit}
            items={items}
          />
        </Form>
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <div>Saving...</div>
        ) : (
          <>
            <Button color="success" onClick={save} disabled={loading}>
              Save
            </Button>{" "}
            <Button color="secondary" onClick={toggle} disabled={loading}>
              Cancel
            </Button>{" "}
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default SimpleAlert;
