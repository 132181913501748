import { useState, useEffect } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Form,
  FormGroup,
  Input,
  Label,
  CardTitle,
  Button,
  FormFeedback,
} from "reactstrap";
// core components
import Header from "./Header.js";
import { PlusOutlined } from "@ant-design/icons";
import { message } from "antd";
import GroupNameCheck from "./GroupNameCheck.js";
import createCommunity_api from "apis/createCommunity.js";
import { useSelector, useDispatch } from "react-redux";

const CreatorSettings = () => {
  const [loading, setLoading] = useState(false);
  const [groupForm, setGroupForm] = useState({ name: "", description: "" });
  const accessToken = useSelector((state) => state.accessToken.value);
  // name and description checking ...

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  const setMessageApi = async (mType, validName, duration) => {
    messageApi.open({
      key,
      type: mType,
      content: validName,
      duration: duration,
    });
  };

  const handleKeyPress = (target) => {
    if (target.charCode == 13) {
      target.preventDefault();
    }
  };

  const onChange = (e) => {
    let updatedValue = { ...groupForm };
    updatedValue[e.target.id] = e.target.value;
    setGroupForm(updatedValue);
  };

  const submit = async () => {
    setLoading(true);
    var nameCheck = GroupNameCheck(groupForm.name);
    if (nameCheck != "") {
      setMessageApi("error", nameCheck, 3);
      setLoading(false);
      return;
    }
    if (accessToken != "") {
      var result = await createCommunity_api(accessToken, groupForm);
      if (result != null) {
        if (result.error == false) {
          setMessageApi("success", "Successfully Created Group", 3);
        } else {
          if (result.message != "") {
            setMessageApi("error", result.message, 3);
          } else {
            setMessageApi("error", "Error in Creating Group", 3);
          }
        }
      } else {
        setMessageApi("error", "Please try again later", 3);
      }
    }
  };

  return (
    <>
      {contextHolder}
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}

        <Row>
          <Col lg="12" xl="2"></Col>
          <Col lg="12" xl="8">
            <Card
              className="card-stats mb-4 mb-xl-0"
              style={{ border: "3px solid black" }}
            >
              <CardBody>
                <Row style={{ marginBottom: "5%" }}>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      <span
                        className="h2 font-weight-bold mb-0"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Create A Community Group
                      </span>
                    </CardTitle>
                  </div>
                </Row>

                <Form>
                  <FormGroup row>
                    <Label for="name" sm={3}>
                      Name:
                    </Label>
                    <Col sm={8}>
                      <Input
                        id="name"
                        onChange={onChange}
                        onKeyPress={handleKeyPress}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="description" sm={3}>
                      Description:
                    </Label>
                    <Col sm={8}>
                      <Input
                        id="description"
                        type="textarea"
                        rows="6"
                        onChange={onChange}
                      />
                    </Col>
                  </FormGroup>
                </Form>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button color="info" onClick={submit}>
                    Submit
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="12" xl="2"></Col>
        </Row>
      </Container>
    </>
  );
};

export default CreatorSettings;
