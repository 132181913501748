import { createSlice } from "@reduxjs/toolkit";

export const notificationList = createSlice({
  name: "notificationList",
  initialState: {
    value: {},
  },
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes.
    // Also, no return statement is required from these functions.
    setNotifData: (state, action) => {
      state.value = action.payload;
    },
    addNotifData: (state, action) => {
      var data = action.payload;
      state.value[data["key"]] = data;
    },
    clearSelectNotifData: (state, action) => {
      // list of notifId
      var notifIds = action.payload[0];
      for (let i = 0; i < notifIds.length; i++) {
        delete state.value[notifIds[i]];
      }
    },
    clearAllNotifData: (state, action) => {
      state.value = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setNotifData,
  addNotifData,
  clearSelectNotifData,
  clearAllNotifData,
} = notificationList.actions;

export default notificationList.reducer;
