import React from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import { LoginButton } from "components/buttons/login-button";

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-dark");
    return () => {
      document.body.classList.remove("bg-dark");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <AuthNavbar />
        <div className="header bg-gradient-blue py-7 py-lg-8">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="10" md="10">
                  <img
                    src="./coeus-logo.png"
                    width="40%"
                    height="55%"
                    style={{ marginBottom: 10 }}
                  ></img>
                  <h1
                    className="text-white"
                    style={{
                      marginBottom: 30,
                      marginTop: 20,
                      textAlign: "center",
                    }}
                  >
                    Design your Alerts <br />
                    Build Communities and Share Alerts <br />
                    Alerts that meet people where they are <br />
                  </h1>

                  <LoginButton />
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <AuthFooter />
    </>
  );
};

export default Auth;
