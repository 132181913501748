import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addNotifData } from "features/notificationListSlice";
import { Button, Input, Select, Space } from "antd";

import emailVerification_api from "apis/emailVerification";
import emailVerificationCode_api from "apis/emailVerificationCode";
import { addEmailData } from "features/emailListSlice";

const FormFilling = ({
  emailData,
  setEmailData,
  activeKey,
  setLoading,
  setMessageApi,
  loading,
}) => {
  const accessToken = useSelector((state) => state.accessToken.value);
  const emailList = useSelector((state) => state.emailList.value);

  const dispatch = useDispatch();
  const [emailValue, setEmailValue] = useState(""); // useeffect
  const [verifStep, setVerifStep] = useState(false); // useeffect
  const [emailConfirmCode, setEmailConfirmCode] = useState(""); // useeffect
  const [isVerified, setIsVerified] = useState(false); // useeffect

  const loadPage = async () => {
    var myEmailData = emailData["Email " + activeKey];

    if (myEmailData.verified) {
      setEmailValue(myEmailData.email);
      setIsVerified(true);
      setVerifStep(false);
    }
  };

  useEffect(() => {
    loadPage();
  }, []);

  const fillForm = async (e) => {
    setLoading(true);
    if (!isVerified) {
      if (e.target.id == "email") {
        setEmailValue(e.target.value);
      } else {
        setEmailConfirmCode(e.target.value);
      }
    }

    setLoading(false);
  };

  const onVerify = async () => {
    setLoading(true);
    if (/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(emailValue)) {
      if (accessToken != "") {
        var result = await emailVerification_api(accessToken, emailValue);
        try {
          if (result != undefined) {
            if (result.error == false) {
              setMessageApi("success", "Verification code sent");
              setVerifStep(true);
            } else {
              setMessageApi("error", "Error with Email, Please Try Again");
            }
          } else {
            setMessageApi("error", "Error with Email, Please Try Again");
          }
        } catch (err) {
          setMessageApi("error", "Error with Email, Please Try Again");
        }
      }
    } else {
      setMessageApi("error", "Invalid Email, Please Try Again");
    }
    setLoading(false);
  };

  // ```
  // 1. submit email -> verification code
  // 2. confirm verification -> add to database (user_id | alert_id | email)
  // ```

  const onVerifyCode = async () => {
    setLoading(true);
    if (accessToken != "") {
      var email_id = "Email " + activeKey;
      var result = await emailVerificationCode_api(
        accessToken,
        emailValue,
        email_id,
        emailConfirmCode
      );
      try {
        if (result != undefined) {
          if (result.error == false) {
            setMessageApi("success", "Email Verified and Saved!");
            setIsVerified(true);
            var temp = emailData;
            temp["Email " + activeKey].email = emailValue;
            temp["Email " + activeKey].verified = true;
            setEmailData(temp);
            setVerifStep(false);
            // get to redux
            dispatch(addEmailData(["Email " + activeKey, emailValue]));
          } else {
            if (result.message != "") {
              setMessageApi("error", result.message);
            } else {
              setMessageApi("error", "Error invalid Code, Please Try Again");
            }
          }
        } else {
          setMessageApi("error", "Error invalid Code, Please Try Again");
        }
      } catch (err) {
        setMessageApi("error", "Error invalid Code, Please Try Again");
      }
    }
    setLoading(false);
  };

  return (
    <ModalBody>
      <Form>
        <FormGroup>
          <Space.Compact
            style={{
              width: "70%",
              marginBottom: "3%",
            }}
          >
            <Input
              placeholder="email"
              id="email"
              onChange={fillForm}
              value={emailValue}
              disabled={isVerified}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
            <Button type="primary" onClick={onVerify} disabled={isVerified}>
              Verify
            </Button>
          </Space.Compact>
          <Space.Compact />
          {verifStep ? (
            <Space.Compact
              style={{
                width: "40%",
              }}
            >
              <Input
                placeholder="Verification Code"
                id="vcode"
                onChange={fillForm}
                value={emailConfirmCode}
                disabled={isVerified}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
              <Button
                type="primary"
                onClick={onVerifyCode}
                disabled={isVerified}
              >
                Submit
              </Button>
            </Space.Compact>
          ) : (
            <></>
          )}
          {isVerified ? <div> Successfully Verified and Saved</div> : <></>}
        </FormGroup>
      </Form>
    </ModalBody>
  );
};

export default FormFilling;
