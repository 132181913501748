import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Terminal, { ColorMode, TerminalOutput } from "react-terminal-ui";
import testLayout_api from "apis/testLayout";
import { message } from "antd";
import { editFilteredLayoutStatusData } from "features/filteredLayoutsListSlice";
const TestModal = ({ setTestModal, testModal, layoutTarget }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [inputDataPanel, setInputDataPanel] = useState([
    <TerminalOutput>Welcome to Coeus Testing</TerminalOutput>,
  ]);
  const [outputDataPanel, setOutputDataPanel] = useState([
    <TerminalOutput>Welcome to Coeus Testing</TerminalOutput>,
  ]);
  const [messageApi, contextHolder] = message.useMessage();
  const accessToken = useSelector((state) => state.accessToken.value);
  const dispatch = useDispatch();
  const key = "updatable";
  const filteredlayoutList = useSelector(
    (state) => state.filteredLayoutsList.value
  );

  const layoutname = filteredlayoutList[layoutTarget].name;

  const toggle = () => {
    setTestModal(false);
  };

  const inputted = (myinput) => {
    if (myinput == undefined) {
      return;
    }
    if (inputDataPanel.length < 200) {
      // this is the limit for free trail -> need data streaming instead of state rendering
      setInputDataPanel([
        ...inputDataPanel,
        <TerminalOutput>{myinput}</TerminalOutput>,
      ]);
    }
  };

  const outputted = (myoutput) => {
    if (myoutput == undefined) {
      return;
    }
    if (outputDataPanel.length < 200) {
      // this is the limit for free trail -> need data streaming instead of state rendering
      setOutputDataPanel([
        ...outputDataPanel,
        <TerminalOutput>{myoutput}</TerminalOutput>,
      ]);
    }
  };

  const runTest = async () => {
    setConfirmLoading(true);
    messageApi.open({
      key,
      type: "loading",
      content: "Loading...",
      duration: 0,
    });
    if (accessToken != "") {
      try {
        var layoutresult = await testLayout_api(accessToken, layoutname);
        if (layoutresult != undefined) {
          if (layoutresult.error == false) {
            inputted(layoutresult.message.input);
            outputted(layoutresult.message.output);
            messageApi.open({
              key,
              type: "success",
              content: "Loaded!",
              duration: 3,
            });
            if (filteredlayoutList[layoutTarget].status == "not tested") {
              dispatch(
                editFilteredLayoutStatusData([layoutTarget, "not active"])
              );
            }
          } else {
            messageApi.open({
              key,
              type: "error",
              content: layoutresult.message,
              duration: 3,
            });
          }
        }
      } catch (e) {
        messageApi.open({
          key,
          type: "error",
          content: e.message,
          duration: 3,
        });
      }
    }
    setConfirmLoading(false);
  };

  return (
    <Modal isOpen={testModal} toggle={toggle} size="xl">
      {contextHolder}
      <ModalHeader toggle={toggle}>Testing Layout - {layoutname}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Row>
              <Col xs="6">
                <div className="container">
                  <Terminal
                    name="Inputs Panel"
                    prompt=">>"
                    height="400px"
                    colorMode={ColorMode.Dark}
                    onInput={(terminalInput) => inputted()}
                  >
                    {inputDataPanel}
                  </Terminal>
                </div>
              </Col>
              <Col xs="6">
                <div className="container">
                  <Terminal
                    name="Outputs and Alerts Panel"
                    prompt=">>"
                    height="400px"
                    colorMode={ColorMode.Dark}
                    onInput={(terminalInput) => outputted()}
                  >
                    {outputDataPanel}
                  </Terminal>
                </div>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={runTest} disabled={confirmLoading}>
          Run Test
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default TestModal;
