import { useState, useEffect } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
import NewModel from "./NewModel";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
// import Header from "components/Headers/Header.js";
import { useSelector, useDispatch } from "react-redux";
import getModelPage_api from "apis/getModelPage";
import EditModel from "./EditModel";
import { setModelData } from "features/modelsListSlice";
import { message } from "antd";

const Models = () => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [newModel, setNewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [oldModelName, setOldModelName] = useState("");

  const accessToken = useSelector((state) => state.accessToken.value);
  const modelList = useSelector((state) => state.modelsList.value);
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const key = "updatable";

  const onCreateModel = async (e) => {
    setNewModel(true);
  };

  const onEditModel = async (e) => {
    setOldModelName(e.target.id);
    setEditModel(true);
  };

  const setMessageApi = async (mType, validName) => {
    messageApi.open({
      key,
      type: mType,
      content: validName,
      duration: 3,
    });
  };

  // Use Effect to load page

  const loadPage = async () => {
    if (accessToken != "") {
      try {
        setConfirmLoading(true);

        var result = await getModelPage_api(accessToken);
        if (result != undefined) {
          if (result.error == false) {
            var modelresult = result.message; // array of names
            var temp = [];
            Object.entries(modelresult).forEach(([v, k]) => {
              temp.push({ id: v, name: k });
            });
            dispatch(setModelData(temp));
          } else {
            setMessageApi("error", "Cannot get load page");
          }
        }
        setConfirmLoading(false);
      } catch (e) {
        setMessageApi("error", "Cannot get load page");
        console.log(e.message);
      }
    }
  };

  useEffect(() => {
    loadPage();
  }, []);

  return (
    <>
      {contextHolder}
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}

        {newModel ? (
          <NewModel
            setNewModel={setNewModel}
            newModel={newModel}
            setMessageApi={setMessageApi}
          />
        ) : (
          <></>
        )}

        {editModel ? (
          <EditModel
            setEditModel={setEditModel}
            editModel={editModel}
            oldModelName={oldModelName}
            setMessageApi={setMessageApi}
          />
        ) : (
          <></>
        )}

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Models</h3>
              </CardHeader>
              <CardBody>
                {confirmLoading ? (
                  "loading"
                ) : (
                  <Row className="model-examples">
                    <Col lg="3" md="6">
                      <button
                        className="btn-icon-clipboard"
                        id="Create-Model"
                        type="button"
                        onClick={onCreateModel}
                      >
                        <div>
                          <i className="ni ni-fat-add text-green" />
                          <span>Create Models</span>
                        </div>
                      </button>
                      <UncontrolledTooltip
                        delay={0}
                        trigger="hover focus"
                        target="Create-Model"
                      >
                        {"Click to Add"}
                      </UncontrolledTooltip>
                    </Col>
                    {modelList.map((model) => (
                      // this model.id references the length (problem with delete)
                      <Col lg="3" md="6" key={model.name}>
                        <button
                          className="btn-icon-clipboard"
                          id={model.name}
                          type="button"
                          onClick={onEditModel}
                        >
                          <div id={model.name}>
                            <i
                              id={model.name}
                              className="ni ni-support-16 text-blue"
                            />
                            <span id={model.name}>{model.name}</span>
                          </div>
                        </button>
                      </Col>
                    ))}
                  </Row>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Models;
