import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import getSubscription_api from "apis/getSubscription";
import { Tabs } from "antd";
import FormFilling from "./FormFilling";
import { Alert, Flex, Spin } from "antd";
import { checkInput } from "../../SubscribeModal/Filtering";
import editSubscription_api from "apis/editSubscription";

const CommunitySettings = ({
  setMessageApi,
  profileInfo,
  isAdminCheck,
  setNotificationApi,
}) => {
  const [loading, setLoading] = useState(false);

  const accessToken = useSelector((state) => state.accessToken.value);
  const [alertData, setAlertData] = useState({});

  const newTabIndex = useRef(0);

  const [items, setItems] = useState([]); // calls formfilling component

  const [activeKey, setActiveKey] = useState("0"); // useeffect

  const loadPage = async () => {
    if (accessToken != "" && isAdminCheck) {
      var result = await getSubscription_api(accessToken, profileInfo.name);
      try {
        if (result != undefined) {
          if (result.error == false) {
            var tempAlertData = result.message[0][2];
            setAlertData(tempAlertData);
            var initialItems = [];
            for (let item in tempAlertData) {
              var keyValue = item.split(" ")[1];
              initialItems.push({
                label: item,
                children: (
                  <FormFilling
                    alertData={tempAlertData}
                    setAlertData={setAlertData}
                    referID={keyValue}
                    setLoading={setLoading}
                    setMessageApi={setMessageApi}
                    setNotificationApi={setNotificationApi}
                    key={keyValue}
                  />
                ),
                key: { keyValue },
              });
              newTabIndex.current = parseInt(keyValue);
            }
            newTabIndex.current += 1;

            setItems(initialItems);
            if (initialItems.length != 0) {
              setActiveKey(initialItems[0].key);
            }

            // set new messages here
          } else {
            setMessageApi("error", "Page Failed to load, Please Try Again");
          }
        } else {
          setMessageApi("error", "Page Failed to load, Please Try Again");
        }
      } catch (err) {
        setMessageApi("error", "Page Failed to load, Please Try Again");
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    loadPage();
    setLoading(false);
  }, []);

  const add = () => {
    const newActiveKey = `${newTabIndex.current++}`;
    setActiveKey(newActiveKey);

    var temp = alertData;
    temp["Alert " + newActiveKey] = {
      type: "",
      GroupMe_BotID: "",
      Discord_WebhookUrl: "",
      Slack_WebhookUrl: "",
      Email: {},
    };
    setAlertData(temp);

    const newPanes = [...items];

    newPanes.push({
      label: "Alert " + newActiveKey,
      children: (
        <FormFilling
          alertData={alertData}
          setAlertData={setAlertData}
          referID={newActiveKey}
          setLoading={setLoading}
          setMessageApi={setMessageApi}
          setNotificationApi={setNotificationApi}
          key={newActiveKey}
        />
      ), // here
      key: newActiveKey,
    });
    setItems(newPanes);
  };

  const remove = (targetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key; // activate the last one
      } else {
        newActiveKey = newPanes[0].key; // activate the first one
      }
    }
    var temp = alertData;
    delete temp["Alert " + targetKey.keyValue];
    setAlertData(temp);
    setActiveKey(newActiveKey);
    setItems(newPanes);
  };

  const onEdit = (targetKey, action) => {
    setLoading(true);
    if (action === "add") {
      add();
    } else {
      remove(targetKey);
    }
    setLoading(false);
  };

  const onSave = async () => {
    setLoading(true);
    var errorMsg = checkInput(alertData);
    if (errorMsg != "") {
      setMessageApi("error", errorMsg);
    } else {
      if (accessToken != "") {
        var result = await editSubscription_api(
          accessToken,
          profileInfo.name,
          alertData
        );
        try {
          if (result != undefined) {
            if (result.error == false) {
              setMessageApi("success", "Successfully Saved");
            } else {
              setMessageApi("error", "Please try again later");
            }
          } else {
            setMessageApi("error", "Please try again later");
          }
        } catch (err) {
          setMessageApi("error", "Page Failed to load, Please Try Again");
        }
      }
    }
    setLoading(false);
  };

  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };

  return (
    <ModalBody>
      {isAdminCheck ? (
        <Form>
          {loading ? (
            <FormGroup>
              <Spin tip="Loading...">
                <div
                  style={{
                    height: 600,
                    display: "flex",
                    flexDirection: "column",
                    padding: "16px",
                    border: "2px solid #1890ff", // Color the border
                    borderRadius: "8px",
                  }}
                ></div>
              </Spin>
            </FormGroup>
          ) : (
            <>
              <FormGroup>
                <Tabs
                  type="editable-card"
                  onChange={onChange}
                  activeKey={activeKey}
                  onEdit={onEdit}
                  items={items}
                />{" "}
              </FormGroup>
              <div style={{ textAlign: "right" }}>
                <Button color="success" onClick={onSave} disabled={loading}>
                  Save All
                </Button>{" "}
              </div>
            </>
          )}
        </Form>
      ) : (
        <Form>
          <FormGroup>You do not have access to this page</FormGroup>
        </Form>
      )}
    </ModalBody>
  );
};

export default CommunitySettings;
